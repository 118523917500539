import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Lander from './Components/Lander';
import WelcomeScreen from './Pages/WelcomeScreen';
import AccountSetup from './Pages/AccountSetup';
import ModuleSelection from './Pages/ModuleSelection';
import { getOnboardingModules } from 'Services/apiFunctions';
import RNRSetup from './Pages/RNRSetup';
import RNRSetupComplete from './Pages/RNRSetupComplete';
import RNRIntro from './Pages/RNRIntro';

import { updateUserProfile } from 'redux/user/actions';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { computeRedirectionPath, showSnackBarMessage } from 'utils/HelperFunctions';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import { useToaster } from 'Context/SnackbarContext';
import { useCommonContext } from 'Context/CommonContext';
import { find } from 'lodash';

const Index = () => {
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);
    const history = useHistory();
    const { SetSnackbar } = useToaster();
    const { onboardingInfo, setOnboardingInfo } = useCommonContext();
    const dispatch = useDispatch();

    useEffect(() => {
        let savedTokenDecode;
        try {
            savedTokenDecode = jwt_decode(Cookies.get('AuthToken'));
        } catch (error) {
            userLogout();
            return null;
        }
        if (!savedTokenDecode) {
            userLogout();
            return null;
        }
        updateUserProfile(dispatch, { adminId: savedTokenDecode['admin-id'] });
        updateWorkspaceDetails(dispatch, {
            team: savedTokenDecode['team-id'],
            platform: savedTokenDecode.platform,
        });
    }, []);

    const getOnboardingInfo = async () => {
        try {
            const onboardingData = await getOnboardingModules();
            if (onboardingData) {
                setOnboardingInfo(onboardingData);
                const selectedModules = !!find(onboardingData, (item) => item.completed);
                if (!selectedModules) {
                    history.push('/onboarding/');
                } else {
                    const path = computeRedirectionPath(onboardingData, isAdmin);
                    history.push(path);
                }
            }
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error.message || 'Error fetching Onboarding Modules');
        }
    };

    useEffect(() => {
        if (!onboardingInfo) {
            getOnboardingInfo();
        }
    }, [onboardingInfo]);

    return (
        <>
            <Switch>
                <Route exact path='/onboarding/' render={() => <Lander history={history} />} />
                <Route exact path='/onboarding/welcome/' render={() => <WelcomeScreen history={history} />} />
                <Route exact path='/onboarding/setup' render={() => <AccountSetup history={history} />} />
                <Route exact path='/onboarding/module' render={() => <ModuleSelection history={history} />} />
                <Route exact path='/onboarding/RnR/intro' render={() => <RNRIntro history={history} />} />
                <Route exact path='/onboarding/start' render={() => <RNRSetup history={history} />} />
                <Route exact path='/onboarding/RnR/complete' render={() => <RNRSetupComplete history={history} />} />
                <Redirect to='/onboarding/' />
            </Switch>
        </>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin });

export default Index;
