import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { dateRanges } from 'constants.js';
import { useToaster } from 'Context/SnackbarContext';
import { debounce, find } from 'lodash';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { getCoreValues, getRecognitonTypes } from 'Services/apiFunctions';
import { useQuery } from 'react-query';
import { GET_RECOGNITION_TYPES, GET_CORE_VALUES } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { STATUS_FILTER_DATA, STATUS } from '../../constants';

const Index = ({
    filter,
    setFilter,
    setPage,
    setShowDatePicker,
    from,
    searchText,
    setSearch,
    resetStatus,
    transactionState,
    isAdmin,
}) => {
    const { SetSnackbar } = useToaster();
    const [valueType, setValueType] = useState([]);
    const [recognitionType, setRecognitionType] = useState([]);
    const [statusData, setStatusData] = useState(STATUS_FILTER_DATA);

    const handleSearchText = debounce((value) => {
        setSearch(value);
    }, 400);

    useEffect(() => {
        if (resetStatus) {
            const temp = STATUS_FILTER_DATA.map((value) => {
                return {
                    ...value,
                    isChecked: false,
                };
            });
            setStatusData(temp);
        }
    }, []);

    const setRecognitionTypes = (data) => {
        let tempValues = data.recognitionTypes
            .map((recognition) => {
                if (
                    recognition.name !== 'special rewards' ||
                    (recognition.name === 'special rewards' && transactionState === 'recieved')
                ) {
                    return {
                        ...recognition,
                        label: recognition.name,
                        isChecked: false,
                    };
                }
                return null;
            })
            .filter(Boolean);
        if (!isAdmin) {
            tempValues = tempValues.filter(
                (recognition) =>
                    recognition.name !== 'bonus' || (recognition.name === 'bonus' && transactionState === 'recieved')
            );
        }
        setRecognitionType(tempValues);
    };

    const setValuesData = (data) => {
        const tempValues = data.values.map((value) => ({
            ...value,
            label: value.value,
            value: value.value,
            isChecked: false,
        }));
        setValueType(tempValues);
    };

    useQuery([GET_RECOGNITION_TYPES], getRecognitonTypes, {
        onSuccess: (data) => setRecognitionTypes(data),
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    useQuery([GET_CORE_VALUES], getCoreValues, {
        onSuccess: (data) => setValuesData(data),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const handleRecognitionType = (index) => {
        const temp = [...recognitionType];
        temp[index].isChecked = !temp[index].isChecked;
        const selectedValues = temp.filter((value) => value.isChecked).map((value) => value.name);
        setRecognitionType(temp);
        setFilter({
            ...filter,
            recognitionType: selectedValues,
        });
        setPage(1);
    };

    const handleValueType = (index) => {
        const temp = [...valueType];
        temp[index].isChecked = !temp[index].isChecked;
        const selectedValues = temp.filter((value) => value.isChecked).map((value) => value.id);
        setValueType(temp);
        setFilter({
            ...filter,
            values: selectedValues,
        });
        setPage(1);
    };

    const handleDateRange = (index) => {
        const value = dateRanges[index];
        if (value?.value === 'Custom Date') {
            setShowDatePicker(true);
        } else {
            setFilter({
                ...filter,
                dateRange: value.value,
            });
            setPage(1);
        }
    };

    const dateLabel = () => {
        if (!filter?.dateRange) return 'ALL';
        if (typeof filter.dateRange === 'object') {
            return 'Custom Date';
        } else {
            return find(dateRanges, (item) => item.value === filter.dateRange)?.label;
        }
    };

    const handleStatusFilter = (index) => {
        let temp = [...statusData];
        temp[index].isChecked = !temp[index].isChecked;
        let selectedValues = temp.filter((value) => value.isChecked).map((value) => value.value);
        setStatusData(temp);
        setFilter({
            ...filter,
            status: selectedValues,
        });
        setPage(1);
    };

    return (
        <div className='my-recognitions-filters'>
            <div className='d-flex'>
                <CustomFilterDropdown
                    dropDownID={'filterRecognitionType' + from}
                    title='Recognition Type'
                    selectedName={
                        filter?.recognitionType?.length > 0 ? `Selected (${filter.recognitionType.length})` : 'ALL'
                    }
                    optionsSelected
                    buttonStyleClass='width-188'
                    dropdownWidth={188}
                    filterOptions={recognitionType}
                    handleSelection={(index) => handleRecognitionType(index)}
                    search
                />
                <CustomFilterDropdown
                    dropDownID={'filterDateType' + from}
                    title='Date Range'
                    selectedName={dateLabel()}
                    optionsSelected
                    buttonStyleClass='width-188'
                    dropdownWidth={188}
                    dropdownHeight={234}
                    filterOptions={dateRanges}
                    handleSelection={(index) => handleDateRange(index)}
                    singleSelect
                />
                {from === STATUS.RECIEVED ? (
                    <CustomFilterDropdown
                        dropDownID={'filterValueType' + from}
                        title={'Value Type'}
                        selectedName={filter?.values?.length > 0 ? `Selected (${filter.values.length})` : 'ALL'}
                        optionsSelected
                        buttonStyleClass='width-188'
                        dropdownWidth={278}
                        dropdownHeight={202}
                        filterOptions={valueType}
                        handleSelection={(index) => handleValueType(index)}
                        search
                    />
                ) : (
                    <CustomFilterDropdown
                        dropDownID={'filterStatus' + from}
                        title={'Status'}
                        selectedName={filter?.status?.length > 0 ? `Selected (${filter.status.length})` : 'ALL'}
                        optionsSelected
                        buttonStyleClass='width-188'
                        dropdownWidth={206}
                        dropdownHeight={202}
                        filterOptions={statusData}
                        handleSelection={(index) => handleStatusFilter(index)}
                    />
                )}
            </div>
            <div className='mt-2'>
                <CustomSearchBox
                    value={searchText}
                    width={'320px'}
                    handleSearch={(event) => handleSearchText(event.target.value)}
                    placeholder={'Search for a person or message'}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    filter: PropTypes.object,
    setFilter: PropTypes.func,
    setPage: PropTypes.func,
    setShowDatePicker: PropTypes.func,
    from: PropTypes.string,
    searchText: PropTypes.string,
    setSearch: PropTypes.func,
    resetStatus: PropTypes.bool,
    transactionState: PropTypes.string,
    isAdmin: PropTypes.bool,
};

export default Index;
