import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import { useCommonContext } from 'Context/CommonContext';
import CSVModal from '../CSVModal';
import WelcomeModal from './Components/WelcomeModal';
import PointsTable from './PointsTable';
import Department from './Components/Department';
import Country from './Components/Country';
import queryString from 'query-string';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { shallowEqual, useSelector } from 'react-redux';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const Index = ({ history }) => {
    const { setManagerflow } = useCommonContext();
    const { todo, platform } = useSelector(mapStateToProps, shallowEqual);
    const [key, setKey] = useState('Users');
    const [csvOpen, setCsvOpen] = useState(false);
    const [onboardingRedirection, setOnboardingRedirection] = useState(false);
    const [showMarkAsManagerModal, setShowMarkAsManagerModal] = useState(false);
    const query = queryString.parse(history.location.search);

    useEffect(() => {
        if (query.mapManager) {
            setTimeout(() => {
                setShowMarkAsManagerModal(true);
            }, 2000);
        } else {
            setTimeout(() => {
                const timeStamp = localStorage.getItem('timestamp');
                if (
                    todo.isManagerFlowCompleted === false &&
                    (!timeStamp || Date.now() - timeStamp > 24 * 60 * 60 * 1000)
                ) {
                    setOnboardingRedirection(true);
                    localStorage.setItem('timestamp', Date.now().toString());
                }
            }, 2000);
        }
        if (query.type) {
            setKey(query.type);
        }
        setManagerflow(!!todo.isManagerFlowCompleted);
    }, [todo?.isManagerFlowCompleted]);

    return (
        <div className='organization-container'>
            <TitleBar title='Organization' />
            <div>
                <div className='container-member-manager navigatortabs'>
                    <Tabs activeKey={key} onSelect={(_key) => setKey(_key)}>
                        <Tab eventKey='Users' title='Users'>
                            {key === 'Users' && (
                                <PointsTable
                                    history={history}
                                    showMarkAsManagerModal={showMarkAsManagerModal}
                                    handleMarkAsManagerModal={(value) => setShowMarkAsManagerModal(value)}
                                />
                            )}
                        </Tab>
                        <Tab eventKey='Departments' title='Departments'>
                            {key === 'Departments' && <Department />}
                        </Tab>
                        <Tab eventKey='Country' title='Countries'>
                            {key === 'Country' && <Country />}
                        </Tab>
                    </Tabs>
                    <div className='switch-container-team d-flex justify-content-end'>
                        <EWButton
                            plainTextButton
                            primary={false}
                            buttonText='Map Teams'
                            onClick={() => setShowMarkAsManagerModal(true)}
                            buttonStyleClass='mr-3'
                        />
                        <EWButton
                            plainTextButton
                            primary={false}
                            buttonText='Bulk Update'
                            onClick={() => setCsvOpen(true)}
                        />
                    </div>
                </div>
                <CSVModal open={csvOpen} setOpen={setCsvOpen} platform={platform} />
                <WelcomeModal
                    open={onboardingRedirection}
                    setOpen={setOnboardingRedirection}
                    handleButtonClick={setCsvOpen}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    todo: state.Workspace.todo,
    platform: state.Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
