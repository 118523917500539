import React, { useEffect, useState } from 'react';
import { AutoReloadBox } from '../styles';
import { CustomSwitch } from '../../../../../index';
import {
    DROPDOWN_TYPES,
    PRESELECTED_AMOUNTS_LISTS,
    PaymentMethodStripe,
    PLAN_STATUS,
    AutoReloadNote,
    handlePlanAction,
    CONFIRM_AUTO_RELOAD,
} from '../../constants';
import { DisclaimerNote } from '../../DisclaimerNote';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/payments/actionEvents';
import { useToaster } from 'Context/SnackbarContext';
import clsx from 'clsx';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import EWButton from 'components/ReusableComponents/EWButton';
import { setupWalletAutoReload } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

const SELECT_AMOUNT = 'Select amount';

const AutoReload = () => {
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();
    const { workspaceWallet, subscription, workspaceCountry } = useSelector(mapStateToProps, shallowEqual);
    const PRESELECTED_AMOUNTS = PRESELECTED_AMOUNTS_LISTS(workspaceCountry);
    const [reloadSettings, setReloadSettings] = useState({});
    const [enableAutoReload, setEnableAutoReload] = useState(workspaceWallet?.autoReload?.status || false);
    const [openConfirmModal, setConfirmModal] = useState(false);
    const [reloadEditMode, setReloadEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const isSubscriptionCancelled = subscription?.status === PLAN_STATUS.CANCELLED;
    const { threshold: autoReloadThreshold, credit: autoReloadCredit } = workspaceWallet?.autoReload || {};

    const showAutoReloadDropdown = !!(!autoReloadThreshold || !autoReloadCredit || reloadEditMode);
    const showAutoReloadAmount = !!(!reloadEditMode && autoReloadCredit && autoReloadThreshold);

    useEffect(
        (prevv) => {
            if (prevv === undefined && workspaceWallet?.autoReload) {
                const { status = false, threshold, credit } = workspaceWallet?.autoReload || {};
                if (status) {
                    setReloadSettings({
                        ...(threshold && { [DROPDOWN_TYPES.MIN_BALANCE]: { label: threshold, value: threshold } }),
                        ...(credit && { [DROPDOWN_TYPES.AUTO_RELOAD]: { label: credit, value: credit } }),
                    });
                    setEnableAutoReload(workspaceWallet?.autoReload?.status);
                } else if (status === false) {
                    setEnableAutoReload(false);
                }
            }
        },
        [workspaceWallet?.autoReload]
    );

    const handleDropDowns = (data, type) => {
        setReloadSettings({
            ...reloadSettings,
            [type]: PRESELECTED_AMOUNTS[type][data],
        });
    };

    const performAutoReloadSave = async (status) => {
        setLoading(true);
        const payload = {
            status,
            ...(status && { credit: reloadSettings[DROPDOWN_TYPES.AUTO_RELOAD].value }),
            ...(status && { threshold: reloadSettings[DROPDOWN_TYPES.MIN_BALANCE].value }),
        };

        try {
            await setupWalletAutoReload(payload);
            const data = { workspaceWallet: { ...workspaceWallet, autoReload: { ...payload } } };
            dispatch({ type: actions.SET_DATA, payload: { data } });
            showSnackBarMessage(SetSnackbar, 'success', 'Auto Reload wallet settings saved');
            if (!status) {
                setEnableAutoReload(false);
            }
            setReloadEditMode(false);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenPortal = () => handlePlanAction((err) => showSnackBarMessage(SetSnackbar, 'error', err?.message));

    return (
        <div className='margin-bottom-16'>
            <AutoReloadBox disclaimerAvailable={!isSubscriptionCancelled && enableAutoReload}>
                <div className='spreadOut'>
                    <div className='reload-title'>Auto- reload settings</div>
                    <div className='spreadOut'>
                        <CustomSwitch
                            checked={!isSubscriptionCancelled && !!enableAutoReload}
                            onClick={() => {
                                if (isSubscriptionCancelled) return;
                                if (enableAutoReload) {
                                    setConfirmModal(true);
                                } else {
                                    setEnableAutoReload(true);
                                }
                            }}
                            name='checkedC'
                        />
                        <span className='reload-switch'>&nbsp;{`${enableAutoReload ? 'Enabled' : 'Disabled'}`}</span>
                    </div>
                </div>
                <AutoReloadNote subscriptionCancelled={isSubscriptionCancelled} />
                {!isSubscriptionCancelled && enableAutoReload && (
                    <div className='inner-details'>
                        <div className={clsx({ 'd-flex': true })}>
                            <div className='margin-bottom-20 mr-2'>
                                <div className='text-subheading'>When balance drops below</div>
                                {showAutoReloadAmount && (
                                    <div className='reload-balance'>
                                        <span dangerouslySetInnerHTML={{ __html: workspaceWallet?.currencySymbol }} />{' '}
                                        {reloadSettings[DROPDOWN_TYPES.MIN_BALANCE]?.label}
                                    </div>
                                )}
                                {showAutoReloadDropdown && (
                                    <CustomFilterDropdown
                                        dropDownID='balancetDropdown'
                                        filterOptions={PRESELECTED_AMOUNTS.MIN_BALANCE}
                                        selectedName={
                                            <div className='d-flex justify-content-between p-2'>
                                                <div>
                                                    {reloadSettings[DROPDOWN_TYPES.MIN_BALANCE] && (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: workspaceWallet?.currencySymbol,
                                                            }}
                                                        />
                                                    )}
                                                    &nbsp;
                                                    {reloadSettings[DROPDOWN_TYPES.MIN_BALANCE]
                                                        ? reloadSettings[DROPDOWN_TYPES.MIN_BALANCE].label
                                                        : SELECT_AMOUNT}
                                                </div>
                                            </div>
                                        }
                                        handleSelection={(index) => handleDropDowns(index, DROPDOWN_TYPES.MIN_BALANCE)}
                                        buttonStyleClass='width-216'
                                        singleSelect
                                        optionsSelected={reloadSettings?.[DROPDOWN_TYPES.MIN_BALANCE]}
                                    />
                                )}
                            </div>

                            <div className='ml-2 mr-2'>
                                <div className='text-subheading'>Auto-reload amount</div>
                                {showAutoReloadAmount && (
                                    <div className='reload-balance'>
                                        <span dangerouslySetInnerHTML={{ __html: workspaceWallet?.currencySymbol }} />{' '}
                                        {reloadSettings[DROPDOWN_TYPES.AUTO_RELOAD]?.label}
                                    </div>
                                )}
                                {showAutoReloadDropdown && (
                                    <CustomFilterDropdown
                                        dropDownID='autoReloadDropdown'
                                        filterOptions={PRESELECTED_AMOUNTS.AUTO_RELOAD}
                                        selectedName={
                                            <div className='d-flex justify-content-between p-2'>
                                                <div>
                                                    {reloadSettings[DROPDOWN_TYPES.AUTO_RELOAD] && (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: workspaceWallet?.currencySymbol,
                                                            }}
                                                        />
                                                    )}
                                                    &nbsp;
                                                    {reloadSettings[DROPDOWN_TYPES.AUTO_RELOAD]
                                                        ? reloadSettings[DROPDOWN_TYPES.AUTO_RELOAD].label
                                                        : SELECT_AMOUNT}
                                                </div>
                                            </div>
                                        }
                                        handleSelection={(index) => handleDropDowns(index, DROPDOWN_TYPES.AUTO_RELOAD)}
                                        buttonStyleClass='width-216'
                                        singleSelect
                                        optionsSelected={reloadSettings?.[DROPDOWN_TYPES.AUTO_RELOAD]}
                                    />
                                )}
                            </div>

                            <div className={clsx({ 'd-flex': true, 'margin-top-20': showAutoReloadDropdown })}>
                                {reloadEditMode && (
                                    <EWButton
                                        plainTextButton
                                        primary={false}
                                        buttonText='Cancel'
                                        onClick={() => setReloadEditMode(false)}
                                        disabled={loading}
                                    />
                                )}
                                {showAutoReloadDropdown && (
                                    <EWButton
                                        primary={false}
                                        border={true}
                                        buttonText='Save'
                                        buttonStyleClass='margin-left-15 ew-btn-slim'
                                        onClick={() => performAutoReloadSave(true)}
                                        loading={loading}
                                        loaderColor={'#5d5cf5'}
                                        disabled={
                                            loading ||
                                            !reloadSettings[DROPDOWN_TYPES.MIN_BALANCE] ||
                                            !reloadSettings[DROPDOWN_TYPES.AUTO_RELOAD]
                                        }
                                    />
                                )}
                                {showAutoReloadAmount && (
                                    <EWButton
                                        primary={false}
                                        border={true}
                                        buttonText='Edit'
                                        buttonStyleClass='margin-left-65'
                                        onClick={() => setReloadEditMode(true)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='text-subheading margin-bottom-8'>Payment method</div>
                        <div className='margin-bottom-10'>
                            <PaymentMethodStripe
                                isPaymentMethodAdded={subscription?.isPaymentMethodAdded}
                                onClick={handleOpenPortal}
                            />
                        </div>
                    </div>
                )}
                {!enableAutoReload && (
                    <span className='auto-reload-disabled-note'>
                        <strong>Note:</strong> Since the Auto-reload is disabled, we'll automatically charge the saved
                        card for any redemptions by your users when there are insufficient funds in your wallet.
                    </span>
                )}

                <ConfirmModal
                    open={openConfirmModal}
                    data={CONFIRM_AUTO_RELOAD}
                    singleButton
                    onClose={() => setConfirmModal(false)}
                    onConfirm={() => {
                        performAutoReloadSave(false);
                        setConfirmModal(false);
                    }}
                />
            </AutoReloadBox>
            {!isSubscriptionCancelled && enableAutoReload && <DisclaimerNote currency={workspaceWallet?.currency} />}
        </div>
    );
};

const mapStateToProps = ({
    Payments: { loading, workspaceWallet, subscription, card },
    Workspace: { countryDetails, team, workspaceCountry },
}) => ({
    loading,
    workspaceWallet,
    subscription,
    card,
    countryDetails,
    team,
    workspaceCountry,
});

export default AutoReload;
