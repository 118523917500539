import React, { useState, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import TitleBar from 'components/ReusableComponents/TitleBar';
import FeedbackReceived from './FeedbackReceived';
import FeedbackGiven from './FeedbackGiven';
import EWButton from 'components/ReusableComponents/EWButton';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import SendFeedbackModal from './SendFeedbackModal';
import Banner from 'components/Dashboard/Content/Celebration/Banner';
import { ReactComponent as FeedbackDisabled } from 'Assets/images/feedback-disabled-banner.svg';
import FeedbackCreatedModal from 'components/Modals/PulseCreatedModal';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_FEEDBACK_CONFIGURATION, GET_FEEDBACK_LIST } from 'Services/apiKeys';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { getFeedbackConfiguration } from '../Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { FEEDBACK, TEAM } from '../constants';
import './style.scss';

const FEEDBACK_RECEIVED = 'Feedback received';
const FEEDBACK_GIVEN = 'Feedback given';

const Index = ({ history }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const { feedbackConfigs, isAdmin, adminEmail, managerId } = useSelector(mapStateToProps, shallowEqual);
    const [key, setKey] = useState(FEEDBACK_RECEIVED);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackCreated, setFeedbackCreated] = useState(false);
    const [showNoManagerModal, setShowNoManagerModal] = useState(false);
    const feedbackSentModalData = useRef({
        title: 'Feedback shared successfully',
        buttonText: 'Show message',
    });
    const [feedbackCategories, setFeedbackCategories] = useState([]);

    const NO_MANAGER_MODAL_DATA = {
        confirm: 'Close',
        heading: 'Cannot send feedback',
        subHeading: `You have no manager assigned yet, you can give feedback only if a manager is assigned. Please contact your admin for more details- ${adminEmail}`,
    };

    const { isLoading } = useQuery([GET_FEEDBACK_CONFIGURATION], getFeedbackConfiguration, {
        onSuccess: (data) => {
            const config = data?.data;
            if (config?.hasOwnProperty('enabled')) {
                updateWorkspaceDetails(dispatch, { feedback: { ...config } });
            }
            setFeedbackCategories(config?.categories || []);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    /**
     * Opens feedback sent modal and sets the data required to show
     * @param {string} messageUrl - slack message url to open
     * @param {string} userName - name of the user to whom the feedback was sent
     * @param {boolean} anonymous - feedback was sent anonymously or not
     */
    const handleFeedbackCreatedModalSuccess = (messageUrl, userName, anonymous) => {
        setShowFeedbackModal(false);
        feedbackSentModalData.current = {
            ...feedbackSentModalData.current,
            content: `Your feedback was shared with ${userName} ${anonymous ? 'anonymously' : ''}`,
            messageUrl,
        };
        setFeedbackCreated(true);
    };

    const handleShowFeedbackModalClick = () => {
        if (feedbackConfigs?.feedbackLevel === TEAM && !managerId) {
            setShowNoManagerModal(true);
            return;
        }
        setShowFeedbackModal(true);
    };

    return (
        <div className='feedback-container navigatortabs'>
            <TitleBar title='My Feedback'>
                {feedbackConfigs?.enabled && (
                    <EWButton
                        buttonText='Send feedback'
                        buttonStyleClass='margin-botton-neg-65'
                        onClick={handleShowFeedbackModalClick}
                    />
                )}
            </TitleBar>
            {isAdmin && !isLoading && !feedbackConfigs?.enabled && (
                <div className='mb-3'>
                    <Banner
                        heading={'You have disabled 360º feedback'}
                        subHeading={'Please enable to access it'}
                        buttonText='Enable 360º feedback'
                        handleClick={() => history.push(FEEDBACK.CONFIGURATION)}
                        image={<FeedbackDisabled className='feedback-banner' />}
                    />
                </div>
            )}
            <Tabs id='my-feedback-tab' activeKey={key} onSelect={setKey}>
                <Tab eventKey={FEEDBACK_RECEIVED} title={FEEDBACK_RECEIVED}>
                    {key === FEEDBACK_RECEIVED && <FeedbackReceived history={history} />}
                </Tab>
                <Tab eventKey={FEEDBACK_GIVEN} title={FEEDBACK_GIVEN}>
                    {key === FEEDBACK_GIVEN && <FeedbackGiven history={history} />}
                </Tab>
            </Tabs>
            {showFeedbackModal && (
                <SendFeedbackModal
                    open={showFeedbackModal}
                    onSuccess={handleFeedbackCreatedModalSuccess}
                    onClose={() => setShowFeedbackModal(false)}
                    categories={feedbackCategories}
                />
            )}
            <FeedbackCreatedModal
                open={feedbackCreated}
                data={feedbackSentModalData.current}
                onConfirm={() => {
                    window.open(feedbackSentModalData.current?.messageUrl, '_blank');
                    setFeedbackCreated(false);
                    queryClient.invalidateQueries(GET_FEEDBACK_LIST);
                }}
                onClose={() => {
                    setFeedbackCreated(false);
                    queryClient.invalidateQueries(GET_FEEDBACK_LIST);
                }}
            />
            <ConfirmModal
                open={showNoManagerModal}
                onClose={() => setShowNoManagerModal(false)}
                onConfirm={() => setShowNoManagerModal(false)}
                data={NO_MANAGER_MODAL_DATA}
                singleButton
            />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

const mapStateToProps = ({ Workspace, User }) => ({
    feedbackConfigs: Workspace.feedback,
    isAdmin: User.isAdmin,
    adminEmail: Workspace?.adminEmail,
    managerId: User?.managerId,
});

export default Index;
