import React from 'react';
import CustomRadioButton from 'components/Styles/CustomRadioButton';
import PropTypes from 'prop-types';
import { TEAM, ORGANIZATION } from '../../constants';
import './style.scss';

const ConfigurationRadioSection = ({ allowFeedback, handleAllowFeedback }) => {
    return (
        <div className='configuration-op-container'>
            <div className='config-anonymous-box'>
                <h5 className='pa-an-header'>Allow feedback to</h5>
                <div>
                    <div className='config-anonimity'>
                        <CustomRadioButton
                            checked={allowFeedback === ORGANIZATION}
                            onChange={() => handleAllowFeedback('feedbackLevel', ORGANIZATION)}
                            title='Anyone in the organization'
                            name='anyone-in-the-organization'
                            anyone-in-the-organisation
                            size={24}
                            bold={allowFeedback === ORGANIZATION}
                        />
                        <p className='subtitle'>Any employee can send feedback to another employee</p>
                    </div>

                    <div className='config-anonimity'>
                        <CustomRadioButton
                            checked={allowFeedback === TEAM}
                            onChange={() => handleAllowFeedback('feedbackLevel', TEAM)}
                            title='Anyone in the same team'
                            name='anyone-in-the-same-team'
                            size={24}
                            bold={allowFeedback === TEAM}
                        />
                        <p className='subtitle'>Reportees of the same manager can send feedback to one other</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
ConfigurationRadioSection.propTypes = {
    allowFeedback: PropTypes.string.isRequired,
    handleAllowFeedback: PropTypes.func.isRequired,
};

export default ConfigurationRadioSection;
