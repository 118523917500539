import React from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import './style.scss';

const Index = ({ open, onClose, data, votesHandler, userButtonHandler, isFeedbackReadableModal = false }) => {
    const modalData = {
        heading: isFeedbackReadableModal ? 'Feedback' : 'Suggestions',
    };
    return (
        <EWModal open={open} onClose={onClose} width='550px' modalData={modalData}>
            <div className='ew-modal-body'>
                <div className='ew-modal-details'>
                    <strong className='ew-modal-details-heading'>From</strong>
                    {isFeedbackReadableModal ? userButtonHandler(data, 'sender') : userButtonHandler(data)}
                </div>
                {isFeedbackReadableModal && (
                    <div className='ew-modal-details'>
                        <strong className='ew-modal-details-heading'>To</strong>
                        {userButtonHandler(data, 'receiver')}
                    </div>
                )}
                <div className='ew-modal-details'>
                    <strong className='ew-modal-details-heading'>Sent on</strong>
                    {data.date}
                </div>
                {isFeedbackReadableModal ? (
                    <>
                        <div>
                            <strong className='ew-modal-details-heading'>Strength</strong>
                            <div>{data.strength.join(', ') || '-'}</div>
                        </div>
                        <div>
                            <strong className='ew-modal-details-heading'>Needs improvement</strong>
                            <div>{data.improvement.join(', ') || '-'}</div>
                        </div>
                    </>
                ) : (
                    votesHandler(data)
                )}

                <div>
                    <strong className='ew-modal-details-heading'>
                        {isFeedbackReadableModal ? 'Feedback message' : 'Suggestion message'}
                    </strong>
                    <div style={{ height: '150px', overflow: 'scroll' }}>{data.message}</div>
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    votesHandler: PropTypes.func,
    userButtonHandler: PropTypes.func,
    isFeedbackReadableModal: PropTypes.bool,
};

export default Index;
