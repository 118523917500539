import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { CustomCheckbox } from 'components';
import Tooltip from 'components/Styles/Tooltip';
import { useToaster } from 'Context/SnackbarContext';
import { isEmptyObject } from 'utils/HelperFunctions';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { handlePointsChange, handleCarryForward, handleCarryAmount } from '../../componentsUtil';
import {
    balanceHeaderReward,
    balanceRewardSubHeader,
    balanceCarryForwardLabel,
    balanceAllottedHeading,
    balanceTooltipInfo,
    balanceCarryForwardHeading,
    balanceNote,
} from '../../constants';
import './style.scss';

const Index = ({ nameForPoints, balance, setBalance }) => {
    const { SetSnackbar } = useToaster();

    if (isEmptyObject(balance)) {
        return null;
    }

    return (
        <div className='config-balance-container'>
            <h3 className='header-reward'>{balanceHeaderReward(nameForPoints)}</h3>
            <p className='sub-header-reward'>{balanceRewardSubHeader(nameForPoints)}</p>
            <div className='points-alloted'>
                <p className='allotted-headings'>{balanceAllottedHeading(nameForPoints)}</p>
                <input
                    className='points-input-value input-item'
                    type='number'
                    value={balance.creditPoints}
                    onChange={(e) =>
                        handlePointsChange({
                            value: e.target.value,
                            balance,
                            setBalance,
                            nameForPoints,
                            SetSnackbar,
                        })
                    }
                />
            </div>
            <div className='forward-container'>
                <div className='form-control-container'>
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={balance.carryForward}
                                onChange={() => handleCarryForward({ balance, setBalance })}
                                name='forward'
                            />
                        }
                        label={<p className='carry-forward-label mb-0'>{balanceCarryForwardLabel(nameForPoints)}</p>}
                    />
                    <Tooltip arrow title={balanceTooltipInfo(nameForPoints)}>
                        <InfoIcon className='tooltip-info-icon' />
                    </Tooltip>
                </div>
                {balance.carryForward && (
                    <div className='points-alloted'>
                        <p className='allotted-headings'>{balanceCarryForwardHeading(nameForPoints)}</p>
                        <input
                            className='points-input-value input-item'
                            type='number'
                            value={balance.maxPoints ? balance.maxPoints : ''}
                            onChange={(e) =>
                                handleCarryAmount({
                                    value: e.target.value,
                                    balance,
                                    setBalance,
                                    nameForPoints,
                                    SetSnackbar,
                                })
                            }
                        />
                        {!!balance.maxPoints && (
                            <p className='note'>
                                <b>Note:</b> {balanceNote(nameForPoints, balance.creditPoints, balance.maxPoints)}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    nameForPoints: PropTypes.string,
    balance: PropTypes.object,
    setBalance: PropTypes.func,
};

export default Index;
