import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
    const AuthToken = Cookies.get('AuthToken');
    let decode;
    if (AuthToken) {
        decode = jwt_decode(AuthToken);
    }
    const [colorCode, setColorCode] = useState(undefined);
    const [onboarding, setOnboarding] = useState(false);
    const [loading, setLoading] = useState(true);
    const [trial, setTrial] = useState({});
    const [isPulseEnabled, setIsPulseEnabled] = useState(true);
    const [members, setMembers] = useState([]);
    const [unassigned, setUnassigned] = useState([]);
    const [managerflow, setManagerflow] = useState(undefined);
    const [enpsPulseCreated, setEnpsPulseCreated] = useState(false);
    const [welcome, setWelcome] = useState(false);
    const [recognition, setRecognition] = useState(false);
    const [reward, setReward] = useState(false);
    const [fromProductHunt, setFromProductHunt] = useState(true);
    const [onboardingInfo, setOnboardingInfo] = useState(null);
    return (
        <CommonContext.Provider
            value={{
                members,
                setMembers,
                unassigned,
                setUnassigned,
                onboarding,
                setOnboarding,
                loading,
                setLoading,
                trial,
                setTrial,
                decode,
                managerflow,
                setManagerflow,
                colorCode,
                setColorCode,
                isPulseEnabled,
                setIsPulseEnabled,
                enpsPulseCreated,
                setEnpsPulseCreated,
                welcome,
                setWelcome,
                recognition,
                setRecognition,
                fromProductHunt,
                setFromProductHunt,
                reward,
                setReward,
                onboardingInfo,
                setOnboardingInfo,
            }}
        >
            {children}
        </CommonContext.Provider>
    );
};

CommonProvider.propTypes = {
    children: PropTypes.node,
};

export const useCommonContext = () => {
    return useContext(CommonContext);
};
