import { createServer } from 'miragejs';
import { paymentsData, customerCardsData, addWalletBalance } from './payment';
import { RewardStats } from './mockStoryData';

export default function makeServer({ environment = 'localhost' } = {}) {
    return createServer({
        environment,
        routes() {
            this.namespace = '/api';

            this.passthrough(`${process.env.REACT_APP_API_URL}/**`);

            // payments
            this.get('/getSubscriptionDetail', paymentsData);

            this.get('/getCustomerCards', customerCardsData);

            this.post('/v2/creditWallet', addWalletBalance);
        },
    });
}

export const mockStoriesServer = () => {
    return createServer({
        routes() {
            this.urlPrefix = 'https://api-dev-springengage.springworks.in/';
            this.get('/analytics/stats', (schema, request) => {
                let queryParams = request.queryParams;
                let data = RewardStats;
                return data;
            });
            this.patch('recognition/configure/workspace', (schema, request) => {
                return new Response(404, {}, { error: 'Not Found' });
            });
        },
    });
};
