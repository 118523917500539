import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import Splash from './image/success.json';
import { useCommonContext } from '../../Context/CommonContext';
import './style.scss';
import { LOCALSTORAGE_KEYS } from 'utils/localStorageKeys';
import { getter } from 'utils/CacheManager';

const Success = ({ history }) => {
    const { setOnboarding } = useCommonContext();
    const onboardingCheck = getter(LOCALSTORAGE_KEYS.ONBOARDING_COMPLETE)?.value ?? false;
    const SplashAnimation = {
        loop: true,
        autoplay: true,
        animationData: Splash,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    useEffect(() => {
        localStorage.removeItem(LOCALSTORAGE_KEYS.ONBOARDING_SELECTED_CHANNELS);
        localStorage.removeItem(LOCALSTORAGE_KEYS.ONBOARDING_RECOGNITION_SETUP);
        localStorage.removeItem(LOCALSTORAGE_KEYS.ONBOARDING_REWARDS_SAVED);
        localStorage.removeItem(LOCALSTORAGE_KEYS.ONBOARDING_FINISHUP_DATA);
        localStorage.removeItem(LOCALSTORAGE_KEYS.ONBOARDING_REWARDS_ENABLED);
        const user = JSON.parse(localStorage.getItem('user') || null);
        const temp = {
            team: user?.team,
            adminId: user?.adminId,
            platform: user?.platform,
        };
        localStorage.setItem('user', JSON.stringify(temp));
        setTimeout(() => {
            history.push('/dashboard/users');
            localStorage.removeItem(LOCALSTORAGE_KEYS.ONBOARDING_COMPLETE);
        }, 2000);
        setOnboarding(false);
        localStorage.removeItem('MemberList');
    }, [history, setOnboarding]);

    if (!onboardingCheck) {
        window.location.href = '/onboarding';
        return;
    }

    return (
        <div className='middle-element'>
            <div className='text-center'>
                <Lottie options={SplashAnimation} height={1000} width={1000} />
                <div className='container-image'>
                    <img src={require('./image/tada.png')} className='tada-img' alt='' />
                    <br />
                    <h2 className='content-spalsh mt-4'>
                        Congratulations, Have fun
                        <br />
                        engaging with your team
                    </h2>
                </div>
            </div>
        </div>
    );
};

Success.propTypes = {
    history: PropTypes.object,
};

export default Success;
