import Cookies from 'js-cookie';
import { LOCALSTORAGE_KEYS } from 'utils/localStorageKeys';

export const userLogout = () => {
    try {
        const userData = JSON.parse(localStorage.getItem('user') || null);
        localStorage.clear();
        Cookies.remove('AuthToken');
        window.location.href = `/login/${userData?.platform ? '?platform=' + userData.platform : ''}`;
        return true;
    } catch (error) {
        return false;
    }
};

export const inactiveRedirect = () => {
    try {
        localStorage.clear();
        Cookies.remove('AuthToken');
        window.open(process.env.REACT_APP_BASE_URL + 'inactive', '_self');
        return true;
    } catch (error) {
        return false;
    }
};

/**
 * Method redirects to target location while maintaining an active state in nav bar.
 * @param {Object} history
 * @param {String} redirectionURL
 * @param {String} navMenuCode - Fixed codes defines in HISTORY_FROM
 */
export const handlePageRedirectionWithinSubMenu =
    ({ history, redirectionURL, navMenuCode }) =>
    () => {
        localStorage.setItem(LOCALSTORAGE_KEYS.NAVIGATION_FROM, navMenuCode);
        history.push(redirectionURL, { from: navMenuCode });
    };
