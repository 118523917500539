import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { debounce, find, findIndex } from 'lodash';
import { useQuery } from 'react-query';
import { pulseQuestionTypes } from 'constants.js';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { BlueTag } from 'utils/Stylesheet/style';
import { getQuestionLibrary } from 'Services/apiFunctions';
import { GET_QUESTION_LIBRARY } from 'Services/apiKeys';
import Tooltip from 'components/Styles/Tooltip';
import ConditionalWrapper from 'components/ReusableComponents/ConditionalWrapper';
import { isEmptyObject } from 'utils/HelperFunctions';

const Library = ({
    questions,
    setQuestions,
    addedLibraryQuestion,
    setAddedLibraryQuestion,
    openQuestion,
    setOpenQuestion,
    scrollToAddQuestionButton,
}) => {
    const [search, setSearch] = useState('');
    const [categoryList, setCategoryList] = useState([{ label: 'All categories', value: '' }]);
    const [activeCategory, setActiveCategory] = useState({});

    const { data, isLoading } = useQuery(GET_QUESTION_LIBRARY, getQuestionLibrary);

    const handleSearch = debounce((value) => setSearch(value), 300);
    const handleAddQuestionFromLibrary = (index) => {
        const tempQuestions = [...questions];
        if (openQuestion > -1 && tempQuestions.length > 0 && tempQuestions[openQuestion]) {
            tempQuestions[openQuestion].question.name =
                tempQuestions[openQuestion].question.name || 'Untitled question';
            if (tempQuestions[openQuestion].questionType === 'MCQ') {
                tempQuestions[openQuestion].options.forEach((option, optionIndex) => {
                    option.name = option.name || `Choice ${optionIndex + 1}`;
                });
            }
        }
        setQuestions([
            ...tempQuestions,
            {
                id: nanoid(),
                question: { name: data.questions[index].title, error: false },
                questionType:
                    data.questions[index].type === 'MULTIPLE_CHOICE' || data.questions[index].type === 'SINGLE_CHOICE'
                        ? 'MCQ'
                        : data.questions[index].type,
                questionLabel:
                    data.questions[index].type === 'MULTIPLE_CHOICE' || data.questions[index].type === 'SINGLE_CHOICE'
                        ? 'Multiple choice'
                        : find(pulseQuestionTypes, (type) => type.value === data.questions[index].type).label,
                required: false,
                multipleSelection: data.questions[index].type === 'MULTIPLE_CHOICE',
                options: data.questions[index].options?.length
                    ? data.questions[index].options.map((option) => ({ name: option, error: false }))
                    : [
                          { name: '', error: false },
                          { name: '', error: false },
                      ],
                libraryIndex: index,
            },
        ]);
        setOpenQuestion(tempQuestions.length);
        const tempNewAddedList = { ...addedLibraryQuestion };
        tempNewAddedList[index] = true;
        setAddedLibraryQuestion(tempNewAddedList);
        scrollToAddQuestionButton();
    };
    const handleCategoryFilter = (index) => {
        if (index === 0 && !isEmptyObject(activeCategory)) {
            setActiveCategory({});
            return;
        }
        if (activeCategory[categoryList[index].value]) {
            const tempActiveCategory = { ...activeCategory };
            delete tempActiveCategory[categoryList[index].value];
            setActiveCategory(tempActiveCategory);
            return;
        }
        if (index > 0) {
            const tempActiveCategory = { ...activeCategory };
            tempActiveCategory[categoryList[index].value] = true;
            setActiveCategory(tempActiveCategory);
        }
    };

    useEffect(() => {
        if (data) {
            setCategoryList([
                ...categoryList,
                ...data.categories.map((categoryItem) => ({ label: categoryItem, value: categoryItem })),
            ]);
        }
    }, [data]);

    if (isLoading || !data) {
        return null;
    }

    const isEmpty =
        findIndex(
            data.questions,
            (question, index) =>
                question.title.toLowerCase().includes(search.toLowerCase().trim()) &&
                (isEmptyObject(activeCategory) || activeCategory[question.category]) &&
                !addedLibraryQuestion[index]
        ) < 0;

    return (
        <div className='question-library-container'>
            <div className='pulse-library-heading'>
                <h3 className='header-3'>Library</h3>
                <CustomFilterDropdown
                    dropDownID='dropdown-library'
                    selectedName={
                        isEmptyObject(activeCategory)
                            ? 'All Categories'
                            : `Selected (${Object.keys(activeCategory).length})`
                    }
                    filterOptions={categoryList.map((category, index) => ({
                        ...category,
                        isChecked: (index === 0 && isEmptyObject(activeCategory)) || activeCategory[category.value],
                    }))}
                    optionsSelected
                    buttonStyleClass='width-137'
                    handleSelection={handleCategoryFilter}
                />
            </div>
            <CustomSearchBox
                placeholder='Search for questions'
                handleSearch={(event) => handleSearch(event.target.value)}
            />
            {isEmpty && (
                <div className='response-empty'>
                    <div className='response-empty-image' />
                    <p className='no-response-text'>No results found</p>
                </div>
            )}
            <div>
                {data.questions.map(
                    (question, index) =>
                        question.title.toLowerCase().includes(search.toLowerCase().trim()) &&
                        (isEmptyObject(activeCategory) || activeCategory[question.category]) &&
                        !addedLibraryQuestion[index] && (
                            <div key={question.order} className='pulse-library-question-container'>
                                <BlueTag>{question.category}</BlueTag>
                                <button className='ew-btn tb-btn' onClick={() => handleAddQuestionFromLibrary(index)}>
                                    + Add
                                </button>
                                <ConditionalWrapper
                                    condition={question?.title?.length > 60}
                                    wrapper={(children) => <Tooltip title={question.title}>{children}</Tooltip>}
                                >
                                    <p>
                                        {question?.title?.length > 60 && question.title.substring(0, 57) + '...'}
                                        {question?.title?.length <= 60 && question.title}
                                    </p>
                                </ConditionalWrapper>
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

Library.propTypes = {
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    addedLibraryQuestion: PropTypes.object,
    setAddedLibraryQuestion: PropTypes.func,
    openQuestion: PropTypes.number,
    setOpenQuestion: PropTypes.func,
    scrollToAddQuestionButton: PropTypes.func,
};

export default Library;
