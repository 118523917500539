import Mastercard from 'Assets/images/masterCard-big.svg';
import React from 'react';

export const PAYMENT_STATUS = {
    active: 'active',
    canceled: 'canceled',
    trialing: 'trialing',
};

export const PAYMENT_PRODUCTS = {
    Pro: 'Pro',
    Basic: 'Basic',
    Standard: 'Standard',
};

export const PAYMENT_SECTIONS = {
    SUMMARY: 'SUMMARY',
    WALLET: 'WALLET',
    TRANSACTIONAL: 'TRANSACTIONAL',
    PAYMENTS: 'PAYMENTS',
};

export const TRANSACTIONS_FILTERS = {
    all: 'all',
    wallet: 'wallet',
    subscription: 'subscription',
};

export const CARD_LOGS = {
    visa: 'http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_logo_2.gif',
    master: 'http:///www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_2.gif',
    mastercard: Mastercard,
    amex: 'https://www.aexp-static.com/cdaas/one/statics/axp-static-assets/1.8.0/package/dist/img/logos/dls-logo-bluebox-solid.svg',
    diners: 'https://www.dinersclub.com/assets/images/diners-club-international-logo-white.png',
};

export const DROPDOWN_TYPES = {
    AUTO_RELOAD: 'AUTO_RELOAD',
    MIN_BALANCE: 'MIN_BALANCE',
};

export const PRESELECTED_AMOUNTS_LISTS = ({ countryCode = 'IN' }) => {
    const loopFn = (each) => ({ value: each, label: each });
    if (countryCode === 'IN') {
        return {
            AUTO_RELOAD: [5000, 10000, 20000, 50000, 100000].map(loopFn),
            MIN_BALANCE: [1000, 3000, 5000, 10000, 25000].map(loopFn),
        };
    }
    return {
        AUTO_RELOAD: [100, 500, 1000, 5000].map(loopFn),
        MIN_BALANCE: [25, 100, 500, 1000].map(loopFn),
    };
};

export const CARD_ACTION_TYPES = {
    NEW: 'NEW',
    MAKE_DEFAULT: 'MAKE_DEFAULT',
};

export const CONFIRM_MODALS_TYPES = {
    ADD_FUND: 'ADD_FUND',
    AUTO_RELOAD: 'AUTO_RELOAD',
};

export const CONFIRM_MODALS_DATA = {
    ADD_FUND: {
        header: 'Add Funds to Wallet',
        content: ({ card, amount, currencySymbol }) => (
            <>
                Your card ending in <strong>{card}</strong> will be charged{' '}
                <strong>
                    <span dangerouslySetInnerHTML={{ __html: currencySymbol }} />
                    {amount}
                </strong>
            </>
        ),
        confirm: 'Add funds',
    },
    AUTO_RELOAD: {
        header: 'Disable auto-reload',
        content: () => 'Are you sure you want to disable auto-reload?',
        confirm: 'Confirm',
    },
};
