import { getData, postData, patchData, deleteData } from 'Services/apiCall';
import queryString from 'query-string';

/**
 * Fetch the feedback recieved/given list
 */
export const getFeedbackList = async ({ queryKey: [_key, page, type, order, selectedUser] }) => {
    try {
        const queryParams = {
            page,
            type,
            order,
            ...(selectedUser && { selectedUser }),
        };
        const queryStringParams = queryString.stringify(queryParams);
        const path = `feedback/list?${queryStringParams}`;
        const { data } = await getData(path);
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

/**
 * API to get the feedback configurations
 * @returns data
 */
export const getFeedbackConfiguration = async () => {
    try {
        const { data } = await getData('feedback/configuration');
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

/**
 * API to get the feedback categories list
 * @returns data
 */

export const getFeedbackCategoriesList = async () => {
    try {
        const { data } = await getData('feedback/categories');
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const addFeedbackCategory = async ({ apiData }) => {
    try {
        return await postData(`feedback/categories`, apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateFeedbackCategory = async ({ prevCategory, apiData }) => {
    try {
        return await patchData(`feedback/categories?category=${encodeURIComponent(prevCategory)}`, apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deleteCategory = async (category) => {
    try {
        await deleteData(`feedback/categories?category=${encodeURIComponent(category)}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

/**
 * API to update the feedback configs
 * @param {object} apidata
 * @returns
 */
export const updateFeedbackConfiguration = async ({ apiData }) => {
    try {
        return await postData('feedback/configuration', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

/**
 * API to send the feedback message
 * @param {object} apiData
 * @returns
 */
export const sendFeedback = async ({ apiData }) => {
    try {
        const { data } = await postData('feedback/send', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
