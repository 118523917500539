import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox, Tooltip } from 'components';
import { useToaster } from 'Context/SnackbarContext';
import RewardPoint from 'components/ReusableComponents/RewardPoint';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { handlePointsUpdate, handleApprovalChange } from '../../componentsUtil';
import { recogSetupHeaderReward, recogSetupSubHeader, approvalReq, approvalReqToolTip } from '../../constants';
import clsx from 'clsx';
import './style.scss';

const Index = ({ rewardsData, nameForPoints, rewards, setRewards, kudosName, shoutoutName }) => {
    const { SetSnackbar } = useToaster();

    return (
        <div className='recognition-config-container'>
            <div className='container-main-recognitions'>
                <h3 className='header-reward'>{recogSetupHeaderReward}</h3>
                <p className='sub-header-reward'>{recogSetupSubHeader(nameForPoints)}</p>
                <div
                    className={clsx({
                        'container-recognitions': 1,
                        'justify-content-between': nameForPoints?.length > 9,
                    })}
                >
                    {rewards?.map((reward, index) => (
                        <div className='reward-item' key={reward?.name}>
                            <RewardPoint
                                key={reward?.name}
                                index={index}
                                rewardName={reward?.name}
                                points={reward?.points}
                                nameForPoints={nameForPoints}
                                handlePointsUpdate={(index, value) =>
                                    handlePointsUpdate({
                                        index,
                                        value,
                                        SetSnackbar,
                                        rewards,
                                        setRewards,
                                        kudosName,
                                        shoutoutName,
                                        rewardsData,
                                    })
                                }
                            />
                            <div className='container-approval'>
                                <CustomCheckbox
                                    checked={reward.isApprovalRequired}
                                    onChange={() => handleApprovalChange({ index, rewards, setRewards, rewardsData })}
                                    name={'reward' + index}
                                />
                                <p>{approvalReq}</p>
                                <Tooltip title={approvalReqToolTip(reward?.name)}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    rewardsData: PropTypes.array,
    nameForPoints: PropTypes.string,
    rewards: PropTypes.array,
    setRewards: PropTypes.func,
    kudosName: PropTypes.string,
    shoutoutName: PropTypes.string,
};

export default Index;
