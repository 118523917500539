import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ image, heading, subHeading, buttonText, handleClick }) => {
    return (
        <div className='container-banner row ml-0'>
            <div className='banner-img'>{image}</div>
            <div className='col-lg-9'>
                <p className='missing-date-count mt-3 mb-0'>{heading}</p>
                <p className='cb-subheading'>{subHeading}</p>
                <div>
                    <button className='ew-btn pb-btn' onClick={handleClick}>
                        {buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    image: PropTypes.element,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    buttonText: PropTypes.string,
    handleClick: PropTypes.func,
};

export default Index;
