import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getAdminList, getIntegratedChannelsList } from 'Services/apiFunctions';
import { GET_ADMIN_LIST, GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { AddAdmin, Admin, Channel, OrgSettings } from 'components/ReusableComponents/Settings';
import { useToaster } from 'Context/SnackbarContext';
import { shallowEqual, useSelector } from 'react-redux';
import { isSlack } from 'utils/HelperFunctions';

const Index = () => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const { SetSnackbar } = useToaster();
    const [channels, setChannels] = useState([]);

    const isSlackPlatform = isSlack(platform);

    const onError = (error) => {
        SetSnackbar({
            open: true,
            variant: 'error',
            message: error?.message || 'Some error occurred',
        });
    };

    const handeChannelData = (channelData) => {
        setChannels(
            channelData.map((channel) => ({
                ...channel,
                label: isSlackPlatform ? channel.channelName : channel.groupName,
                value: isSlackPlatform ? channel.channelID : channel.groupId,
                alreadyPresent: true,
            }))
        );
    };

    const { data: adminData, isLoading: adminLoading } = useQuery([GET_ADMIN_LIST], getAdminList, onError);

    const { isLoading: channelDataLoading } = useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => handeChannelData(data),
        onError,
    });

    const [addAdminOpen, setAddAdminOpen] = useState(false);
    const [addChannelOpen, setAddChannelOpen] = useState(false);

    const orgSection = [
        {
            heading: 'EngageWith admins',
            subtext: 'Manage admin permissions for EngageWith',
            button: '+Add Admin',
            component: <Admin data={adminData?.data || []} isLoading={adminLoading} />,
            disabled: !adminData || adminData.data.length > 9,
            disabledMessage: 'Maximum of 10 admins allowed',
        },
        {
            heading: `EngageWith integrated ${isSlackPlatform ? 'channels' : 'teams'}`,
            subtext: `Employees from integrated ${
                isSlackPlatform ? 'channels' : 'teams'
            } will be added to EngageWith as active users.`,
            button: `+Add ${isSlackPlatform ? 'channels' : 'teams'}`,
            component: (
                <Channel
                    addChannelOpen={addChannelOpen}
                    setAddChannelOpen={setAddChannelOpen}
                    channelData={channels}
                    isLoading={channelDataLoading}
                />
            ),
        },
    ];

    const handleOpenModal = (index) => {
        if (index === 0) {
            setAddAdminOpen(true);
        } else if (index === 1) {
            setAddChannelOpen(true);
        }
    };

    return (
        <div>
            {orgSection.map((blockData, index) => (
                <OrgSettings
                    blockData={blockData}
                    handleOpenModal={handleOpenModal}
                    index={index}
                    key={blockData.heading}
                />
            ))}
            {addAdminOpen && (
                <AddAdmin open={addAdminOpen} setOpen={setAddAdminOpen} initialAdminCount={adminData?.data.length} />
            )}
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    config: Workspace.config,
    platform: Workspace.platform,
});

export default Index;
