import { getData, postData } from 'Services/apiCall';
import queryString from 'query-string';

export const postRecognition = async ({ apiData }) => {
    try {
        const { data } = await postData('recognition', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecognitions = async ({ pageParam = 1, queryKey: [_key, recognitionId, userIds] }) => {
    try {
        const queryParams = {
            page: pageParam,
            ...(recognitionId && { recognitionId }),
            ...(userIds?.length > 0 && { userIds: userIds.join(',') }),
        };
        const queryStringParams = queryString.stringify(queryParams);
        const path = `recognition/feed?${queryStringParams}`;
        return await getData(path);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPendingRecognitions = async ({ queryKey: [_key] }) => {
    try {
        const path = 'users/tasks?page=1&feed=true';
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const enhanceMessage = async (message) => {
    const payload = {
        message: message,
    };

    try {
        const path = 'recognition/enhance-with-ai';
        const { data } = await postData(path, payload);
        return data.message;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};
