import React from 'react';
import './style.scss';

const Index = () => {
    return (
        <div className='time-info'>
            <p className='head'>
                Current Timezone:&nbsp;
                <strong>{Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').join('-')}</strong>
            </p>
            <p className='description'>Pulse will be sent based on your timezone and not the recipients</p>
        </div>
    );
};

export default Index;
