import React from 'react';
import Recognizer from '../../../RecognitionItem/Recognizer';
import RecognitionDate from '../../../RecognitionItem/RecognitionDate';
import RecognizedUsers from '../../../RecognitionItem/RecognizedUsers';
import RecognitionMessage from '../../../RecognitionItem/RecognitionMessage';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ addOnRecognitionData }) => {
    return (
        <div className='container-add-on-recognitions-list-item'>
            <RecognitionDate date={addOnRecognitionData?.timestamp} />
            <div style={{ marginTop: '12px' }}>
                <Recognizer
                    userName={addOnRecognitionData?.sender?.userName}
                    pictureURL={addOnRecognitionData?.sender?.pictureURL}
                    recognitionType={`add on ${addOnRecognitionData?.type}`}
                    showDownArrowIcon={false}
                />
            </div>
            <RecognizedUsers
                showValue={false}
                users={addOnRecognitionData?.receivers}
                value={addOnRecognitionData?.value}
            />
            <RecognitionMessage
                showMessageTitle={false}
                showGiveAddOnButton={false}
                recognitionData={addOnRecognitionData}
                showAddOnValue={true}
                showRecognitionImage={false}
                showViewMoreButtonForMessage={false}
            />
            <div className='cao-recognitions-divider' />
        </div>
    );
};

Index.propTypes = {
    addOnRecognitionData: PropTypes.object.isRequired,
};

export default Index;
