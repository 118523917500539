import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EWLogo } from 'Assets/images/EW-logo.svg';
import { ReactComponent as EWWelcomeBanner } from 'Assets/images/EW-welcome-banner.svg';
import './style.scss';

const Index = ({ history }) => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center height-100-vh bg-white'>
            <EWLogo />
            <EWWelcomeBanner className='margin-top-10' />
            <div className='welcome-heading'>Welcome to EngageWith</div>
            <div className='welcome-sub-heading'>{'Your partner in building a people-first culture'}</div>
            <button className='ew-btn pb-btn btn width-200' onClick={() => history.push('/onboarding/setup')}>
                Let's get started
            </button>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
