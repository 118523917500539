import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import UserSelectionDropdown from 'components/ReusableComponents/UserSelectionDropdown';
import LabelWithImage from 'components/ReusableComponents/LabelWithImage';
import EWModal from 'components/ReusableComponents/EWModal';
import { useMutation, useQueryClient } from 'react-query';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TeamCount from 'components/ReusableComponents/TeamCount';
import { useToaster } from 'Context/SnackbarContext';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { modifyUser } from 'Services/apiFunctions';

const Index = ({ open, setOpen, currentManager, handleBack }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();

    const { mutateAsync: changeTeamManager, isLoading } = useMutation(modifyUser);

    const handleSave = () => {
        if (!selectedUser?.userName) {
            return;
        }
        setConfirmOpen(open);
    };

    const modalData = {
        heading: (
            <span>
                <span className='pointer-cursor' onClick={handleBack}>
                    <ArrowBackIosIcon
                        style={{ width: '16px', height: '16px', marginBottom: '2px', color: '#15222F' }}
                    />
                </span>
                Change manager for the team <TeamCount count={currentManager.team.length} />{' '}
            </span>
        ),
        subHeading: `Change the manager of ${currentManager.team.length} users from ${currentManager?.userName} to a new team manager`,
        rightButtonText: 'Save',
        handleRightButtonClick: handleSave,
        leftButtonText: 'Go Back',
        handleLeftButtonClick: handleBack,
        loading: isLoading,
        disabled: isLoading || !selectedUser?.userName,
    };

    const handleChangeTeamConfirm = async () => {
        try {
            setConfirmOpen(false);
            const apiData = {
                managerId: selectedUser?._id,
                userIds: currentManager.team.map((member) => member._id),
            };
            await changeTeamManager({ apiData });
            showSnackBarMessage(SetSnackbar, 'success', 'Manager changed successfully.');
            setOpen(false);
            queryClient.invalidateQueries(GET_PAGINATED_USERS);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const confirmModalProps = {
        open: confirmOpen,
        onClose: () => setConfirmOpen(false),
        onConfirm: () => handleChangeTeamConfirm(),
        height: '200px',
        data: {
            heading: `Are you sure you want to reassign ${currentManager?.userName}'s team to ${selectedUser?.userName}`,
            confirm: 'Confirm',
        },
    };

    return (
        <div>
            <EWModal open={!confirmOpen} onClose={() => setOpen(false)} width='528px' modalData={modalData}>
                <div className='mt-2'>
                    <UserSelectionDropdown
                        id='selectManager'
                        title='Manager'
                        dropdownWidth={480}
                        buttonWidth={480}
                        selectedUser={
                            <LabelWithImage
                                {...(selectedUser && {
                                    image: selectedUser?.pictureURL || require('Assets/images/defaultUser.png'),
                                })}
                                name={selectedUser?.userName || 'Select a manager'}
                                teamSize={selectedUser?.team?.length}
                            />
                        }
                        handleUserSelection={(user) => setSelectedUser(user)}
                        placeholder='Search name'
                        showTeam={true}
                        optionsSelected={!!selectedUser}
                    />
                </div>
            </EWModal>
            <ConfirmModal {...confirmModalProps} />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    currentManager: PropTypes.object,
    handleBack: PropTypes.func,
};

export default Index;
