import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import CustomToggle from 'components/ReusableComponents/CustomToggle';
import './style.scss';
import { map } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import { useQuery } from 'react-query';
import { GET_RNR_LINE_DATA } from 'Services/apiKeys';
import { getRandRLineChartData } from 'Services/apiFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { ANALYTICS_GROUPBY, ANALYTICS_VIEWS } from 'constants.js';
import { capitalizeFirstLetter, showSnackBarMessage } from 'utils/HelperFunctions';

const Index = ({ cohort, startDate, endDate, groupBy, view, setView, setAnalyticsGroupBy }) => {
    const { SetSnackbar } = useToaster();

    const { data: lineChartData, isLoading } = useQuery(
        [GET_RNR_LINE_DATA, cohort, startDate, endDate, groupBy, view],
        getRandRLineChartData,
        {
            keepPreviousData: false,
            onSuccess: (data) => setView(data.view),
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        }
    );

    const handleSelection = (value) =>
        setAnalyticsGroupBy(value === 0 ? ANALYTICS_GROUPBY.RECOGNITIONS : ANALYTICS_GROUPBY.REDEMPTIONS);

    let count = lineChartData?.data?.length;
    const data = {
        labels: map(lineChartData?.data, '_id'),

        datasets: [
            {
                // label: 'My First Dataset',
                data: map(lineChartData?.data, groupBy === ANALYTICS_GROUPBY.REDEMPTIONS ? 'redemptionCount' : 'count'), // has the y-axis values for each x-axis value.
                fill: false, // set to false means that we don’t have any colors between the x-axis and our line.
                borderWidth: 2, // specifies the thickness of the line in pixels.
                borderColor: count > 0 ? '#5d5cf5' : '#F1F3F6', // color of the line
                tension: 0,
                pointBackgroundColor: count > 0 ? '#5d5cf5' : '#D7DCE2', // The fill color for points.
                pointBorderColor: count > 0 ? '#5d5cf5' : '#D7DCE2', // The border color for points.
                pointRadius: 4,
            },
        ],
    };

    const getRewards = (rewardsData) => rewardsData.map((reward) => `${reward?.name}: ${reward?.count}`);

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false, // If true, color boxes are shown in the tooltip.
                backgroundColor: '#fff', // Background color of the tooltip.
                borderColor: 'rgba(0, 0, 0, 0.06)', // Color of the border.
                borderWidth: 2, // size of the border.
                cornerRadius: 8, // Radius of tooltip corner curves.
                padding: {
                    x: 10,
                    y: 8,
                }, // Padding inside the tooltip.
                titleColor: '#82878D', // Color of title text.
                bodyColor: '#15222F', // Color of body text.
                bodySpacing: 4, // Spacing to add to top and bottom of each tooltip item.
                callbacks: {
                    title: function (_context) {
                        return _context[0].label;
                    },
                    label: function (context) {
                        if (groupBy === ANALYTICS_GROUPBY.REDEMPTIONS) {
                            return [`Count: ${lineChartData?.data?.[context?.dataIndex]?.redemptionCount}`];
                        } else {
                            return getRewards(lineChartData?.data?.[context?.dataIndex]?.rewards || []);
                        }
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // If false, do not display grid lines for this axis.
                    drawBorder: false,
                },
                ticks: {
                    color: '#82878D', // The colour of the tick marks.
                },
            },
            y: {
                grid: {
                    display: true,
                    borderDash: [10, 15], // Length and spacing of dashes on grid lines.
                    drawBorder: false, // If true, draw a border at the edge between the axis and the chart area.
                },
                beginAtZero: true,
            },
        },
        responsive: true,
        maintainAspectRatio: true,
    };

    return (
        <div className='analytics-line-container'>
            <div className='d-flex'>
                <CustomToggle
                    option1='Recognitions'
                    option2='Redemptions'
                    handleSelection={handleSelection}
                    firstOptionSelected={groupBy === ANALYTICS_GROUPBY.RECOGNITIONS}
                />
                {view && (
                    <CustomFilterDropdown
                        dropDownID='selectView'
                        filterOptions={ANALYTICS_VIEWS}
                        selectedName={capitalizeFirstLetter(view)}
                        handleSelection={(index) => setView(ANALYTICS_VIEWS[index])}
                        buttonStyleClass='view-dropdown'
                        singleSelect
                        optionsSelected
                        dropdownHeight={125}
                    />
                )}
            </div>
            {isLoading && <CustomLoader />}
            {!isLoading && <Line data={data} options={options} />}
        </div>
    );
};

Index.propTypes = {
    cohort: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    groupBy: PropTypes.string,
    view: PropTypes.string,
    setView: PropTypes.func,
    setAnalyticsGroupBy: PropTypes.func,
};

export default Index;
