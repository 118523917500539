import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
import { ReactComponent as ClockDelayIcon } from 'Assets/images/clock_delayed.svg';
import EllipsisText from 'components/ReusableComponents/EllipsisText';

const RedemptionRow = ({ rowData, index, handleTnc, handleRI, toggleCoupon }) => {
    const handleExpiryDate = (date) => {
        const expiryDate = new Date(date);
        if (date && isValid(expiryDate)) {
            return format(expiryDate, 'yyyy-MM-dd');
        }
        return date;
    };

    const couponHelper = (lineItem) => {
        if (lineItem?.deliveryType === 'delayed') {
            const fulfillmentDate = new Date(lineItem.fulfillmentDate).getTime();
            if (Date.now() < fulfillmentDate) {
                return (
                    <>
                        <ClockDelayIcon />
                        <span className='fulfilment-date'>
                            Coupon code will be available on {format(new Date(lineItem.fulfillmentDate), 'yyyy-MM-dd')}
                        </span>
                    </>
                );
            }
        }
        if (lineItem?.voucherCode || !lineItem?.isCustom) {
            return (
                <button className='btn' onClick={() => toggleCoupon(index)}>
                    View coupon code
                </button>
            );
        }
        return <p className='no-coupon-msg'>No coupon code available</p>;
    };

    return (
        <div className='purchased-rewards'>
            <div className='d-flex'>
                {rowData?.productImage &&
                    (rowData?.productImage.includes('http') ? (
                        <img className='logo-pic' src={rowData?.productImage} alt='Card' />
                    ) : (
                        <div className='logo-pic'>{rowData?.productImage}</div>
                    ))}
                <div className='card-details'>
                    <EllipsisText text={rowData?.name} customClassName=' bold card-title mb-0' white />
                    <EllipsisText text={rowData?.categories || 'Others'} white customClassName=' card-category' />
                    <p className='card-currency'>
                        {rowData?.currency}&nbsp;
                        <span className='card-amount'>{parseFloat(rowData?.amount).toFixed(2)}</span>
                    </p>
                </div>
            </div>
            <div className='mt-2 middle-column'>
                {rowData?.orderId ? (
                    <p className='order-text'>
                        Order ID: <span>{rowData.orderId}</span>
                    </p>
                ) : (
                    <p />
                )}
                <p className='order-text'>
                    Purchased on: <span>{rowData?.dateOfPurchase.substring(0, 10)}</span>
                </p>
                <p className='order-text'>
                    {!rowData?.validity ? `Does not expire` : `Expires on: `}
                    <span>{handleExpiryDate(rowData?.validity)}</span>
                </p>
            </div>
            <div className='last-column'>
                {couponHelper(rowData)}
                <p>
                    {rowData?.termsAndConditionsInstructions && (
                        <button
                            className='mr-2 redemption-text-btn'
                            onClick={() => handleTnc(rowData?.termsAndConditionsInstructions)}
                        >
                            Terms & Conditions
                        </button>
                    )}
                    <button className='redemption-text-btn' onClick={() => handleRI(rowData?.redemptionInstructions)}>
                        Redemption instructions
                    </button>{' '}
                </p>
            </div>
        </div>
    );
};

RedemptionRow.propTypes = {
    rowData: PropTypes.object,
    index: PropTypes.number,
    handleTnc: PropTypes.func,
    handleRI: PropTypes.func,
    toggleCoupon: PropTypes.func,
};

export default RedemptionRow;
