import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { userLogout, inactiveRedirect } from '../utils/SessionUtils/sessionUtils';
let apiPath = process.env.REACT_APP_API_URL;

if (!apiPath && process.env.STORYBOOK_APP_API_URL) {
    apiPath = process.env.STORYBOOK_APP_API_URL.trim();
}

function handleHeaders() {
    const headers = {
        'app-version': '1',
        'content-type': 'application/json',
        'device-type': '3',
    };
    const AuthToken = Cookies.get('AuthToken');
    if (AuthToken) {
        const decode = jwt_decode(AuthToken);
        headers['Admin-Id'] = decode['admin-id'];
        headers['user-id'] = decode['admin-id'];
        headers['platform'] = decode['platform'];
    }
    if (AuthToken) {
        headers['Authorization'] = 'Bearer ' + AuthToken;
    }
    return headers;
}

const errorHandler = (error, reject) => {
    if (
        error?.response?.status === 401 ||
        (error?.response?.status === 400 && error?.response?.data?.errors === 'Admin-Id should be present') ||
        (error?.response?.status === 400 &&
            error?.response?.data?.errors ===
                'Enter a valid authorization starting with "Bearer" separated by space') ||
        error?.response?.status === 440
    ) {
        userLogout();
    } else if (error?.response?.status === 403) {
        inactiveRedirect();
    } else {
        reject(error);
    }
};

export const postData = (path, data, auth = null) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios({
            method: 'post',
            responseType: 'json',
            url: apiPath + path,
            data: data,
            auth: auth,
            headers: headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const publicPost = (path, data) => {
    return new Promise(function (resolve, reject) {
        axios({
            method: 'post',
            responseType: 'json',
            url: apiPath + path,
            data: data,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const downLoadFile = (path, method, data, auth = null) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios({
            method: method,
            responseType: 'blob',
            url: apiPath + path,
            ...(data && { data: data }),
            auth: auth,
            headers: headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const postFileAndContent = (path, formData, auth = null) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios
            .post(apiPath + path, formData, { headers: headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const postFile = (path, filePath, auth = null) => {
    const headers = handleHeaders();
    headers['content-type'] = 'multipart/form-data';
    const formData = new FormData();
    formData.append('CSV', filePath[0]);
    return new Promise((resolve, reject) => {
        axios
            .post(apiPath + path, formData, { headers: headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const getData = (path, auth = null) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios({
            method: 'get',
            responseType: 'json',
            url: apiPath + path,
            auth: auth,
            headers: headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const deleteData = (path, data = {}) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios({
            method: 'delete',
            responseType: 'json',
            url: apiPath + path,
            headers: headers,
            data: data,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const putData = (path, data, auth = null) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios({
            method: 'put',
            responseType: 'json',
            url: apiPath + path,
            data: data,
            auth: auth,
            headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const patchData = (path, data, auth = null) => {
    const headers = handleHeaders();
    return new Promise(function (resolve, reject) {
        axios({
            method: 'patch',
            responseType: 'json',
            url: apiPath + path,
            data: data,
            auth: auth,
            headers: headers,
        })
            .then((res) => {
                return resolve(res);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};
