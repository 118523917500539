import React from 'react';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { nanoid } from 'nanoid';

export const getENPSQuestionList = (dispatch, companyName = '') => [
    {
        title: `On a scale from 1-10, how likely are you to recommend ${companyName} as a workplace?`,
        question: (
            <p className='mb-0'>
                On a scale from 1-10, how likely are you to recommend{' '}
                <input
                    type='text'
                    className='company-name-input'
                    placeholder='Type Your Company Name Here'
                    value={companyName}
                    onChange={(e) => {
                        // update company name
                        updateWorkspaceDetails(dispatch, { companyName: e.target.value });
                    }}
                />{' '}
                <br /> as a workplace?
            </p>
        ),
        questionType: 'RATING',
        isENPSStandardQuestion: true,
        required: true,
    },
    {
        title: 'What is the main reason for your score ?',
        question: 'What is the main reason for your score?',
        questionType: 'LONG_TEXT',
        isENPSStandardQuestion: true,
        required: false,
    },
];

export const formattedEnpsAPIData = (eNPSQuestions) => {
    return eNPSQuestions.map((question) => {
        return { title: question.title, type: question.questionType, required: question.required };
    });
};

export const useSavedAsDraftModalData = {
    confirm: 'Use saved draft',
    cancel: 'Create new',
    heading: 'You already have a draft saved, do you want to use that?',
};
export const scheduleDefaultValue = {
    frequencyType: 'one-time',
    onDays: 'monday',
    onDate: new Date(),
    responseForDays: 1,
    rollingPulse: false,
    remindersEnabled: false,
    reminderValue: [],
};

export const defaultAudience = {
    channelList: [],
    userList: [],
    excludedUsers: [],
    anonymous: true,
};

export const getDefaultQuestion = (eNPS) =>
    eNPS
        ? []
        : [
              {
                  id: nanoid(),
                  question: { name: '', error: false },
                  questionType: 'MCQ',
                  questionLabel: 'Multiple choice',
                  required: false,
                  multipleSelection: false,
                  options: [
                      { name: '', error: false, id: nanoid() },
                      { name: '', error: false, id: nanoid() },
                  ],
              },
          ];
