import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SettingsBox } from 'utils/Stylesheet/style';
import Details from '../Details';
import ErrorField from 'components/ReusableComponents/ErrorField';
import InputField from 'components/ReusableComponents/InputField';
import { GET_GLOBAL_SETTINGS } from 'Services/apiKeys';
import { updateGlobalSettings } from 'Services/apiFunctions';
import { useMutation, useQueryClient } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const ERROR_TEXT = 'Please enter a valid number';

const Index = ({ data }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [phone, setPhone] = useState();
    const [error, setError] = useState(false);
    const [edit, setEdit] = useState();

    const { mutate: configureSettings, isLoading: isUpdating } = useMutation(updateGlobalSettings, {
        onSuccess: () => {
            queryClient.invalidateQueries(GET_GLOBAL_SETTINGS);
            setEdit(false);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const handleEdit = () => setEdit(!edit);

    const handlePhoneNumber = (value) => {
        setPhone(!value || isNaN(value) ? '' : value);
        setError(false);
    };

    const handleSave = () => {
        const phoneNumber = phone?.trim();
        if (!phoneNumber || phoneNumber?.length < 7 || phoneNumber?.length > 15) {
            setError(true);
            return false;
        }
        configureSettings({ phoneNumber });
    };

    useEffect(() => {
        setPhone(data?.phoneNumber);
        setEdit(!data?.phoneNumber);
    }, [data]);

    return (
        <SettingsBox className='sc-default-country'>
            <div className='sc-heading-container'>
                <h4 className='sc-heading'>Contact details</h4>
                {!isUpdating && (
                    <p className='sc-button' onClick={handleEdit}>
                        {edit ? 'Cancel' : 'Edit'}
                    </p>
                )}
            </div>
            <p className='sc-sub-heading'>Help us get in touch with you.</p>

            {!edit && (
                <div className='sc-details-container'>
                    <Details label='Phone number:' imageUrl={''} labelData={data?.phoneNumber} />
                </div>
            )}
            {edit && (
                <div className='sc-details-container'>
                    <InputField
                        inputID='phoneNumber'
                        label='Phone Number '
                        placeholder='Enter Phone Number'
                        labelClass='input-label'
                        width='250px'
                        inputType='text'
                        inputClass={error && 'border-red'}
                        value={phone}
                        handleChange={(_id, value) => handlePhoneNumber(value)}
                    />
                    {error && <ErrorField errorText={ERROR_TEXT} className='error-class' />}
                    <p
                        className='sc-button'
                        style={{ color: isUpdating ? '#82878d' : '#5d5cf5' }}
                        onClick={!isUpdating && handleSave}
                    >
                        {`${isUpdating ? 'Saving' : 'Save'} changes`}
                    </p>
                </div>
            )}
        </SettingsBox>
    );
};

Index.propTypes = {
    data: PropTypes.object,
};

export default Index;
