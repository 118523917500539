import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip } from 'chart.js';
import { DOUGHNUT_CHART_COLORS } from 'constants.js';
import { formatNumber } from 'utils/HelperFunctions';

const CoreValues = ({ valueData }) => {
    const [valueChampion, setValueChampion] = useState(valueData?.[0]);

    const count = valueData?.length || 0;
    const doughnutData = {
        datasets: [
            {
                label: 'Company Values',
                data: count > 0 ? valueData?.map((item) => item?.count || 0) : [1],
                backgroundColor: count > 0 ? DOUGHNUT_CHART_COLORS : '#E5E7EA',
                hoverOffset: 8,
            },
        ],
    };

    // Used to show tooltip along cursor position
    Tooltip.positioners.customPosition = function (_elements, eventPosition) {
        return {
            x: eventPosition.x,
            y: eventPosition.y,
        };
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                caretSize: 0, // Size, in px, of the tooltip arrow.
                position: 'customPosition',
                displayColors: false, // If true, color boxes are shown in the tooltip.
                backgroundColor: '#fff', // Background color of the tooltip.
                borderColor: 'rgba(0, 0, 0, 0.06)', // Color of the border.
                borderWidth: 2, // size of the border.
                cornerRadius: 8, // Radius of tooltip corner curves.
                padding: {
                    x: 10,
                    y: 8,
                }, // Padding inside the tooltip.
                titleColor: '#82878D', // Color of title text.
                bodyColor: '#15222F', // Color of body text.
                bodySpacing: 4, // Spacing to add to top and bottom of each tooltip item.
                callbacks: {
                    title: function (_context) {
                        return 'Recognitions';
                    },
                    label: function (context) {
                        if (valueData?.[context.dataIndex]?._id !== valueChampion?._id) {
                            setValueChampion(valueData[context.dataIndex]);
                        }
                        const element = valueData?.[context.dataIndex];
                        let retVal;
                        if (element?.count) {
                            retVal = `${element.count} (${formatNumber(element.percentage)}%)`;
                        } else {
                            retVal = 0;
                        }
                        return retVal;
                    },
                },
            },
        },
        cutout: '75%',
        radius: '95%',
        borderWidth: 0,
        responsive: true,
        maintainAspectRatio: true,
    };

    return (
        <div className='cp-core-values'>
            <h4 className='title'>Core values</h4>
            <div className='doughnut-container'>
                <div className='absolute-center doughnut-center-data'>
                    {valueChampion?.companyValues && <p>{`"${valueChampion?.companyValues}"`}</p>}
                </div>
                <div className='doughnut-chart-container'>
                    <Doughnut data={doughnutData} options={options} />
                </div>
            </div>
        </div>
    );
};

CoreValues.propTypes = {
    valueData: PropTypes.array,
};

export default CoreValues;
