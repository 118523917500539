import React from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation } from 'react-router-dom';

const Index = ({ component, ...rest }) => {
    const admin = JSON.parse(localStorage.getItem('user') || null);
    const Location = useLocation();
    const isLoggedIn =
        Location.search.includes('success') || (admin?.['team'] && admin?.['adminId'] && admin?.['platform']) || false;

    if (!isLoggedIn) {
        window.open(process.env.REACT_APP_LANDER_URL, '_self');
        return null;
    }

    return <Route component={component} {...rest} />;
};

Index.propTypes = {
    component: PropTypes.any,
};

export default Index;
