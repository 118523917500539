import ConditionalWrapper from 'components/ReusableComponents/ConditionalWrapper';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'components';
import React from 'react';

import EnpsTip from '../EnpsTip';

const Score = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: ${(props) => props.borderRadius};
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    width: ${(props) => props.width};
`;

const EnpsResponses = styled.div`
    margin-right: 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: '#4E515B';
`;

const Index = (props) => {
    const { enpsInfo, responses, totalRespondents } = props;

    const enps = {
        passives: Math.round(enpsInfo?.Passive?.percentage),
        promoters: Math.round(enpsInfo?.Promoter?.percentage),
        detractors: Math.round(enpsInfo?.Detractor?.percentage),
        score: Math.round(enpsInfo?.eNPSScore),
    };

    const getENPSScoreColor = (scoreValue) => {
        if (scoreValue < 30) {
            return '#FF6760';
        } else if (scoreValue >= 70) {
            return '#48BB78';
        } else {
            return '#FAC032';
        }
    };

    return (
        <div className='d-flex text-center justify-content-end align-items-center'>
            {!isNaN(enps?.score) && totalRespondents && responses && (
                <EnpsResponses>
                    <strong className='mr-1'>
                        {responses}/{totalRespondents}
                    </strong>
                    responses
                </EnpsResponses>
            )}
            <div
                className='mr-2 d-flex justify-content-center align-items-center rounded'
                style={{
                    width: '50px',
                    height: '33px',
                    backgroundColor: enps?.score ? getENPSScoreColor(enps?.score) : '#E5E7EA',
                    color: enps?.score ? '#FFFFFF' : '#A9ADB1',
                    fontWeight: 600,
                }}
            >
                {enps?.score || '00'}
            </div>
            <ConditionalWrapper
                condition={!isNaN(enps?.score)}
                wrapper={(children) => (
                    <Tooltip
                        white
                        arrow
                        placement='top'
                        title={<EnpsTip {...props} getENPSScoreColor={getENPSScoreColor} />}
                    >
                        {children}
                    </Tooltip>
                )}
            >
                <div className='d-flex text-center rounded' style={{ width: '350px', height: '33px' }}>
                    {!isNaN(enps?.score) ? (
                        <>
                            {enps?.detractors > 0 && (
                                <Score
                                    width={`${enps?.detractors}%`}
                                    borderRadius='4px 0px 0px 4px'
                                    background='#ff676780'
                                    color='#FF6767'
                                >
                                    {`${enps?.detractors}%`}
                                </Score>
                            )}

                            {enps?.passives > 0 && (
                                <Score width={`${enps?.passives}%`} background='#fed06680' color='#ECA90F'>
                                    {`${enps?.passives}%`}
                                </Score>
                            )}

                            {enps?.promoters > 0 && (
                                <Score
                                    width={`${enps?.promoters}%`}
                                    background='#1eb89c80'
                                    color='#18957E'
                                    borderRadius='0px 4px 4px 0px'
                                >
                                    {`${enps?.promoters}%`}
                                </Score>
                            )}
                        </>
                    ) : (
                        <div
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', backgroundColor: '#E5E7EA' }}
                        >
                            {'00'}
                        </div>
                    )}
                </div>
            </ConditionalWrapper>
        </div>
    );
};

Index.propTypes = {
    enpsInfo: PropTypes.object,
    responses: PropTypes.number,
    totalRespondents: PropTypes.number,
};

export default Index;
