import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from 'Assets/images/search.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomCheckbox } from 'components';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { debounce, find } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({
    dropDownID,
    dropdownStyleClass,
    dropdownWidth,
    dropdownHeight,
    selectedUsers,
    handleUserSelection,
}) => {
    const { SetSnackbar } = useToaster();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const onClose = () => {
        setOpen(false);
    };

    const handleSearch = debounce((value) => {
        setSearch(value);
    }, 300);

    const {
        data: dropdownData,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery([GET_USERS_DATA, { search, team: false }], getUsers, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
        retry: 0,
        staleTime: 5 * 60 * 1000,
    });

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className='container-user-search-dropdown'>
                <div className='cusd-input-container'>
                    <SearchIcon className='cusd-search-icon' />
                    <input
                        className='cusd-input'
                        placeholder='Type or select someone'
                        onChange={(e) => handleSearch(e.target.value)}
                        onClick={() => setOpen(true)}
                    />
                </div>
                <div
                    id={dropDownID}
                    className={clsx({
                        'dropdown-container': true,
                        'display-none': !open,
                        [dropdownStyleClass]: dropdownStyleClass,
                    })}
                    style={{
                        height: dropdownHeight,
                        width: dropdownWidth,
                        top: '42px',
                    }}
                >
                    <div>
                        {search || dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                            <div>
                                {dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                                    <div className='infinite-scroll-container'>
                                        <InfiniteScroll
                                            dataLength={dropdownData?.pages?.length * 9}
                                            next={fetchNextPage}
                                            hasMore={hasNextPage}
                                            height={190}
                                            loader={<CustomLoader size={10} />}
                                        >
                                            {dropdownData?.pages?.map((pageData) =>
                                                pageData?.data?.users?.map((user) => (
                                                    <button
                                                        className='dropdown-list'
                                                        key={user._id}
                                                        onClick={() => handleUserSelection(user)}
                                                    >
                                                        <CustomCheckbox
                                                            checked={
                                                                !!find(
                                                                    selectedUsers,
                                                                    (selectedUser) => selectedUser._id === user._id
                                                                )
                                                            }
                                                        />
                                                        <img
                                                            className='dropdown-list-user-image'
                                                            src={
                                                                user?.pictureURL ||
                                                                require('Assets/images/defaultUser.png')
                                                            }
                                                            alt='user'
                                                        />
                                                        <span className='dropdown-list-username'>{user?.userName}</span>
                                                    </button>
                                                ))
                                            )}
                                        </InfiniteScroll>
                                    </div>
                                ) : (
                                    <p className='p-2 text-muted'>No users found</p>
                                )}
                            </div>
                        ) : (
                            <div>{(isLoading || isFetching) && <CustomLoader size={10} />}</div>
                        )}
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    dropDownID: PropTypes.string,
    dropdownStyleClass: PropTypes.string,
    dropdownWidth: PropTypes.number,
    dropdownHeight: PropTypes.number,
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
};

export default Index;
