import CustomLoader from 'components/ReusableComponents/CustomLoader';
import PropTypes from 'prop-types';
import Tooltip from 'components/Styles/Tooltip';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { format } from 'date-fns';
import React from 'react';
import { eNPS_STATUS } from '../../constant';
import './style.scss';

const Index = ({ children, score, loading }) => {
    return (
        <div className='enps-score'>
            <div className='head'>
                <div className='head-left d-inline-block'>
                    <p className='mt-2 text-left'>
                        Total respondents:{' '}
                        {!loading && (
                            <strong>{`${score?.totalRespondents || 0}/${score?.totalRequestedUser || 0}`}</strong>
                        )}
                        <Tooltip
                            white
                            arrow
                            title={`${score?.totalRespondents || 0} responded & ${
                                score?.partialRespondents || 0
                            } partially responded`}
                        >
                            <InfoIcon className='info-icon' />
                        </Tooltip>
                    </p>
                </div>
                {!loading && score?.scheduledAt && !score?.launch && !score.isRecurringStopped && (
                    <div className='head-right d-inline-block '>
                        {score?.status === eNPS_STATUS.SCHEDULED && (
                            <p>
                                Scheduled for:
                                <strong> {format(new Date(score?.scheduledAt), 'dd MMM yyyy, h:mm aaa')}</strong>
                            </p>
                        )}

                        {score?.status !== eNPS_STATUS.SCHEDULED && (
                            <p className='text-right'>
                                Next eNPS: <strong>{format(new Date(score?.scheduledAt), 'dd MMM yyyy ')}</strong>
                            </p>
                        )}
                    </div>
                )}
            </div>
            {loading ? (
                <CustomLoader />
            ) : (
                <div className='body'>
                    <div className='body-left '>{children[0]}</div>
                    <div className='body-right'>
                        {children[1]}
                        {children[2]}
                    </div>
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    children: PropTypes.array,
    dateFilter: PropTypes.array,
    score: PropTypes.object,
    handleChange: PropTypes.func,
    loading: PropTypes.bool,
};

export default Index;
