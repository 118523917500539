import React from 'react';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import clsx from 'clsx';
import './style.scss';

const Index = ({ size = 15, color = '#5D5CF5', marginTop }) => {
    return (
        <div className={clsx({ 'loading-center': true, 'margin-top-20': marginTop })}>
            <BeatLoader size={size} color={color} />
        </div>
    );
};

Index.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    marginTop: PropTypes.bool,
};

export default Index;
