import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import SideNav from './SideNav';
import Header from './Header';
import { getData } from 'Services/apiCall';
import { getOnboardingModules } from 'Services/apiFunctions';
import { useCommonContext } from 'Context/CommonContext';
import CustomLoader from '../Styles/CustomLoader';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import { useToaster } from 'Context/SnackbarContext';
import Cookies from 'js-cookie';
import './style.scss';
import { setter } from 'utils/CacheManager';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import { Helmet } from 'react-helmet';
import DashboardRoutes from './Content/Routes';
import { checkAdmin, updateUserProfile } from 'redux/user/actions';
import { getWorkspaceDetails, updateWorkspaceDetails } from 'redux/workspace/actions';
import { GetSubscriptionDetails } from 'redux/payments/actions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';
import { find } from 'lodash';
import PropTypes from 'prop-types';

const CheckAdmin = (setLoading, history, setColorCode, setIsPulseEnabled, dispatch) => {
    setLoading(true);
    getData('user/profile')
        .then((res) => {
            const {
                isActive: _isActive,
                canRedeemRewards: _canRedeemRewards,
                isAdmin,
                isPulseEnabled,
                themeColor,
                themeColorRGBA,
            } = res.data;
            if (_isActive || _canRedeemRewards) {
                checkAdmin(dispatch, isAdmin);
                if (themeColor || themeColorRGBA) {
                    const RGBA = themeColorRGBA.split(',');
                    RGBA[RGBA.length - 1] = 0.1;
                    setColorCode({ colourCode: themeColor, hover: `rgba(${RGBA.join(',')})` });
                }
            } else {
                history.push(`/inactive/?token=${Cookies.get('AuthToken')}`);
            }
            if (isPulseEnabled === false) {
                setIsPulseEnabled(false);
            }
            updateUserProfile(dispatch, res.data);
        })
        .catch((error) => {
            if (error?.response?.status === 400 && error.response.data.Error === 'No Workspace') {
                userLogout();
            }
        })
        .finally(() => setLoading(false));
};

const fetchOnboardingModules = async ({ onboardingInfo, setOnboardingInfo, SetSnackbar }) => {
    try {
        if (!onboardingInfo) {
            const onboardingAPIResult = await getOnboardingModules();
            if (onboardingAPIResult) {
                setOnboardingInfo(onboardingAPIResult);
            }
        }
    } catch (error) {
        showSnackBarMessage(SetSnackbar, 'error', error.message || 'Error fetching Onboarding Modules');
    }
};

const checkUserToken = (validationId) => {
    // coming from MS Team, the code is encrypted
    return AES.decrypt(validationId.replaceAll(' ', '+'), process.env.REACT_APP_MSTEAMS_ENCRYPTION).toString(encUtf8);
};

const directLogin = async ({
    data,
    history,
    dispatch,
    setLoading,
    setColorCode,
    setIsPulseEnabled,
    SetSnackbar,
    onboardingInfo,
    setOnboardingInfo,
}) => {
    let userToken = data?.token;
    if (data?.validationId) {
        userToken = checkUserToken(data.validationId);
    }

    let decode;
    try {
        decode = jwt_decode(userToken);
        Cookies.set('AuthToken', userToken, {
            expires: 30,
            path: '/',
        });
    } catch (e) {
        userLogout();
        return null;
    }
    checkOnboardingCompletion({ onboardingInfo, setOnboardingInfo, SetSnackbar, history });
    if (decode['admin-id'] && (decode['isActive'] || decode['canRedeemRewards'])) {
        const user = {
            team: decode['team-id'],
            adminId: decode['admin-id'],
            platform: decode['platform'],
        };
        updateUserProfile(dispatch, { adminId: decode['admin-id'] });
        updateWorkspaceDetails(dispatch, { team: decode['team-id'], platform: decode.platform });
        setter('user', user);
        CheckAdmin(setLoading, history, setColorCode, setIsPulseEnabled, dispatch);
        GetSubscriptionDetails(dispatch, SetSnackbar);
        getWorkspaceDetails(dispatch, SetSnackbar);
        fetchOnboardingModules({ onboardingInfo, setOnboardingInfo, SetSnackbar });
    } else {
        history.push(`/inactive/?token=${Cookies.get('AuthToken')}`);
    }
};

const checkOnboardingCompletion = ({ onboardingInfo, setOnboardingInfo, SetSnackbar, history }) => {
    if (!onboardingInfo) {
        getOnboardingModules()
            .then((res) => {
                setOnboardingInfo(res);
                const selectedModules = !!find(res, (item) => item.completed);
                if (!selectedModules) {
                    history.push('/onboarding/');
                }
            })
            .catch((error) =>
                showSnackBarMessage(SetSnackbar, 'error', error.message || 'Error fetching Onboarding Modules')
            );
    }
};

const Index = ({ location, history }) => {
    const { loading, setLoading, setColorCode, setIsPulseEnabled, onboardingInfo, setOnboardingInfo } =
        useCommonContext();
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem('user') || null);
    const data = queryString.parse(location.search);
    const isFullscreen = useSelector((state) => state.LayoutSettings.isFullscreen, shallowEqual);
    useEffect(() => {
        setLoading(true);
        if (data.success) {
            directLogin({
                data,
                history,
                dispatch,
                setLoading,
                setColorCode,
                setIsPulseEnabled,
                SetSnackbar,
                onboardingInfo,
                setOnboardingInfo,
            });
        } else if (userData) {
            let savedTokenDecode;
            try {
                savedTokenDecode = jwt_decode(Cookies.get('AuthToken'));
            } catch (error) {
                userLogout();
                return null;
            }
            if (!savedTokenDecode) {
                userLogout();
                return null;
            }
            checkOnboardingCompletion({ onboardingInfo, setOnboardingInfo, SetSnackbar, history });
            CheckAdmin(setLoading, history, setColorCode, setIsPulseEnabled, dispatch);
            GetSubscriptionDetails(dispatch, SetSnackbar);
            getWorkspaceDetails(dispatch, SetSnackbar);
            updateUserProfile(dispatch, { adminId: savedTokenDecode['admin-id'] });
            updateWorkspaceDetails(dispatch, {
                team: savedTokenDecode['team-id'],
                platform: savedTokenDecode.platform,
            });

            if (userData?.onboarding) {
                let setterUserData = {
                    team: userData?.team,
                    adminId: userData?.adminId,
                    platform: userData?.platform,
                };
                setter('user', setterUserData);
            }
            fetchOnboardingModules({ onboardingInfo, setOnboardingInfo, SetSnackbar });
        } else {
            userLogout();
        }
    }, [0]);

    const [open, setOpen] = useState(false);

    return (
        <Router>
            <Helmet>
                <meta charSet='utf-8' />
                <title>EngageWith | Dashboard</title>
                <meta property='og:site_name' content='EngageWith' />
                <meta property='og:title' content='EngageWith | Dashboard' />
                <meta
                    property='og:description'
                    content='Manage Billing, Rewards & Recognition settings, admin controls & more. You can also view transactions, leaderboards, Pulse Surveys & download analytics reports.'
                />
                <meta property='og:url' content='https://engagewith.springworks.in/' />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image:secure_url'
                    content='https://assets-springworks.s3.amazonaws.com/EngageWith.jpg'
                />
                <meta
                    property='og:image:secure_url'
                    content='https://assets-springworks.s3.amazonaws.com/EngageWith.jpg'
                />
                <meta property='og:image:width' content='1280' />
                <meta property='og:image:height' content='640' />

                <meta property='twitter:title' content='Employee Pulse Survey inside Slack & MS Teams | EngageWith' />
                <meta
                    property='twitter:description'
                    content=':Manage Billing, Rewards & Recognition settings, admin controls & more. You can also view transactions, leaderboards, Pulse Surveys & download analytics reports.'
                />
                <meta property='twitter:card' content='website' />
                <meta property='twitter:card' content='summary_large_image' />
                <meta property='twitter:image' content='https://assets-springworks.s3.amazonaws.com/EngageWith.jpg' />
                <meta property='twitter:site' content='@springroleinc' />
                <link rel='canonical' href='https://engagewith.springworks.in/' />
            </Helmet>
            <CustomLoader />
            {!loading && !isFullscreen && (
                <div className='seDashboardWrapper'>
                    <div style={open ? { display: '' } : {}} className='seDashboardAside'>
                        <SideNav open={open} setOpen={setOpen} />
                    </div>
                    <div style={open ? { display: 'none' } : { display: '' }} className='seDashboardSection'>
                        {(data.success || userData) && (
                            <>
                                <Header history={history} />
                                <DashboardRoutes history={history} />
                            </>
                        )}
                    </div>
                </div>
            )}
            {isFullscreen && <DashboardRoutes history={history} />}
        </Router>
    );
};

Index.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

export default Index;
