import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useInfiniteQuery, useMutation } from 'react-query';
import Modal from '@material-ui/core/Modal';
import './style.scss';
import { LABELS, DEPARTMENT, COUNTRY } from './constant.js';
import CloseIcon from '@material-ui/icons/Close';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import { updateDepartment, addDepartment, addOrUpdateCountry, getUsers } from 'Services/apiFunctions';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { useToaster } from 'Context/SnackbarContext';
import { debounce } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_PAGINATED_DEPARTMENT, GET_PAGINATED_COUNTRY, GET_USERS_DATA } from 'Services/apiKeys';
import MemberCard from 'components/ReusableComponents/MemberDetailsCard';
import EWButton from 'components/ReusableComponents/EWButton';
import { getCountries } from 'utils/countryUtils';

const Index = ({ setOrgModal, editData, targetCountry, countries = [], organisationType, setCurrentPage }) => {
    const {
        title,
        editTitle,
        nameFieldLabel,
        nameFieldPlaceholder,
        userFieldLabel,
        userFieldPlaceholder,
        nameFieldDropdownSearchText,
        userFieldDropdownSearchText,
        selectedFieldLabel,
        submitBtn,
        inputField,
    } = LABELS[organisationType.toUpperCase()];

    const allCountries = getCountries();

    const { SetSnackbar } = useToaster();

    // Modal States
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [departmentName, setDepartmentName] = useState(editData?.name || '');
    const [countrySelection, setCountrySelection] = useState(targetCountry);
    const [search, setSearch] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [deSelectedMembers, setDeSelectedMembers] = useState([]);
    const [selectedMembersDetails, setSelectedMembersDetails] = useState([]);

    //API Calls
    const queryClient = useQueryClient();

    const onSuccess = (_data) => {
        setOrgModal(false);
        setCurrentPage(1);
        queryClient.invalidateQueries(
            organisationType === DEPARTMENT ? GET_PAGINATED_DEPARTMENT : GET_PAGINATED_COUNTRY
        );
    };

    const onError = (error) => {
        setOrgModal(false);
        showSnackBarMessage(SetSnackbar, 'error', error?.message || 'Some error occurred');
    };

    const { mutate: editDepartment, isLoading: isEditing } = useMutation(updateDepartment, { onSuccess, onError });

    const { mutate: addNewDepartment, isLoading: isAdding } = useMutation(addDepartment, { onSuccess, onError });

    const { mutate: updateCountry, isLoading: updatingCountry } = useMutation(addOrUpdateCountry, {
        onSuccess,
        onError,
    });

    const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [GET_USERS_DATA, { search, isActive: true }],
        getUsers,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            retry: 0,
        }
    );

    const getCountryFlag = (member) =>
        organisationType === COUNTRY &&
        allCountries?.find((country) => country?.countryCode === member?.userCountry)?.countryFlag;

    // Handle Modal Actions
    const saveCheck = {
        DEPARTMENT: selectedMembers?.length < 1 || departmentName?.length < 1,
        COUNTRY: selectedMembers?.length < 1 || !countrySelection,
    };

    const handleDebounceSearch = debounce((value) => {
        setSearch(value);
    }, 300);
    const handleChange = (type, value) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'close':
                setOrgModal(false);
                break;
            case 'searchUser':
                handleDebounceSearch(value);
                break;
            case 'selectUser':
                if (selectedMembers?.includes(value)) {
                    setSelectedMembers(selectedMembers.filter((item) => item !== value));
                    setDeSelectedMembers([...deSelectedMembers, value]);
                } else {
                    setDeSelectedMembers(deSelectedMembers.filter((item) => item !== value));
                    setSelectedMembers([...selectedMembers, value]);
                }
                break;
            case 'countrySelection':
                setCountrySelection(value);
                break;
            case 'departmentName':
                setDepartmentName(value);
                break;
            case 'submit': {
                let id;
                if (organisationType === COUNTRY) {
                    id = editData ? editData?.countryCode : countrySelection?.countryCode;
                } else {
                    id = editData?.id;
                }
                const apiData = {
                    ...((editData || organisationType === COUNTRY) && { id }),
                    ...(organisationType === DEPARTMENT && { name: departmentName }),
                    users: selectedMembers,
                };
                if (organisationType === DEPARTMENT) {
                    editData ? editDepartment(apiData) : addNewDepartment(apiData);
                } else {
                    updateCountry(apiData);
                }
                break;
            }
        }
    };

    useEffect(() => {
        if (data) {
            const members = data?.pages?.map((page) => page.data.users).flat(); // Collect all members
            const tempSelected = [...members, ...selectedMembersDetails]; //PreSelected + All Members
            const tempSelectedId = tempSelected.map((member) => member._id);
            const membersDetailsList = tempSelected.filter(
                (member, index) => !tempSelectedId.includes(member?._id, index + 1)
            ); //Filtered Duplicate
            const filterMembersDetails = membersDetailsList.filter((member) => selectedMembers.includes(member?._id)); //Filtered Locally Selected
            setSelectedMembersDetails(filterMembersDetails);
        }
    }, [selectedMembers]);

    useEffect(() => {
        if (editData) {
            const selectedUsers = editData?.users?.map((user) => {
                user.status = true;
                return user._id;
            });
            setSelectedMembers(selectedUsers);
            setSelectedMembersDetails([...editData.users] || []);
        }
    }, []);

    return (
        <Modal open>
            <div className='department-modal'>
                <div className='modal-head'>
                    <p className='title'>{editData ? editTitle : title}</p>
                    <CloseIcon className='close-icon' onClick={() => handleChange('close')} />
                </div>

                <div className='modal-body'>
                    {nameFieldLabel && (
                        <div className='input-field'>
                            <p className='label'>{nameFieldLabel}</p>
                            {/* Department Name Input */}
                            {inputField && (
                                <input
                                    type='text'
                                    value={departmentName}
                                    placeholder={nameFieldPlaceholder}
                                    onChange={(e) => handleChange('departmentName', e.target.value)}
                                />
                            )}
                            {/* Country Selection Dropdown */}
                            {!inputField && (
                                <div className='user-dropdown'>
                                    <CustomFilterDropdown
                                        filterOptions={countries}
                                        selectedName={countrySelection?.label || nameFieldPlaceholder}
                                        optionsSelected
                                        handleSelection={(index) => handleChange('countrySelection', countries[index])}
                                        dropDownID='dropdownSelection'
                                        disabled={countries?.length < 1 || editData}
                                        search
                                        singleSelect
                                        dropdownStyleClass='width-100'
                                        buttonStyleClass='mr-0'
                                        customSearchText={userFieldDropdownSearchText}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {/* Users Selection Dropdown */}
                    <div className='user-dropdown'>
                        <CustomDropdown
                            title={userFieldLabel}
                            selectedName={
                                selectedMembers?.length > 0
                                    ? `Selected(${selectedMembers?.length})`
                                    : userFieldPlaceholder
                            }
                            optionsSelected
                            dropDownID='userSelection'
                            dropdownStyleClass='width-100'
                            buttonWidth={480}
                            dropdownWidth={480}
                            dropdownHeight={232}
                            open={dropdownOpen}
                            setOpen={setDropdownOpen}
                        >
                            <div className='infinite-scroll-container'>
                                <CustomSearchBox
                                    width='402px'
                                    handleSearch={(e) => handleChange('searchUser', e.target.value)}
                                    placeholder={nameFieldDropdownSearchText}
                                />

                                {data?.pages[0]?.data?.users?.length > 0 && (
                                    <InfiniteScroll
                                        dataLength={data?.pages?.length * 18}
                                        next={fetchNextPage}
                                        hasMore={hasNextPage}
                                        height={192}
                                        loader={<CustomLoader size={10} />}
                                    >
                                        <div>
                                            {data?.pages?.map((pageData) =>
                                                pageData?.data?.users?.map((member) => (
                                                    <MemberCard
                                                        key={member._id}
                                                        member={member}
                                                        selected={selectedMembers?.includes(member?._id)}
                                                        countryFlag={
                                                            organisationType === COUNTRY && getCountryFlag(member)
                                                        }
                                                        handleChange={handleChange}
                                                    />
                                                ))
                                            )}
                                        </div>
                                    </InfiniteScroll>
                                )}
                            </div>
                        </CustomDropdown>
                    </div>

                    {selectedMembersDetails.length > 0 && <p className='selected-field-label'>{selectedFieldLabel}</p>}

                    {/* Selected Users List */}
                    <div className='selection-list'>
                        {selectedMembersDetails.map((member) => (
                            <MemberCard
                                key={member._id}
                                member={member}
                                {...(organisationType === DEPARTMENT && {
                                    departmentName: editData?.name,
                                })}
                                selected={selectedMembers?.includes(member?._id)}
                                countryFlag={getCountryFlag(member)}
                                handleChange={handleChange}
                            />
                        ))}
                    </div>
                </div>

                <div className='modal-footer'>
                    <EWButton
                        buttonText={submitBtn}
                        disabled={
                            saveCheck[organisationType?.toUpperCase()] || isAdding || isEditing || updatingCountry
                        }
                        loading={isAdding || isEditing || updatingCountry}
                        onClick={() => handleChange('submit')}
                    />
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    setOrgModal: PropTypes.func,
    editData: PropTypes.object,
    targetCountry: PropTypes.string,
    countries: PropTypes.array,
    organisationType: PropTypes.string,
    setCurrentPage: PropTypes.func,
};

export default Index;
