import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomSwitch } from 'components';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import './style.scss';
import EllipsisText from 'components/ReusableComponents/EllipsisText';

const Index = ({ item, handleToggleStatus, handleEdit }) => {
    const [showHoverState, setShowHoverState] = useState(false);

    return (
        <div
            className={clsx({
                'container-catalog-item': true,
                'container-catalog-item-center': false,
            })}
            onMouseEnter={() => setShowHoverState(true)}
            onMouseLeave={() => setShowHoverState(false)}
        >
            <div className='status-container'>
                <div className={item?.isEnabled ? 'enabled-circle' : 'disabled-circle'} />
                {item?.isEnabled ? 'Enabled' : 'Disabled'}
            </div>

            <div
                className={clsx({
                    'card-details-container': !showHoverState,
                    'card-details-container-hover': showHoverState,
                })}
            >
                {item?.imageUrl?.includes('http') ? (
                    <img src={item.imageUrl} alt='' />
                ) : (
                    <div className='emoji-image'>{item?.imageUrl}</div>
                )}
                <div className='card-details'>
                    <EllipsisText white customClassName='card-name' text={item?.name} />
                    <p>{item?.categories || 'Others'}</p>
                </div>
            </div>

            <div
                className={clsx({
                    'reward-card-bottom': !showHoverState,
                    'reward-card-bottom-hover': showHoverState,
                })}
            >
                {item?.isEnabled ? 'Disable' : 'Enable'} Gift card
                <CustomSwitch
                    className={item?.isCustom ? '' : 'margin-right-25'}
                    checked={item?.isEnabled}
                    onClick={() => handleToggleStatus(item)}
                />
                {item?.isCustom && (
                    <div className='edit-icon' onClick={() => handleEdit(item)}>
                        <EditIcon />
                    </div>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    item: PropTypes.object,
    handleToggleStatus: PropTypes.func,
    handleEdit: PropTypes.func,
};

export default Index;
