import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import clsx from 'clsx';
import { ReactComponent as CancelIcon } from 'Assets/images/closeBlue.svg';
import { CustomTag } from 'utils/Stylesheet/style';

const Index = ({
    member,
    selected,
    handleChange,
    countryFlag,
    departmentName,
    showManagerInfo = false,
    maxWidth = '91px',
}) => {
    const INACTIVE_TAG = {
        color: '#FFBA07',
        background: '#FFF5DA',
        fontWeight: 500,
    };

    const getUserText = () => {
        if (showManagerInfo && member?.manager) {
            return `${member?.userName} (${member?.manager?.userName}'s team)`;
        }
        return member?.userName;
    };

    return (
        <div
            className={clsx('member-card-container', { selected: selected })}
            onClick={() => handleChange('selectUser', member?._id)}
        >
            <div className='d-flex'>
                <img
                    className='member-image'
                    src={member?.pictureURL || require('Assets/images/defaultUser.png')}
                    alt='member'
                />
                <div className='d-flex flex-column'>
                    <div className='member-details'>
                        <p className='member-name'>
                            <EllipsisText text={getUserText()} maxWidth={maxWidth} />
                        </p>
                        {countryFlag && (
                            <span className='member-country'>
                                <img className='country-image' src={countryFlag} alt='' />
                            </span>
                        )}
                        {/* if status field is not present then dont show the user as inactive */}
                        {member?.status === false && (
                            <CustomTag
                                color={INACTIVE_TAG?.color}
                                background={INACTIVE_TAG?.background}
                                fontWeight={INACTIVE_TAG?.fontWeight}
                            >
                                Inactive
                            </CustomTag>
                        )}
                    </div>
                    <p className='member-department'>{member?.department || departmentName}</p>
                </div>
            </div>
            <CancelIcon />
        </div>
    );
};

Index.propTypes = {
    member: PropTypes.object,
    selected: PropTypes.bool,
    handleChange: PropTypes.func,
    countryFlag: PropTypes.string,
    departmentName: PropTypes.string,
    showManagerInfo: PropTypes.bool,
    maxWidth: PropTypes.string,
};

export default Index;
