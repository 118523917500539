import React, { useState } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Lander, Onboarding, Dashboard, UnauthorizedScreen, Inactive } from './components';
import Success from './components/Success/Success';
import OnboardingActive from 'components/Onboarding/OnboardingActive';
import { useCommonContext } from './Context/CommonContext';
import { SnackbarProvider, Toastr } from './Context/SnackbarContext';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import 'font-awesome/css/font-awesome.min.css';
import LanderRedirect from './components/LanderRedirect';
import LoginScreen from './components/LoginScreen';
import history from './history';
import { GlobalStyles } from 'utils/Stylesheet/global';
import { ThemeProvider } from 'styled-components';
import makeServer from 'mock';

if (process.env.REACT_APP_USE_MOCK === 'true') {
    makeServer({ environment: 'localhost' });
}

const App = () => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        retry: 2,
                        refetchOnWindowFocus: false,
                    },
                },
            })
    );
    const { colorCode } = useCommonContext();

    const defaultTheme = {
        colourCode: '#5d5cf5',
        buttonHover: { backgroundColor: '#4749be' },
    };

    return (
        <Router history={history}>
            <div className='App'>
                <ThemeProvider theme={colorCode ? colorCode : defaultTheme}>
                    <GlobalStyles />
                    <Switch>
                        <Route path='/unauthorized' component={UnauthorizedScreen} />
                        <Route path='/login' component={LoginScreen} />
                        <Route path='/inactive' component={Inactive} />
                        <Route path='/inprogress' component={OnboardingActive} />
                        <Route path='/cookie-policy' component={LanderRedirect} />
                        <Route path='/privacy-policy' component={LanderRedirect} />
                        <Route path='/terms-and-conditions' component={LanderRedirect} />
                        <QueryClientProvider client={queryClient}>
                            <Provider store={store}>
                                <SnackbarProvider>
                                    <Toastr />
                                    <Route path='/' exact component={Lander} />
                                    <AuthenticatedRoute path='/onboarding' component={Onboarding} />
                                    <AuthenticatedRoute path='/dashboard' component={Dashboard} />
                                    <AuthenticatedRoute path='/success' component={Success} />
                                </SnackbarProvider>
                            </Provider>
                            <ReactQueryDevtools />
                        </QueryClientProvider>
                        <Redirect to='/' />
                    </Switch>
                </ThemeProvider>
            </div>
        </Router>
    );
};

export default App;
