import React from 'react';
import PropTypes from 'prop-types';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { GreenTag, LightOrangeTag, LightPurpleTag } from 'utils/Stylesheet/style';
import { ReactComponent as CalendarIcon } from 'Assets/images/CalenderIcon.svg';
import { eNPS_STATUS, eNPS_ROUTE } from '../../constant';
import './style.scss';

const Index = ({ dateFilter, score, handleChange, loading, history }) => {
    return (
        <div className='enpshead'>
            <div className='head'>
                <div className='head-left d-inline-block'>
                    <h3 className='d-inline'>eNPS</h3>
                    {score?.status === eNPS_STATUS.ACTIVE && <GreenTag>Active</GreenTag>}
                    {score?.status === eNPS_STATUS.SCHEDULED && <LightOrangeTag>Scheduled</LightOrangeTag>}
                    {score?.status === eNPS_STATUS.COMPLETED && <LightPurpleTag> Completed </LightPurpleTag>}

                    <div className='d-inline-block '>
                        {score?.date && (
                            <CustomFilterDropdown
                                filterOptions={dateFilter}
                                selectedName={
                                    <span>
                                        <CalendarIcon /> {score?.date}
                                    </span>
                                }
                                optionsSelected
                                handleSelection={(index) => handleChange('dateFilter', index)}
                                dropDownID='eNPS-Date'
                                singleSelect
                                buttonStyleClass='width-240 ml-3'
                            />
                        )}
                    </div>
                </div>

                {!loading && (
                    <div className='head-right d-inline-block'>
                        {score?.status !== eNPS_STATUS.COMPLETED && (
                            <button className='end-btn ew-btn ob-btn' onClick={() => handleChange('edit')}>
                                Edit
                            </button>
                        )}
                        {score?.totalRespondents > 0 && (
                            <button className='ew-btn ob-btn' onClick={() => handleChange('viewInsights')}>
                                View Results
                            </button>
                        )}
                        {score?.status !== eNPS_STATUS.COMPLETED && (
                            <button className='end-btn ew-btn ob-btn' onClick={() => handleChange('endPulse')}>
                                Stop eNPS
                            </button>
                        )}
                        {score?.launch && (
                            <button
                                className='ew-btn pb-btn'
                                onClick={() =>
                                    history.push(
                                        score?.draftId
                                            ? {
                                                  pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                                                  state: { draftId: score?.draftId, draftOrScheduled: true },
                                              }
                                            : eNPS_ROUTE.eNPS_CREATE
                                    )
                                }
                            >
                                Setup eNPS
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    dateFilter: PropTypes.array.isRequired,
    score: PropTypes.shape({
        status: PropTypes.string,
        date: PropTypes.string,
        totalRespondents: PropTypes.number,
        launch: PropTypes.bool,
        draftId: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};

export default Index;
