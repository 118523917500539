import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getData } from 'Services/apiCall';
import { debounce, filter, find } from 'lodash';
import CustomAutoComplete from 'components/ReusableComponents/CustomAutoComplete';

const getUsers = debounce((setLoading, setOptions, searchValue, filterChannels, selectedData) => {
    if (!searchValue || searchValue.trim().length <= 0) {
        return;
    }

    setLoading(true);
    let url = `users/search-user-channel-group?name=${searchValue.trim()}`;
    if (filterChannels) {
        const selectedChannels = filter(selectedData, (item) => item.channel).map((item) => item.channelID);
        const selectedGroups = filter(selectedData, (item) => item.groupID).map((item) => item.groupID);
        if (selectedChannels.length > 0) {
            url = `${url}&channels=${selectedChannels.join()}`;
        }
        if (selectedGroups.length > 0) {
            url = `${url}&userGroups=${selectedGroups.join()}`;
        }
    }
    getData(url)
        .then((res) => {
            const response = res.data;
            let combinedOptions = response.users.map((user) => ({
                id: user._id,
                memberID: user.memberID,
                name: user.userName,
                picUrl: user.pictureURL,
                channel: false,
                isEditingOnetime: false,
            }));
            if (filterChannels) {
                // filter already selected users from excluded options
                combinedOptions = combinedOptions.filter((value) => !find(selectedData, value));
            } else {
                const channels = response.channels.map((user) => ({
                    id: user._id,
                    channelID: user.channelID,
                    channelName: user.channelName,
                    name: user.channelName,
                    picUrl: '',
                    channel: true,
                    memberCount: 0,
                    isEditingOnetime: false,
                }));

                const userGroups = response.userGroups.map((userGroup) => ({
                    id: userGroup._id,
                    groupID: userGroup.groupID,
                    name: userGroup.groupName,
                    picUrl: '',
                    memberCount: 0,
                    isEditingOnetime: false,
                }));
                combinedOptions = [...channels, ...combinedOptions, ...userGroups];
            }
            setOptions(combinedOptions.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)));
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
}, 500);

const SearchUser = ({
    autoCompleteId,
    width,
    onChange,
    filterChannels,
    selectedData,
    placeholder,
    listBoxProps,
    selectedOptions,
    maxSelections,
    multiple,
}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const onChangeValue = (value) => {
        onChange(value);
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            getUsers(setLoading, setOptions, '^.*$', filterChannels, selectedData);
        }
    }, [open]);

    return (
        <div>
            <CustomAutoComplete
                autoCompleteId={autoCompleteId}
                open={open}
                setOpen={setOpen}
                loading={loading}
                width={width}
                options={options}
                onChange={onChangeValue}
                placeholder={placeholder}
                listBoxProps={listBoxProps}
                selectedOptions={selectedOptions}
                maxSelections={maxSelections}
                multiple={multiple}
            />
        </div>
    );
};

SearchUser.propTypes = {
    autoCompleteId: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    filterChannels: PropTypes.bool,
    selectedData: PropTypes.array,
    placeholder: PropTypes.string,
    listBoxProps: PropTypes.object,
    selectedOptions: PropTypes.array,
    maxSelections: PropTypes.number,
    multiple: PropTypes.bool,
};

export default SearchUser;
