import React, { useState, useRef } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './style.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const Index = ({
    children,
    placement,
    optionMenu,
    getSelected,
    disabled,
    showNotificationBadge = false,
    menuWidth = 'fit-content',
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const containerRef = useRef();
    const handleClick = (event) => {
        event.stopPropagation();
        if (open) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelection = (index) => {
        getSelected(index);
        setAnchorEl(null);
    };

    return (
        <div className='vert-menu d-flex' ref={containerRef}>
            <ClickAwayListener onClickAway={handleClose}>
                <button className={`more-btn ${anchorEl && 'more-active'}`} disabled={disabled} onClick={handleClick}>
                    {children}
                    {!children && <MoreVertIcon />}
                </button>
            </ClickAwayListener>

            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                container={containerRef.current}
                style={{ zIndex: '3' }}
            >
                <Paper className='menu' style={{ width: menuWidth }}>
                    {optionMenu?.map((option, i) => (
                        <div
                            className='menu-item'
                            key={option.value}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleSelection(i);
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </Paper>
            </Popper>
            {showNotificationBadge && <div className='notification-badge'></div>}
        </div>
    );
};

Index.propTypes = {
    children: PropTypes.any,
    optionMenu: PropTypes.array,
    getSelected: PropTypes.func,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
    showNotificationBadge: PropTypes.bool,
    menuWidth: PropTypes.string,
};

export default Index;
