import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import TitleBar from 'components/ReusableComponents/TitleBar';
import AllActivities from './AllActivities';
import MyActivities from './MyActivities';
import { PAGESTATE, TITLE } from './constants';

const Index = ({ history }) => {
    const [active, setActive] = useState(PAGESTATE.ALLACTIVITIES);
    const { isAdmin, platform } = useSelector(mapStateToProps, shallowEqual);
    return (
        <div>
            <TitleBar title={isAdmin ? TITLE.ACTIVITIES : TITLE.MYACTIVITIES} />
            {active === PAGESTATE.ALLACTIVITIES && isAdmin ? (
                <AllActivities active={active} setActive={setActive} history={history} platform={platform} />
            ) : (
                <MyActivities
                    active={active}
                    setActive={setActive}
                    history={history}
                    isAdmin={isAdmin}
                    platform={platform}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    todo: state.Workspace.todo,
    isAdmin: state.User.isAdmin,
    platform: state.Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
