import React from 'react';
import { ReactComponent as EWLogo } from 'Assets/images/ew-logo-transparent.svg';
import { demoCallURL } from 'constants.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';
import EWButton from 'components/ReusableComponents/EWButton';

const Index = ({
    heading,
    subHeading,
    primaryButtonDetails,
    secondaryButtonDetails,
    bannerImage,
    isSetupComplete,
    showGetHelp = true,
}) => {
    return (
        <div
            className={clsx({
                'info-screen bg-white height-100-vh': true,
                'setup-complete-background': isSetupComplete,
            })}
        >
            <div className='header'>
                <EWLogo />
                {showGetHelp && (
                    <div className='header-details'>
                        <p>Having trouble?&nbsp;</p>
                        <EWButton
                            plainTextButton={true}
                            primary={false}
                            buttonStyleClass='color-blue-1 pointer-cursor'
                            onClick={() => window.open(demoCallURL, '_blank')}
                        >
                            Get help
                        </EWButton>
                    </div>
                )}
            </div>
            <div className='content d-flex flex-column align-items-center justify-content-center'>
                {heading && <div className='content-heading'>{heading}</div>}
                {subHeading && <div className='content-sub-heading'>{subHeading}</div>}
                <button className='ew-btn pb-btn width-350 margin-bottom-20' onClick={primaryButtonDetails.onClick}>
                    {primaryButtonDetails.text}
                </button>
                {secondaryButtonDetails && (
                    <EWButton
                        plainTextButton={true}
                        primary={false}
                        buttonStyleClass='color-blue-1 pointer-cursor margin-bottom-10'
                        onClick={secondaryButtonDetails.onClick}
                    >
                        {secondaryButtonDetails.text}
                    </EWButton>
                )}
                {bannerImage}
            </div>
        </div>
    );
};

Index.propTypes = {
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
    primaryButtonDetails: PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
    }).isRequired,
    secondaryButtonDetails: PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
    }),
    bannerImage: PropTypes.element.isRequired,
    isSetupComplete: PropTypes.bool,
    showGetHelp: PropTypes.bool,
};

export default Index;
