import React from 'react';
import Dropzone from 'react-dropzone';
import './style.scss';
import { BarLoader } from 'react-spinners';
import { ReactComponent as PlusIcon } from 'Assets/images/plus.svg';
import { ReactComponent as FolderIcon } from 'Assets/images/folderIcon.svg';
import { ReactComponent as FileIcon } from 'Assets/images/file.svg';
import { ReactComponent as RetryIcon } from 'Assets/images/retry.svg';
import PropTypes from 'prop-types';

export const StyledDropzone = ({
    onDrop,
    multiple = false,
    accept = '.csv',
    content = 'Drop your file here (PDF/DOC/DOCX)',
    error = false,
    height = 207,
    width = 528,
    acceptedFile,
    setState,
    loading,
}) => {
    return (
        <>
            <Dropzone onDrop={onDrop} multiple={multiple} accept={accept}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <div
                        {...getRootProps()}
                        className={`drop-zone ${error && 'error'} ${isDragActive && 'active'}`}
                        style={{ height, width }}
                    >
                        <input {...getInputProps()} />
                        {isDragActive && <PlusIcon />}
                        <div hidden={isDragReject || acceptedFile || error}>
                            <FolderIcon />
                            <div className='content'>
                                <div>{content}</div>
                                <div>OR</div>
                                <span>Click here to browse</span>
                            </div>
                        </div>
                        <div hidden={!acceptedFile || isDragActive}>
                            {loading && <BarLoader height={4} width={100} color={'#0029FF'} loading={loading} />}
                            {!loading && acceptedFile && (
                                <>
                                    <FileIcon
                                        data-testid='cancel-icon'
                                        className='img-cancel'
                                        onClick={() => setState({ excelFileError: null })}
                                    />
                                    <div className='file-name'>{acceptedFile[0].path}</div>
                                </>
                            )}
                        </div>
                        {error && (
                            <div className='error-div'>
                                <FolderIcon />
                                <span>
                                    <RetryIcon
                                        data-testid='cancel-icon'
                                        className='img-cancel'
                                        onClick={() => setState({ excelFileError: null })}
                                    />
                                    &nbsp;Retry Upload
                                </span>
                                {error}
                            </div>
                        )}
                    </div>
                )}
            </Dropzone>
        </>
    );
};

StyledDropzone.propTypes = {
    onDrop: PropTypes.func,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    content: PropTypes.string,
    error: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    acceptedFile: PropTypes.array,
    setState: PropTypes.func,
    loading: PropTypes.bool,
};
