import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SettingsBox } from 'utils/Stylesheet/style';
import Details from '../Details';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import ErrorField from 'components/ReusableComponents/ErrorField';
import { getCountries } from 'utils/countryUtils';
import { getTimeZoneList } from 'utils/timezoneUtil';
import { isEmptyObject, showSnackBarMessage } from 'utils/HelperFunctions';
import { find } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { GET_GLOBAL_SETTINGS } from 'Services/apiKeys';
import { updateGlobalSettings } from 'Services/apiFunctions';

const countries = getCountries(false);

const Index = ({ data }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [edit, setEdit] = useState(false);
    const [country, setCountry] = useState();
    const [timezoneList, setTimezoneList] = useState([]);
    const [timezone, setTimezone] = useState();
    const [errorFields, setErrorFields] = useState({});

    const { mutate: configureSettings, isLoading: isUpdating } = useMutation(updateGlobalSettings, {
        onSuccess: () => {
            queryClient.invalidateQueries(GET_GLOBAL_SETTINGS);
            setEdit(false);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const handleCountry = (value) => {
        setCountry(value);
        setTimezone();
        setErrorFields({ ...errorFields, country: '' });
    };

    const handleTimezone = (index) => {
        if (country?.value) {
            setTimezone(getTimeZoneList(country?.value)[index]);
            setErrorFields({ ...errorFields, timezone: '' });
        }
    };

    const handleSave = () => {
        if (isUpdating) {
            return;
        }
        const errorObj = {};
        const bodyData = {
            country: country?.country,
            timezone: timezone?.label,
        };
        if (!bodyData.country) {
            errorObj.country = 'Please select country';
        }
        if (!bodyData.timezone) {
            errorObj.timezone = 'Please select timezone';
        }
        if (!isEmptyObject(errorObj)) {
            setErrorFields(errorObj);
            return false;
        }
        configureSettings(bodyData);
    };

    const setCountryDetails = () => {
        if (data?.country) {
            setCountry(find(countries, (country) => country.country === data.country));
        }
        if (data?.timezone) {
            setTimezone({ label: data?.timezone, value: data?.timezone });
        }
        setEdit(!data?.country || !data?.timezone);
    };

    const handleEdit = () => {
        edit && setCountryDetails();
        setEdit(!edit);
    };

    useEffect(() => {
        if (country?.value) {
            setTimezoneList(getTimeZoneList(country?.value));
        }
    }, [country]);

    useEffect(() => {
        setCountryDetails();
    }, [data]);

    return (
        <SettingsBox className='sc-default-country'>
            <div className='sc-heading-container'>
                <h4 className='sc-heading'>Default country and time zone</h4>
                {!isUpdating && (
                    <p className='sc-button' onClick={handleEdit}>
                        {edit ? 'Cancel' : 'Edit'}
                    </p>
                )}
            </div>
            <p className='sc-sub-heading'>
                Manage default settings for onboarding new employees and scheduling communication at the preferred time.
            </p>
            {!edit && (
                <div className='sc-details-container'>
                    <Details
                        label='Country:'
                        imageUrl={''}
                        labelData={country?.label}
                        tooltipInfo='Employees added to EngageWith will be assigned this country by default.'
                    />
                    <div className='margin-top-14' />
                    <Details
                        label='Time zone:'
                        labelData={timezone?.label}
                        tooltipInfo='Communications from EngageWith will be scheduled based on this time zone'
                    />
                </div>
            )}
            {edit && (
                <div className='sc-details-container'>
                    <CustomFilterDropdown
                        search
                        title='Country'
                        filterOptions={countries}
                        selectedName={country?.label || 'Select Country'}
                        optionsSelected={!!country?.label}
                        handleSelection={(index) => handleCountry(countries[index])}
                        buttonStyleClass='width-300 margin-bottom-24'
                        dropDownID='Country'
                        info='Employees added to EngageWith will be assigned this country by default.'
                        singleSelect
                    />
                    {errorFields.country && <ErrorField errorText={errorFields.country} className='error-class' />}
                    <CustomFilterDropdown
                        title='TIME ZONE'
                        filterOptions={timezoneList}
                        selectedName={timezone?.label || 'Select Timezone'}
                        optionsSelected={!!timezone?.label}
                        handleSelection={(index) => handleTimezone(index)}
                        buttonStyleClass='width-300 margin-bottom-24'
                        dropDownID='Timezone'
                        singleSelect
                        info='Communications from EngageWith will be scheduled based on this time zone'
                        disabled={!country?.value}
                    />
                    {errorFields.timezone && <ErrorField errorText={errorFields.timezone} className='error-class' />}
                    <p className='sc-button' style={{ color: isUpdating ? '#82878d' : '#5d5cf5' }} onClick={handleSave}>
                        {`${isUpdating ? 'Saving' : 'Save'} changes`}
                    </p>
                </div>
            )}
        </SettingsBox>
    );
};

Index.propTypes = {
    data: PropTypes.object,
};

export default Index;
