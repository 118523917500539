import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const EmptyScreen = ({ image, headingText, subheadingText, description, action, btnName }) => {
    return (
        <div className='empty-container'>
            <div className='empty-content text-center'>
                <span className='d-block'>{image}</span>
                {headingText && <p className='heading '>{headingText}</p>}
                {subheadingText && <p className='subheading'>{subheadingText}</p>}
                {description && <p className='description'>{description}</p>}
                <button className='pb-btn ew-btn' onClick={action} hidden={!btnName}>
                    {btnName}
                </button>
            </div>
        </div>
    );
};

EmptyScreen.propTypes = {
    image: PropTypes.element.isRequired,
    headingText: PropTypes.string,
    subheadingText: PropTypes.string,
    btnName: PropTypes.string,
    action: PropTypes.func,
    description: PropTypes.string,
};

export default EmptyScreen;
