import { PaymentDarkGreenTag, PaymentRedTag, PaymentYellowTag } from 'utils/Stylesheet/style';
import React from 'react';
import PropTypes from 'prop-types';
import { PlanToolTip } from './Components/statics';
import { formatDistanceToNowStrict } from 'date-fns';
import { getCustomerBillingPortalLink } from 'Services/apiFunctions';
import Mastercard from 'Assets/images/masterCard-big.svg';
import { ReactComponent as WarningIcon } from 'Assets/images/warning.svg';
import EWButton from 'components/ReusableComponents/EWButton';

export const CONTACT_US = 'https://calendly.com/engagewith-demo/demo';

export const CARDVIEW_TYPES = {
    LEAN: 'LEAN',
    DETAILED: 'DETAILED',
};

/**
 * Modify the values here to change the fee for different currencies (modifying USD and OTHER will only change the disclaimer)
 * Modifying LINK will change the fee for the amount showed on dashboard right after adding funds
 * USD: The fee for normal card transaction in USD that we will charge is 4%
 * LINK: The fee for normal card transaction using Stripe Link that we will charge is 4%
 * OTHER: The fee for normal card transaction in other currencies that we will charge is 6%
 */
export const STRIPE_FEES = {
    USD: 4,
    LINK: 4,
    OTHER: 6,
};

export const PAYMENT_SECTIONS = {
    SUMMARY: 'SUMMARY',
    WALLET: 'WALLET',
};

export const CARD_LOGS = {
    visa: 'https://www.credit-card-logos.com/images/visa_credit-card-logos/visa_logo_2.gif',
    master: 'https:///www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_2.gif',
    mastercard: Mastercard,
    amex: 'https://www.aexp-static.com/cdaas/one/statics/axp-static-assets/1.8.0/package/dist/img/logos/dls-logo-bluebox-solid.svg',
    diners: 'https://www.dinersclub.com/assets/images/diners-club-international-logo-white.png',
};

export const DROPDOWN_TYPES = {
    AUTO_RELOAD: 'AUTO_RELOAD',
    MIN_BALANCE: 'MIN_BALANCE',
};

export const PLAN_TYPES = {
    BASIC: 'BASIC',
    STANDARD: 'STANDARD',
    TRIAL: 'TRIAL',
    EXPIRED: 'EXPIRED',
};

export const PLAN_STATUS = {
    ACTIVE: 'active',
    TRIAL: 'trialing',
    CANCELLED: 'canceled',
};

export const PRESELECTED_AMOUNTS_LISTS = (countryCode = 'IN') => {
    const loopFn = (each) => ({ value: each, label: each });
    if (countryCode === 'IN') {
        return {
            AUTO_RELOAD: [5000, 10000, 20000, 50000, 100000].map(loopFn),
            MIN_BALANCE: [1000, 3000, 5000, 10000, 25000].map(loopFn),
        };
    }
    return {
        AUTO_RELOAD: [100, 500, 1000, 5000].map(loopFn),
        MIN_BALANCE: [25, 100, 500, 1000].map(loopFn),
    };
};

const getdateDistance = (unixTime) =>
    formatDistanceToNowStrict(new Date((unixTime || 0) * 1000), {
        unit: 'day',
    });

const CurrentPlan = ({ product, trialDaysLeft, status }) => (
    <div>
        <span className='plan'> Current Plan:</span>
        <span className='plan-type'>
            <b>{product}</b>
        </span>
        {status === PLAN_STATUS.ACTIVE && <PaymentDarkGreenTag>ACTIVE</PaymentDarkGreenTag>}
        {status === PLAN_STATUS.TRIAL && (
            <PaymentYellowTag>
                {trialDaysLeft > 0 ? `${trialDaysLeft} DAYS OF TRIAL LEFT` : 'TRIAL ENDS TODAY'}
            </PaymentYellowTag>
        )}
        {status === PLAN_STATUS.CANCELLED && <PaymentRedTag>Cancelled</PaymentRedTag>}
    </div>
);

const upgradePlan = 'Upgrade Plan';

export const SUMMARY_PLAN_BOX_DATA = {
    [PLAN_TYPES.BASIC]: {
        line1: ({ product }) => <CurrentPlan product={product} />,
        line2: ({ activeUsers }) => (
            <p>
                Total active users : <b>{activeUsers} &nbsp;</b>
                {PlanToolTip}
            </p>
        ),
        button: upgradePlan,
    },
    [PLAN_TYPES.STANDARD]: {
        line1: ({ product, trialDaysLeft, status }) => (
            <CurrentPlan product={product} trialDaysLeft={trialDaysLeft} status={status} />
        ),
        line2: () => (
            <span>
                <WarningIcon />
                Please renew your subscription to continue using EngageWith
            </span>
        ),
        button: 'Manage Plan',
    },
    [PLAN_TYPES.TRIAL]: {
        line1: ({ periodEndUnix }) => (
            <span className='type-1 bold'>Trial period ends in {getdateDistance(periodEndUnix)}</span>
        ),
        line2: ({ activeUsers }) => (
            <p>
                Total active users : <b>{activeUsers}</b>
            </p>
        ),
        button: upgradePlan,
    },
    [PLAN_TYPES.EXPIRED]: {
        line1: () => <span className='type-1 error-text bold'>Your trial period has ended</span>,
        line2: () => <p>To continue using EngageWith, upgrade now</p>,
        button: upgradePlan,
    },
};

export const CONFIRM_MODALS_TYPES = {
    ADD_FUND: 'ADD_FUND',
    AUTO_RELOAD: 'AUTO_RELOAD',
};

export const CONFIRM_MODALS_DATA = {
    ADD_FUND: {
        header: 'Add Funds to Wallet',
        content: ({ card, amount, currencySymbol }) => (
            <>
                Your card ending in <b>{card}</b> will be charged{' '}
                <b>
                    <span dangerouslySetInnerHTML={{ __html: currencySymbol }} />
                    {amount}.
                </b>
            </>
        ),
        confirm: 'Add funds',
    },
    AUTO_RELOAD: {
        header: 'Disable auto-reload',
        content: () => (
            <>
                Are you sure you want to disable auto-reload? <br /> You can enable it later from Payment Settings page
            </>
        ),
        confirm: 'Confirm',
    },
};

export const CONFIRM_AUTO_RELOAD = {
    confirm: 'Confirm',
    heading: 'Are you sure you want to disable auto-reload?',
};

export const handlePlanAction = async (onError) => {
    try {
        const {
            data: { url },
        } = await getCustomerBillingPortalLink();
        window.open(url, '_self');
    } catch (err) {
        onError();
    }
};

export const PaymentMethodStripe = ({ isPaymentMethodAdded, onClick }) => (
    <div className='wallet-note'>
        {isPaymentMethodAdded && (
            <span>
                We will use the default payment method added and saved securely on&nbsp;
                <EWButton buttonStyleClass='wallet-stripe' onClick={onClick} primary={false} plainTextButton={true}>
                    Stripe
                </EWButton>
                &nbsp;for this recharge.
            </span>
        )}
        {!isPaymentMethodAdded && (
            <span>
                Payment method is not available for this transaction.&nbsp;
                <EWButton buttonStyleClass='wallet-stripe' onClick={onClick} primary={false} plainTextButton={true}>
                    Click here
                </EWButton>
                &nbsp;to add.
            </span>
        )}
    </div>
);

const AUTO_RELOAD_ENABLED = 'Auto-reload your redemption wallet whenever it goes below the configured threshold value';

const AUTO_RELOAD_DISABLED = 'Auto-reload settings have been disabled as your subscription is cancelled.';

export const AutoReloadNote = ({ subscriptionCancelled }) => (
    <div className='reload-note'>
        {subscriptionCancelled && <WarningIcon />}
        {subscriptionCancelled ? AUTO_RELOAD_DISABLED : AUTO_RELOAD_ENABLED}
    </div>
);

AutoReloadNote.propTypes = {
    subscriptionCancelled: PropTypes.bool,
};

PaymentMethodStripe.propTypes = {
    isPaymentMethodAdded: PropTypes.bool,
    onClick: PropTypes.func,
};

CurrentPlan.propTypes = {
    product: PropTypes.string,
    trialDaysLeft: PropTypes.number,
    status: PropTypes.string,
};
