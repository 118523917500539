import React, { useState } from 'react';
import { reportText, FEATURED_PULSE } from '../../constant';
import Scale from '../Scale';
import { promoteRecognition } from 'Services/apiFunctions';
import { shallowEqual, useSelector } from 'react-redux';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { RECOGNITION_CONFIG } from '../../../Recognitions/Configuration/constants';
import './style.scss';

const Index = ({ history, reportInfo, pulseID }) => {
    const { todo } = useSelector(mapStateToProps, shallowEqual);
    const { SetSnackbar } = useToaster();
    const { value } = reportInfo || '';
    const [modalOpen, setModalOpen] = useState(false);

    const sendMessage = async () => {
        try {
            const messageResponse = await promoteRecognition({ pulseIssueId: pulseID });
            showSnackBarMessage(SetSnackbar, 'success', messageResponse?.message || 'Message sent');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message || 'Some error occurred!');
        }
    };

    const getIndex = (score) => {
        if (score < 0) {
            return 0;
        } else if (score >= 0 && score < 30) {
            return todo?.isRewardFlowCompleted ? 1 : 2;
        } else if (score >= 30 && score < 70) {
            return 3;
        } else {
            return 4;
        }
    };

    const getColor = (score) => {
        if (score < 0) {
            return '#FF6760';
        }
        return score < 30 ? '#FAC032' : '#32DBA1';
    };

    const { title, description, buttonText, action } = reportText[getIndex(value)];

    const handleAction = (actionValue) => {
        // eslint-disable-next-line default-case
        switch (actionValue) {
            case 'contact':
                window.location = 'https://calendly.com/engagewith-demo/demo';
                break;
            case 'promoteRecognitions':
                sendMessage();
                break;
            case 'setUpRewards':
                history.push(RECOGNITION_CONFIG.CONFIGURATION);
                break;
            case 'orgPerception':
                history.push(
                    `/dashboard/pulse/create?template=${FEATURED_PULSE.ID_1}&name=Organizational%20Perception`
                );
                break;
        }
    };

    return (
        <div className='company-report'>
            {!isNaN(value) ? (
                <div className='text-box'>
                    <p className='big-text' style={{ color: getColor(value) }}>
                        {title}
                    </p>
                    <p className='description'>{description}</p>
                    {buttonText && (
                        <span
                            className='ew-btn tb-btn'
                            onClick={() =>
                                buttonText === reportText[1].buttonText ? setModalOpen(true) : handleAction(action)
                            }
                        >
                            {buttonText}
                        </span>
                    )}
                </div>
            ) : (
                <div className='my-5'>
                    <h4>Results will be displayed soon</h4>
                    <p className='text-box'>
                        Results will be displayed as soon as we start getting responses from employees
                    </p>
                </div>
            )}
            <Scale {...reportInfo} color={value < 0 ? '#FF6760' : '#32DBA1'} />
            {buttonText === reportText[1].buttonText && (
                <ConfirmModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onConfirm={() => {
                        handleAction(action);
                        setModalOpen(false);
                    }}
                    data={{
                        confirm: 'Send',
                        image: require('Assets/images/eNPSConfirm.svg'),
                        heading:
                            'The following message will be sent to all users from channels added in audience section of eNPS',
                        closeIconColor: '#A9ADB1',
                    }}
                    smallHeadingFont
                    height='423px'
                    width='575px'
                />
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    reportInfo: PropTypes.object,
    pulseID: PropTypes.string,
};

const mapStateToProps = (state) => ({ todo: state.Workspace.todo });

export default Index;
