import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import InfoScreen from 'components/ReusableComponents/InfoScreen';

const BANNER_IMG = {
    slack: 'https://assets-springengage.s3.amazonaws.com/production/icons/RnR-setup-complete-slack.svg',
    teams: 'https://assets-springengage.s3.amazonaws.com/production/icons/RnR-setup-complete-msteams.svg',
};

const Index = ({ history }) => {
    const { platform, team } = useSelector(mapStateToProps, shallowEqual);
    const redirectionURL = `slack://app?team=${team}&id=${process.env.REACT_APP_SLACK_APP_ID}&tab=home`;

    return (
        <InfoScreen
            heading='Yayy! Setup complete!'
            subHeading='You can start giving recognitions to your teammates'
            bannerImage={
                <img src={platform?.toLowerCase() === 'slack' ? BANNER_IMG.slack : BANNER_IMG.teams} alt='banner' />
            }
            primaryButtonDetails={{
                text: 'Send your first recognition',
                onClick: () => window.open(redirectionURL, '_blank'),
            }}
            secondaryButtonDetails={{
                text: 'Explore dashboard',
                onClick: () => history.push('/dashboard/users?onboardingRedirection=true'),
            }}
            isSetupComplete={true}
        />
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
    team: Workspace.team,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
