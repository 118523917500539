import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { CommonProvider } from './Context/CommonContext';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://3fc3e3b8a220464ab4c2046a456e6ce0@o271082.ingest.sentry.io/5718958',
        environment: process.env.NODE_ENV,
        denyUrls: [/engagewith-dev\.springworks\.in/i, /localhost:/i, /127\.0\.0\.1:/i],
    });
}

ReactDOM.render(
    <React.StrictMode>
        <CommonProvider>
            <App />
        </CommonProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
