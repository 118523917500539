import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';
import { filter, find, reduce } from 'lodash';
import { CustomCheckbox } from 'components';
import { useToaster } from 'Context/SnackbarContext';
import AnonymousSection from './AnonymousSection';
import SearchUser from '../../Components/SearchUser';
import PulseCreationInfo from '../../Components/PulseCreationInfo';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import { getAudienceCount } from 'Services/apiFunctions';
import { EDIT_TYPE } from '../../constants';
import { showSnackBarMessage, addOrRemoveElement } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({
    setQuesAdded,
    eNPS,
    pulseData,
    audience,
    setAudience,
    questions,
    setStep,
    getScheduleInfo,
    isEditingOnetime,
    isEditingRolling,
    editType,
    scheduling,
    pulseAudienceData,
    setPulseAudienceData,
}) => {
    // make this false once the list is updated.
    const [error, setError] = useState(false);
    //make exclude.channel as true when first channel is added to list and false when all channels are removed from list
    const [exclude, setExclude] = useState({ channel: false, checked: false });
    const { SetSnackbar } = useToaster();

    const getRequestedListData = (users) =>
        reduce(
            users,
            ([currentChannelListData, userListData, userGroupsData], item) => {
                if (item.channel) {
                    currentChannelListData.push(item.channelID);
                } else if (item.groupID) {
                    userGroupsData.push(item.groupID);
                } else {
                    userListData.push(item.id);
                }
                return [currentChannelListData, userListData, userGroupsData];
            },
            [[], [], []]
        );

    const getPulseAudienceCount = async (users, excludedUsers, anonymous) => {
        const [channelListData, userListData, userGroupsData] = getRequestedListData(users);
        const excludedListData = excludedUsers ? excludedUsers.map((item) => item.id) : [];
        const apiData = {
            channels: channelListData,
            requestedUsers: userListData,
            excludedUsers: excludedListData,
            userGroups: userGroupsData,
            withImages: true,
        };
        try {
            const data = await getAudienceCount({ ...apiData });
            setPulseAudienceData(data);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
        }
    };

    useEffect(() => {
        setQuesAdded(true);
        if (
            audience.userList.length === 0 &&
            pulseData &&
            (pulseData?.requestedUsers || pulseData?.channels || pulseData?.userGroups)
        ) {
            // while editing the pulse
            setData(pulseData.requestedUsers, pulseData.channels, pulseData.userGroups, pulseData.excludedUsers);
        } else {
            // while creating the pulse
            const hasChannel = find(audience.userList, (value) => value.channel || value.groupID) ? true : false;
            setExclude({ channel: hasChannel, checked: audience.excludedUsers.length > 0 });
        }
    }, [pulseData]);

    const isEditing = () => {
        return isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING;
    };

    const getUsersList = (users) =>
        users.map((user) => ({
            id: user._id,
            name: user.userName,
            memberID: user.memberID,
            picUrl: user.userImageURL,
            channel: false,
            isEditingOnetime: isEditingOnetime, // if isEditingOnetime is true then hide the x mark next to selected audience
            isEditing: isEditing(),
        }));

    const setData = (requestedUsers, channels, userGroups, excludedUsers) => {
        const tempUsersArray = getUsersList(filter(requestedUsers, (user) => user?.source?.length === 0) || []);
        const tempChannelArray = filter(channels, (item) => item?.channelID).map((channel) => ({
            id: channel.channelID,
            name: channel.channelName,
            channelID: channel.channelID,
            channelName: channel.channelName,
            picUrl: null,
            channel: true,
            memberCount: 0,
            isEditing: isEditing(),
        }));
        const tempUserGroupArray = filter(userGroups, (item) => item?.userGroupID).map((userGroup) => ({
            id: userGroup.userGroupID,
            name: userGroup.userGroupName,
            groupID: userGroup.userGroupID,
            picUrl: null,
            memberCount: 0,
            isEditingOnetime: isEditing(),
        }));
        const combined = [...tempUsersArray, ...tempChannelArray, ...tempUserGroupArray];
        const tempUserExcludedList = getUsersList(excludedUsers || []);
        setAudience({
            ...audience,
            userList: combined,
            excludedUsers: tempUserExcludedList,
            anonymous: pulseData?.anonymous === 'Anonymous',
        });
        if (tempChannelArray && tempChannelArray.length > 0) {
            setExclude({ channel: true, checked: tempUserExcludedList && tempUserExcludedList.length > 0 });
        }
        getPulseAudienceCount(combined, tempUserExcludedList, pulseData?.anonymous === 'Anonymous');
    };

    // callback on selecting audience
    const onChange = (value) => {
        setError(!value);
        const temp = { ...audience };
        temp.userList = [...audience.userList];
        temp.userList = addOrRemoveElement(temp.userList, value);
        setAudience(temp);
        setExcludeUser(temp.userList, false);
    };

    // callback on selecting excluded users
    const onChangeExcludeUsers = (value) => {
        const temp = { ...audience };
        temp.excludedUsers = [...audience.excludedUsers];
        temp.excludedUsers = addOrRemoveElement(temp.excludedUsers, value);
        setAudience(temp);
        getPulseAudienceCount(audience.userList, temp.excludedUsers, audience.anonymous);
    };

    // callback on removing selected audience
    const onRemoveUser = (data) => {
        const temp = { ...audience };
        temp.userList = [...data];
        setAudience(temp);
        setExcludeUser(data, true);
    };

    // callback on removing selected excluded users
    const onRemoveExcludedUser = (data) => {
        const temp = { ...audience };
        temp.excludedUsers = [...data];
        setAudience(temp);
        getPulseAudienceCount(audience.userList, data, audience.anonymous);
    };

    // Show/hide exclude user UI if channels are added/removed
    const setExcludeUser = (data, removeUser) => {
        const hasChannelOrUserGroup = find(data, (value) => value.channel || value.groupID);
        if (hasChannelOrUserGroup) {
            setExclude({ channel: true, checked: audience.excludedUsers.length > 0 });
        }
        if (removeUser && !hasChannelOrUserGroup) {
            setExclude({ channel: false, checked: false });
        }
        getPulseAudienceCount(data, hasChannelOrUserGroup ? audience.excludedUsers : [], audience.anonymous);
    };

    useBeforeunload((event) => {
        event.preventDefault();
    });

    return (
        <div className='pulse-audience-main'>
            <div>
                <div className='pa-audience-box'>
                    <h4 className='pa-header'>Choose your audience</h4>
                    <SearchUser
                        autoCompleteId='searchUser'
                        width={341}
                        onChange={onChange}
                        placeholder='Search for channels and usernames'
                        filterChannels={false}
                        listBoxProps={{ style: { maxHeight: 300 } }}
                        selectedOptions={audience.userList}
                    />
                    <CustomUserTags users={audience.userList} onRemove={onRemoveUser} />
                    {error && <p className='error-validation-text'>Please add a user or channel name</p>}
                    {exclude.channel && (
                        <>
                            <div className='pa-exclude-users'>
                                <CustomCheckbox
                                    checked={exclude.checked}
                                    onClick={() => {
                                        if (exclude.checked) {
                                            const temp = { ...audience };
                                            temp.excludedUsers = [];
                                            setAudience(temp);
                                        }
                                        setExclude({ ...exclude, checked: !exclude.checked });
                                    }}
                                />
                                <p>Exclude users</p>
                            </div>
                            {isEditing() && (
                                <p className='audience-note'>
                                    Note: Users who have already received this pulse cannot be excluded
                                </p>
                            )}
                            {exclude.checked && (
                                <>
                                    <SearchUser
                                        autoCompleteId='excludeUser'
                                        width={341}
                                        onChange={onChangeExcludeUsers}
                                        filterChannels={true}
                                        placeholder='Search for users'
                                        selectedData={audience.userList}
                                        listBoxProps={{ style: { maxHeight: 300 } }}
                                        selectedOptions={audience.excludedUsers}
                                    />
                                    <CustomUserTags users={audience.excludedUsers} onRemove={onRemoveExcludedUser} />
                                </>
                            )}
                        </>
                    )}
                </div>
                {
                    <>
                        <div className='separator' />
                        <AnonymousSection
                            audience={audience}
                            setAudience={setAudience}
                            disabled={isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING}
                            onlyAnonymous={eNPS}
                        />
                    </>
                }
            </div>
            <PulseCreationInfo
                eNPS={eNPS}
                data={pulseAudienceData}
                scheduled={getScheduleInfo}
                questions={questions}
                setStep={setStep}
                scheduling={scheduling}
            />
        </div>
    );
};

Index.propTypes = {
    setQuesAdded: PropTypes.func,
    eNPS: PropTypes.bool,
    pulseData: PropTypes.object,
    audience: PropTypes.object,
    setAudience: PropTypes.func,
    questions: PropTypes.object,
    setStep: PropTypes.func,
    getScheduleInfo: PropTypes.func,
    isEditingOnetime: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    editType: PropTypes.bool,
    scheduling: PropTypes.object,
    pulseAudienceData: PropTypes.object,
    setPulseAudienceData: PropTypes.func,
};

export default Index;
