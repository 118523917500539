export const [DEPARTMENT, COUNTRY] = ['department', 'country'];

export const LABELS = {
    DEPARTMENT: {
        title: 'Add new department',
        editTitle: 'Edit department details',
        nameFieldLabel: 'Department Name',
        nameFieldPlaceholder: 'Add a name for the new department',
        nameFieldDropdownSearchText: 'Search names',
        userFieldLabel: 'Users for this department',
        userFieldPlaceholder: 'Select users',
        userFieldDropdownSearchText: 'Search names',
        selectedFieldLabel: 'Selected users for this department',
        submitBtn: 'Save',
        inputField: true,
    },
    COUNTRY: {
        title: 'Add new country',
        editTitle: 'Edit country details',
        nameFieldLabel: 'Country',
        nameFieldPlaceholder: 'Select country',
        nameFieldDropdownSearchText: 'Search names',
        userFieldLabel: 'Users for this country',
        userFieldPlaceholder: 'Select users',
        userFieldDropdownSearchText: 'Search country',
        selectedFieldLabel: 'Selected users for this country',
        submitBtn: 'Save',
    },
    MANAGER: {
        title: 'Add manager details',
        userFieldLabel: 'Manager',
        userFieldPlaceholder: 'Select Manager',
        userFieldDropdownSearchText: 'Search name',
        submitBtn: 'Save',
    },
};
