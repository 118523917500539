import { format } from 'date-fns';
import React from 'react';
import VertMenu from 'components/ReusableComponents/VertMenu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Tooltip } from 'components';
import ImageStack from 'components/ReusableComponents/ImageStack';
import AwardProgressBar from './Components/AwardProgressBar';
import { dateRanges, HISTORY_FROM } from 'constants.js';
import { getLastNDays } from 'utils/HelperFunctions';
import { ReactComponent as SendVotingReminderIcon } from 'Assets/images/send_voting_reminder.svg';
import { ReactComponent as AnnounceAwardIcon } from 'Assets/images/announce_award.svg';
import { ReactComponent as ExtendVotingDeadlineIcon } from 'Assets/images/extend_voting_deadline.svg';
import { ReactComponent as PickAwardeeIcon } from 'Assets/images/pick_awardee.svg';
import { ReactComponent as EditAwardIcon } from 'Assets/images/pulse.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/deleteIcon.svg';
import { AWARD_STATUS, EMPLOYEE_AWARDS } from './constants';
import TieTooltip from './Components/TieTooltip';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';

const goToProfile = (userId, history) => () => {
    if (userId) {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `${EMPLOYEE_AWARDS.PROFILE}${userId}`,
            navMenuCode: HISTORY_FROM.EMPLOYEE_AWARDS,
        })();
    }
};

export const rowFormatterDirectCompleted = (history) => (award) => ({
    id: award._id,
    row: [
        <Tooltip key={award._id} title={award.name}>
            <div className='award-name-cell'>{award.name}</div>
        </Tooltip>,
        <button
            className='link-btn'
            onClick={goToProfile(award.awardee?._id, history)}
            key={`${award.awardee?._id}-awardee`}
        >
            {award.awardee?.userName}
        </button>,
        award.awardDate ? format(new Date(award.awardDate), 'MMM dd, yyyy') : '-',
        <button
            className='link-btn'
            onClick={goToProfile(award.awardedBy?._id, history)}
            key={`${award.awardedBy?._id}-awardedBy`}
        >
            {award.awardedBy?.userName}
        </button>,
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
    ],
});

export const rowFormatterDirectScheduled = (optionMenu, selectedAward, history) => (award) => ({
    id: award._id,
    row: [
        <Tooltip key={award._id} title={award.name}>
            <div className='award-name-cell'>{award.name}</div>
        </Tooltip>,
        <button className='link-btn' onClick={goToProfile(award.awardee?._id, history)} key={award._id}>
            {award.awardee?.userName}
        </button>,
        format(new Date(award.launchDate), 'MMM dd, yyyy'),
        <button className='link-btn' onClick={goToProfile(award.awardedBy?._id, history)} key={award._id}>
            {award.awardedBy?.userName}
        </button>,
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
        getAnnouncementChannelString(award.announcementChannel, 2),
        <VertMenu
            key={award._id}
            placement={'bottom-end'}
            disabled={false}
            getSelected={(index) => {
                if (optionMenu[index].value === 'editAward') {
                    history.push(EMPLOYEE_AWARDS.LAUNCH, { state: { award, edit: true } });
                } else {
                    selectedAward.current = award;
                    if (optionMenu[index].value === 'deleteAward') {
                        optionMenu[index].onClickHandler();
                    }
                }
            }}
            optionMenu={optionMenu}
        >
            <MoreHorizIcon />
        </VertMenu>,
    ],
});

export const rowFormatterPollScheduled = (optionMenu, selectedAward, history) => (award) => ({
    id: award._id,
    row: [
        <Tooltip key={award._id} title={award.name}>
            <div className='award-name-cell'>{award.name}</div>
        </Tooltip>,
        format(new Date(award.launchDate), 'MMM dd, yyyy'),
        <div className='total-audience' key={`${award._id}-nomineeSample`}>
            <ImageStack members={award.nomineeSample} />
            <p className='bold'>{award.nomineeCount || 0}</p>
        </div>,
        <span key={award._id} className='truncate-text'>
            {getAnnouncementChannelString(award.announcementChannel, 3)}
        </span>,
        <VertMenu
            key={award._id}
            placement={'bottom-end'}
            disabled={false}
            getSelected={(index) => {
                if (optionMenu[index].value === 'editAward') {
                    history.push(EMPLOYEE_AWARDS.LAUNCH, { state: { award: award, edit: true } });
                } else {
                    selectedAward.current = award;
                    if (optionMenu[index].value === 'deleteAward') {
                        optionMenu[index].onClickHandler();
                    }
                }
            }}
            optionMenu={optionMenu}
        >
            <MoreHorizIcon />
        </VertMenu>,
    ],
});

export const rowFormatterPollOngoing = (optionMenu, selectedAward) => (award) => {
    let options;
    if (award?.winner?.length > 1) {
        if (award.responseCount === award.requestedCount) {
            options = optionMenu.slice(1).filter((option) => option.value === 'pickAwardee');
        } else {
            options = optionMenu.slice(1);
        }
    } else if (award?.winner?.length === 1 && award.responseCount === award.requestedCount) {
        options = optionMenu.filter((option) => option.value === 'announceAward');
    } else {
        options = optionMenu;
    }
    return {
        id: award._id,
        row: [
            <Tooltip key={award._id} title={award.name}>
                <div className='award-name-cell'>{award.name}</div>
            </Tooltip>,
            format(new Date(award.launchDate), 'MMM dd, yyyy'),
            <div key={`${award._id}-winner`}>
                <TieTooltip award={award} status={AWARD_STATUS.ONGOING} />
                <span>{format(new Date(award.endDate), 'HH:mm a | MMM dd, yyyy')}</span>
            </div>,
            <div className='total-audience' key={`award._id-nomineeSample`}>
                <ImageStack members={award.nomineeSample} />
                <p className='bold'>{award.nomineeCount || 0}</p>
            </div>,
            <AwardProgressBar
                key={award._id}
                responseCount={award.responseCount}
                requestedCount={award.requestedCount}
            />,
            <VertMenu
                key={award._id}
                placement={'bottom-end'}
                disabled={false}
                getSelected={() => (selectedAward.current = award)}
                optionMenu={options}
            >
                <MoreHorizIcon />
            </VertMenu>,
        ],
    };
};

export const rowFormatterPollCompleted = (history) => (award) => ({
    id: award._id,
    row: [
        <Tooltip title={award.name} key={award._id}>
            <div className='award-name-cell'>{award.name}</div>
        </Tooltip>,
        <div className='d-flex' key={`${award._id}-awardee`}>
            <button
                className='link-btn error-tooltip-span'
                onClick={goToProfile(award.awardee?._id, history)}
                key={`${award.awardee?._id}-awardee`}
            >
                {award.awardee?.userName}
            </button>
            <TieTooltip award={award} status={AWARD_STATUS.COMPLETED} />
        </div>,
        format(new Date(award.awardDate), 'MMM dd, yyyy'),
        <div className='total-audience' key={`award._id-nomineeSample`}>
            <ImageStack members={award.nomineeSample} />
            <p className='bold'>{award.nomineeCount || 0}</p>
        </div>,
        <span key={award._id}>
            <strong>{award.responseCount}</strong>/{award.requestedCount}
        </span>,
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
    ],
});

export const dateRangeHandler = ({
    index,
    setSelectedDateRangeDisplayLabel,
    setShowDatePicker,
    setSelectedDateRange,
    dateRangeObject = false,
}) => {
    setSelectedDateRangeDisplayLabel(dateRanges[index].label);
    let range = dateRanges[index].value;
    let startDate;
    switch (range) {
        case 'today':
            startDate = getLastNDays(0).startDate;
            break;
        case 'last7':
            startDate = getLastNDays(7).startDate;
            break;
        case 'last30':
            startDate = getLastNDays(30).startDate;
            break;
        case 'last60':
            startDate = getLastNDays(60).startDate;
            break;
        case 'last90':
            startDate = getLastNDays(90).startDate;
            break;
        case 'Custom Date':
            setShowDatePicker(true);
            return;
    }
    if (dateRangeObject) {
        range = startDate ? { startDate: format(startDate, 'dd-MM-yyyy') } : {};
    }
    setSelectedDateRange(range);
};

export const threeDotMenuOptions = {
    announceAward: (setOpenAnnounceAwardModal) => ({
        label: (
            <span onClick={() => setOpenAnnounceAwardModal(true)}>
                <AnnounceAwardIcon className='mr-2 pointer-cursor' /> Announce Award
            </span>
        ),
        value: 'announceAward',
    }),
    extendDeadline: (setOpenExtendDeadlineModal) => ({
        label: (
            <span onClick={() => setOpenExtendDeadlineModal(true)}>
                <ExtendVotingDeadlineIcon className='mr-2 pointer-cursor' /> Extend voting deadline
            </span>
        ),
        value: 'extendVotingDeadline',
    }),
    pickAwardee: (setOpenPickAwardeeModal) => ({
        label: (
            <span onClick={() => setOpenPickAwardeeModal(true)}>
                <PickAwardeeIcon className='mr-2 pointer-cursor' /> Pick Awardee
            </span>
        ),
        value: 'pickAwardee',
    }),
    sendReminder: (setOpenSendReminderModal) => ({
        label: (
            <span onClick={() => setOpenSendReminderModal(true)}>
                <SendVotingReminderIcon className='mr-2 pointer-cursor' /> Send voting reminder
            </span>
        ),
        value: 'sendVotingReminder',
    }),
    edit: () => ({
        label: (
            <span>
                <EditAwardIcon className='mr-2 pointer-cursor' /> Edit Award details
            </span>
        ),
        value: 'editAward',
    }),
    delete: (setOpenDeleteAwardModal) => ({
        label: (
            <span>
                <DeleteIcon className='mr-2 pointer-cursor' /> Delete
            </span>
        ),
        value: 'deleteAward',
        onClickHandler: () => setOpenDeleteAwardModal(true),
    }),
};

export const getAnnouncementChannelString = (channelList, n) => {
    let retVal = channelList
        ?.slice(0, n)
        ?.map((c) => `#${c.channelName}`)
        ?.join(', ');
    if (channelList?.length > n) {
        retVal += `+${channelList.length - n}`;
    }
    return retVal;
};
