import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';

const Choice = ({ questions, setQuestions, questionIndex, isEditingOngoing }) => {
    const options = questions?.[questionIndex]?.options;

    const handleOptionChange = (event, index) => {
        const tempQuestions = [...questions];
        tempQuestions[questionIndex].options[index].name = event.target.value;
        setQuestions(tempQuestions);
    };

    const handleAddChoice = () => {
        const tempQuestions = [...questions];
        tempQuestions[questionIndex].options.push({ name: '', error: false, id: nanoid() });
        setQuestions(tempQuestions);
    };

    const handleDeleteOption = (index) => {
        const tempQuestions = [...questions];
        tempQuestions[questionIndex].options.splice(index, 1);
        setQuestions(tempQuestions);
    };

    const handleOnDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination || destination.index === source.index) {
            return;
        }
        const tempQuestionArray = [...questions];
        tempQuestionArray[questionIndex].options.splice(
            destination.index,
            0,
            tempQuestionArray[questionIndex].options.splice(source.index, 1)[0]
        );
        setQuestions(tempQuestionArray);
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className='editing-options'>
                <Droppable droppableId={'question-options-droppable-column'}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {options?.map((option, index) => (
                                <Draggable draggableId={option.id} key={option.id} index={index}>
                                    {(innerProvided) => (
                                        <div
                                            className='d-flex align-items-center mb-4'
                                            ref={innerProvided.innerRef}
                                            {...innerProvided.draggableProps}
                                        >
                                            {!isEditingOngoing && (
                                                <span {...innerProvided.dragHandleProps}>
                                                    <DragIndicatorIcon style={{ color: '#c4c4c4' }} />
                                                </span>
                                            )}
                                            <input
                                                type='text'
                                                value={option.name}
                                                onChange={(event) => handleOptionChange(event, index)}
                                                placeholder={`Choice ${index + 1}`}
                                                disabled={isEditingOngoing}
                                            />
                                            {options.length > 2 && !isEditingOngoing && (
                                                <CloseIcon onClick={() => handleDeleteOption(index)} />
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <button className='ew-btn tb-btn' onClick={handleAddChoice} disabled={isEditingOngoing}>
                    +Add choice
                </button>
            </div>
        </DragDropContext>
    );
};

Choice.propTypes = {
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    questionIndex: PropTypes.number,
    isEditingOngoing: PropTypes.bool,
};

export default Choice;
