import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import PageToggle from '../components/PageToggle';
import SummaryTable from './SummaryTable';
import RewardsHistoryTable from './RewardsHistoryTable';
import AwardsTable from './AwardsTable';
import { PLATFORM } from 'constants.js';

const Index = ({ active, setActive, history, platform }) => {
    const [key, setKey] = useState('Recognitions');

    return (
        <div className=' activities-container'>
            <div className=' navigatortabs'>
                <Tabs activeKey={key} onSelect={(_key) => setKey(_key)}>
                    <Tab eventKey='Recognitions' title='Recognitions'>
                        {key === 'Recognitions' && <SummaryTable history={history} />}
                    </Tab>
                    <Tab eventKey='Redemptions' title='Redemptions'>
                        {key === 'Redemptions' && <RewardsHistoryTable history={history} />}
                    </Tab>
                    {platform !== PLATFORM.TEAMS && (
                        <Tab eventKey='Awards' title='Awards'>
                            {key === 'Awards' && <AwardsTable history={history} />}
                        </Tab>
                    )}
                </Tabs>
                <PageToggle active={active} setActive={setActive} />
            </div>
        </div>
    );
};

Index.propTypes = {
    active: PropTypes.string,
    setActive: PropTypes.func,
    history: PropTypes.object,
    platform: PropTypes.string,
};

export default Index;
