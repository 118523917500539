import React from 'react';
import PropTypes from 'prop-types';
import FeedbackTable from '../FeedbackTable';
import '../style.scss';

const Index = ({ history }) => {
    return (
        <div className='my-feedback-container'>
            <FeedbackTable history={history} feedbackGiven={false} />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
