import React from 'react';

export const configureGiftValuesData = {
    confirm: 'Go to Organization Page',
    heading: 'Configure gift values for celebrations',
    subHeading:
        'To send gifts with celebration wishes, you need to set up countries and map your employees to them. You can do that on the Organization Page',
};

export const STATUS_TOOLTIP =
    'If the status is disabled, the users in that country will only receive Celebration wishes.';
export const selectChannelModalData = {
    confirm: 'Save',
    heading: 'Channel to send wishes',
    subHeading: 'Select a channel where you would like to share the celebration wishes',
};

export const CONFIGURE_GIFT_SUBTITLE = `Set monetary rewards for birthdays and work anniversaries. Gift a fixed amount that is added to your employees' EngageWith redeemable balances. You can set custom values based on your employees' countries.`;

export const DISABLE_CHANNEL_WISHES_MODAL_DATA = {
    confirm: 'Disable',
    heading: 'Are you sure you want to disable this?',
    subHeading:
        'Once disabled, wishes will only be sent as direct messages to the employees celebrating their special days.',
};

export const ANNIVERSARY_TOOLTIP = (
    <>
        <span className=' anniversay-tooltip-title'>{'1st (Y), 2nd (Y), 3rd (Y)...  = 100, 200, 300....'}</span>
        <p>
            {
                'For eg: If you specify a gift value for the 3rd year without additional values, it implies that the same value persists for all subsequent years, including the 3rd, 4th, 5th, 6th, and beyond.'
            }
        </p>
    </>
);

export const CELEBRATIONS = {
    CELEBRATIONS: '/dashboard/celebration',
    EMPLOYEES_WITHOUT_ANY_DATES: '/dashboard/celebration/employees-without-dates',
};
