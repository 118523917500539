import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import { useToaster } from 'Context/SnackbarContext';
import ConditionalWrapper from 'components/ReusableComponents/ConditionalWrapper';
import { Tooltip } from 'components';
import AddChannelModal from 'components/Modals/AddChannelModal';
import RemoveChannelModal from 'components/Modals/RemoveChannelModal';
import PrivateChannelModal from 'components/Modals/RemoveChannelModal/privateChannel';
import { AdminBox } from 'utils/Stylesheet/style.js';
import { uniqBy } from 'lodash';
import { isSlack } from 'utils/HelperFunctions';
import './style.scss';

const handleRemoveChannel = (channel, addedChannelCount, setRemoveChannelOpen, config, SetSnackbar) => {
    if (addedChannelCount < 2) {
        SetSnackbar({
            open: true,
            variant: 'error',
            message: 'EngageWith must be added to atleast one channel',
        });
        return;
    } else if (
        config?.defaultAnniversariesChannel?.channelId === channel.value ||
        config?.defaultRecognitionChannel?.channelId === channel.value
    ) {
        SetSnackbar({
            open: true,
            variant: 'error',
            message: 'Cannot remove default channel for recognitions and birthday/work anniversary',
        });
        return;
    }
    setRemoveChannelOpen({ open: true, channel });
};

const Index = ({ addChannelOpen, setAddChannelOpen, channelData, isLoading }) => {
    const [removeChannelOpen, setRemoveChannelOpen] = useState({
        open: false,
        channel: {},
    });
    const [privateChannelOpen, setPrivateChannelOpen] = useState({
        open: false,
        channel: {},
    });

    const { SetSnackbar } = useToaster();
    const { config, platform } = useSelector(mapStateToProps, shallowEqual);

    const isSlackPlatform = isSlack(platform);

    // Extract unique groupNames for teams
    const uniqueIntegratedTeams = isSlackPlatform ? channelData : uniqBy(channelData, 'groupId');

    if (isLoading) {
        return <CustomLoader />;
    }

    return (
        <>
            <div className='dashboard-channel-container mt-4'>
                {uniqueIntegratedTeams?.length > 0 &&
                    uniqueIntegratedTeams?.map((channel) => {
                        const addedChannelCount = uniqueIntegratedTeams.filter((data) => data.alreadyPresent).length;
                        const channelName = channel.label;
                        return channel.alreadyPresent ? (
                            <AdminBox className='channel-box' key={channel.value}>
                                <ConditionalWrapper
                                    condition={channel.label.length > 18}
                                    wrapper={(children) => <Tooltip title={channel.label}>{children}</Tooltip>}
                                >
                                    <span>{channelName}</span>
                                </ConditionalWrapper>

                                <ConditionalWrapper
                                    condition={addedChannelCount < 2}
                                    wrapper={(children) => (
                                        <Tooltip
                                            title={`EngageWith must be added to atleast one ${
                                                isSlackPlatform ? 'channel' : 'team'
                                            }`}
                                        >
                                            {children}
                                        </Tooltip>
                                    )}
                                >
                                    <div
                                        className='cross-icon'
                                        onClick={() =>
                                            handleRemoveChannel(
                                                channel,
                                                addedChannelCount,
                                                setRemoveChannelOpen,
                                                config,
                                                SetSnackbar
                                            )
                                        }
                                    >
                                        <CancelOutlined />
                                    </div>
                                </ConditionalWrapper>
                            </AdminBox>
                        ) : null;
                    })}
            </div>
            {addChannelOpen && <AddChannelModal setOpen={setAddChannelOpen} channel={uniqueIntegratedTeams} />}
            {removeChannelOpen.open && (
                <RemoveChannelModal
                    channel={removeChannelOpen.channel}
                    onClose={() => setRemoveChannelOpen({ open: false, channel: {} })}
                    setPrivateChannelOpen={setPrivateChannelOpen}
                />
            )}
            {privateChannelOpen.open && (
                <PrivateChannelModal
                    channel={privateChannelOpen.channel}
                    onConfirm={() => setPrivateChannelOpen({ open: false, channel: {} })}
                />
            )}
        </>
    );
};

const mapStateToProps = ({ Workspace }) => ({ config: Workspace.config, platform: Workspace.platform });

Index.propTypes = {
    addChannelOpen: PropTypes.bool,
    setAddChannelOpen: PropTypes.func,
    channelData: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default Index;
