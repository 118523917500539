import { actions } from './actionEvents';
import { DEFAULT_FEEDBACK_SETTINGS } from 'components/Dashboard/Content/Feedback/constants';
import { DEFAULT_SUGGESTION_BOX_SETTINGS } from 'components/Dashboard/Content/SuggestionBox/constants';

const initialState = {
    nameForPoints: 'Points',
    wallet: {},
    config: undefined,
    approver: null,
    todo: {},
    companyName: '',
    feedback: { ...DEFAULT_FEEDBACK_SETTINGS },
    suggestionBox: { ...DEFAULT_SUGGESTION_BOX_SETTINGS },
};

export default function WorkspaceReducer(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case actions.GET_WORKSPACE_DETAILS: {
            // added braces to resolve codesmell - lexical declaration
            const {
                workspaceName,
                workspaceProfilePhoto,
                workspaceCountry,
                nameForPoints = 'Points',
                workspaceCreatedAt,
                optedForWallet,
                walletBalance,
                enpsWorkspaceName,
                approverDetails,
                isManagerFlowCompleted,
                isPulseFlowCompleted,
                isRewardFlowCompleted,
                suggestionBoxAdmins,
                isENPSFlowCompleted,
                isLeaderboardEnabled = true,
                isFeedbackFlowCompleted = false,
                isSuggestionBoxFlowCompleted = false,
                canLaunchNewEnps,
                timezone,
                redemption,
                adminEmail,
                channels,
            } = payload;

            const wallet = {
                optedForWallet,
                walletBalance,
            };

            const todo = {
                isManagerFlowCompleted,
                isPulseFlowCompleted,
                isRewardFlowCompleted,
                isENPSFlowCompleted,
                isFeedbackFlowCompleted,
                isSuggestionBoxFlowCompleted,
                isLeaderboardEnabled,
            };

            return {
                ...state,
                workspaceName,
                workspaceProfilePhoto,
                workspaceCountry,
                workspaceCreatedAt,
                nameForPoints,
                canLaunchNewEnps,
                suggestionBoxAdmins,
                timezone,
                approver: approverDetails,
                companyName: enpsWorkspaceName,
                wallet,
                todo,
                config: { ...state.config, redemption },
                adminEmail,
                channels,
            };
        }
        case actions.UPDATE_WORKSPACE_DETAILS:
            return { ...state, ...payload };

        default:
            return state;
    }
}
