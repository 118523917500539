import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({
    index,
    rewardName,
    points,
    nameForPoints = 'Points',
    handlePointsUpdate,
    width = 235,
    disabled = false,
}) => {
    return (
        <div className='reward-input' style={{ width: width }}>
            <p className='reward-name'>{rewardName}</p>
            <div className='reward-input-container'>
                <input
                    className='reward-input-value'
                    type='number'
                    value={points}
                    onChange={(e) => handlePointsUpdate(index, e.target.value)}
                    disabled={disabled}
                />
                <div className='name-for-reward'>
                    <p>{nameForPoints}</p>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    rewardName: PropTypes.string,
    rewardColor: PropTypes.string,
    points: PropTypes.number,
    handlePointsUpdate: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    nameForPoints: PropTypes.string,
};

export default Index;
