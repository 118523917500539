import React from 'react';
import PropTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import clsx from 'clsx';
import './style.scss';

const Index = ({ blockData, handleOpenModal, index }) => {
    const { SetSnackbar } = useToaster();
    const showError = (message) =>
        SetSnackbar({
            open: true,
            variant: 'warning',
            message,
        });

    return (
        <div
            className={clsx({
                'org-block': true,
                'container-disabled': blockData.disableView && blockData.disabled,
            })}
            key={blockData.heading}
        >
            <div className='ob-header-container'>
                <p className='ob-heading'>{blockData.heading}</p>
                {blockData.toggle}
                {blockData.button && (
                    <button
                        className='ob-button'
                        onClick={() =>
                            blockData.disabled ? showError(blockData.disabledMessage) : handleOpenModal(index)
                        }
                    >
                        {blockData.button}
                    </button>
                )}
            </div>
            <p className='ob-subtext'>{blockData.subtext}</p>
            <div className={clsx({ 'container-disabled': blockData.disableContent && blockData.disabled })}>
                {blockData.component}
                {blockData.endtext}
            </div>
        </div>
    );
};

Index.propTypes = {
    blockData: PropTypes.object,
    handleOpenModal: PropTypes.func,
    index: PropTypes.number,
};

export default Index;
