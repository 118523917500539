import React from 'react';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as SetupFeedbackImage } from 'Assets/images/setup-feedback.svg';
import { FEEDBACK_360, FEEDBACK } from '../constants';
import './style.scss';

const Index = ({ history }) => {
    const emptyScreenContent = {
        image: <SetupFeedbackImage />,
        headingText: [FEEDBACK_360],
        subheadingText:
            'Enable 360º feedback to help employees share constructive and actionable feedback to their co-workers',
        btnName: 'Enable 360º feedback',
        action: () => history.push(FEEDBACK.CONFIGURATION),
    };
    return (
        <div>
            <TitleBar title={[FEEDBACK_360]} />
            <div className='container-setup-feedback'>
                <EmptyContent {...emptyScreenContent} />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};

export default Index;
