import React from 'react';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as NoTasks } from 'Assets/images/noTasks.svg';
import AllTasks from './AllTasks';
import './style.scss';
import { GET_TASKS } from 'Services/apiKeys';
import { getTasksData } from 'Services/apiFunctions';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { updateUserProfile } from 'redux/user/actions';

const Tasks = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();

    const {
        data: tasksItems,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
    } = useInfiniteQuery([GET_TASKS], getTasksData, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.hasMore ? pages.length + 1 : undefined;
        },
        onSuccess: (data) => {
            updateUserProfile(dispatch, { pendingTasks: data?.pages[0]?.data?.total });
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error.message),
    });

    const showTasks = tasksItems?.pages[0]?.data?.total > 0;

    return (
        <div>
            <TitleBar title='Tasks' />
            {isLoading && <CustomLoader />}
            {!showTasks && !isLoading && !isFetching && (
                <div className='no-tasks'>
                    <EmptyContent image={<NoTasks />} headingText='Hurray! No pending tasks to complete.' />
                </div>
            )}
            {showTasks && !isLoading && (
                <AllTasks history={history} tasksData={tasksItems} next={fetchNextPage} hasMore={hasNextPage} />
            )}
        </div>
    );
};

Tasks.propTypes = {
    history: PropTypes.object,
};

export default Tasks;
