import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { useToaster } from 'Context/SnackbarContext';
import { modifyUser, getDepartments, getManagerList, getOrgCountries } from 'Services/apiFunctions';
import { GET_PAGINATED_COUNTRY, GET_PAGINATED_DEPARTMENT, GET_MANAGERS_LIST } from 'Services/apiKeys';
import CustomDatePicker from 'components/ReusableComponents/CustomDatePicker';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import InputField from 'components/ReusableComponents/InputField';
import LabelWithTooltip from 'components/ReusableComponents/LabelWithTooltip';
import { CustomSwitch } from 'components';
import DeactivateUserModal from 'components/Modals/DeactivateUser';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import EWButton from 'components/ReusableComponents/EWButton';
import { countryMap } from 'constants.js';
import { map } from 'lodash';
import './style.scss';
import { format } from 'date-fns';
import { updateUserProfile } from 'redux/user/actions';

const LabelWithImage = ({ image, name }) => {
    //LabelWithImage
    return (
        <>
            <img className='country-image' src={image} alt='' />
            &nbsp;{name}
        </>
    );
};

const Index = ({ open, onClose, data, platform }) => {
    const { adminId, userId } = useSelector(mapStateToProps, shallowEqual);
    const queryClient = useQueryClient();
    const ROLES = [
        { label: 'Admin', value: 'Admin' },
        { label: 'User', value: 'User' },
    ];
    const { SetSnackbar } = useToaster();
    const [birthday, setBirthday] = useState(data?.Birthday);
    const [monthlyPoints, setMonthlyPoints] = useState(data?.monthlyAllowance);
    const [currentPoints, setCurrentPoints] = useState(data?.creditBalance);
    const [department, setDepartment] = useState(data?.Department);
    const [userCountry, setUserCountry] = useState(data?.Country);
    const [role, setRole] = useState(data?.role);
    const [manager, setManager] = useState(data?.Manager);
    const [anniversary, setAnniversary] = useState(data?.Anniversary);
    const [status, setStatus] = useState(data?.Status);
    const [canRedeemRewards, setCanRedeemRewards] = useState(undefined);
    const [deactivate, setDeactivate] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();

    const updateCount = (condition) => {
        setCount(condition ? count + 1 : count > 0 && count - 1);
    };

    const isMsteams = platform === 'MSTeams';

    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message);

    useQuery([GET_PAGINATED_DEPARTMENT, 1, 100], getDepartments, {
        refetchOnWindowFocus: false,
        onSuccess: (data) =>
            setDepartmentList(map(data.departments, (item) => ({ ...item, label: item.name, value: item.id })) || []),
    });

    useQuery([GET_PAGINATED_COUNTRY, 1, 100], getOrgCountries, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onSuccess: (data) =>
            setCountries(map(data?.countries, (item) => ({ ...item, label: item?.name, value: item?.name })) || []),
        onError,
    });

    const handleManagersData = (data) => {
        setManagerList(
            map(data, (member) => ({
                ...member,
                managerObjectId: member?._id,
                imageUrl: member?.memberImageURL,
                label: (
                    <LabelWithImage
                        name={member?.userName}
                        image={member?.memberImageURL || require('Assets/images/defaultUser.png')}
                    />
                ),
                value: member?.userName,
            })) || []
        );
    };

    useQuery([GET_MANAGERS_LIST], getManagerList, {
        onSuccess: (data) => handleManagersData(data?.managers || []),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const { mutateAsync: updateProfile, isLoading } = useMutation(modifyUser);

    const handleDepartmentChange = (index) => {
        setDepartment(departmentList[index]);
        updateCount(data?.Department !== departmentList[index].name);
    };

    const handleUserCountryChange = (index) => {
        setUserCountry(countries?.[index]?.name);
        updateCount(data?.Country !== countries[index].name);
    };

    const handleActiveToggle = () => {
        if (data?.Status && data?.combinedRedeemBalance > 0) {
            setDeactivate(true);
        } else {
            setStatus(!status);
            updateCount(true);
        }
    };

    const handleManagerChange = (index) => {
        setManager(managerList[index]);
        updateCount(data?.Manager.managerObjectId !== managerList[index]._id);
    };

    const handleRoleChange = (index) => {
        if (ROLES[index].label === 'User' && data?.memberID === adminId) {
            showSnackBarMessage(SetSnackbar, 'warning', 'Cannot remove yourself from admin');
            return;
        }
        setRole(ROLES[index].label);
        updateCount(data?.role !== ROLES[index].label);
    };

    const handleConfirm = () => {
        setStatus(false);
        setCanRedeemRewards(false);
        setDeactivate(false);
        updateCount(true);
    };

    const handleSave = async () => {
        const apiData = {
            userIds: [data._id],
            ...(status !== data?.Status && { isActive: status }),
            ...(monthlyPoints && monthlyPoints !== data?.monthlyAllowance && { monthlyAllowance: monthlyPoints }),
            ...(currentPoints && currentPoints !== data?.creditBalance && { creditBalance: currentPoints }),
            ...(role && role !== data?.role && { role }),
            ...(typeof birthday === 'object' && birthday !== data?.Birthday && { dob: format(birthday, 'yyyy-MM-dd') }),
            ...(typeof anniversary === 'object' &&
                anniversary !== data?.Anniversary && { doj: format(anniversary, 'yyyy-MM-dd') }),
            // in UI, we show full country name, but in API we need to send country abbreviation
            ...(userCountry && userCountry !== data?.Country && { userCountry: countryMap[userCountry] }),
            ...(department &&
                department !== data?.Department &&
                typeof department !== 'string' && { departmentId: department.id }),
            ...(manager._id && { managerId: manager._id }),
            ...(data?.Status && !status && { canRedeemRewards }),
        };
        try {
            await updateProfile({ apiData });
            // update logged in user's manager id if there was a change
            if (manager?._id && userId === data?._id) {
                updateUserProfile(dispatch, { managerId: manager._id });
            }
            queryClient.invalidateQueries(GET_PAGINATED_COUNTRY);
            showSnackBarMessage(SetSnackbar, 'success', 'Profile has been updated');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message || 'Some error occurred');
        } finally {
            onClose(true);
        }
    };

    return (
        <Modal open={open} onClose={() => !isLoading && onClose(false)}>
            <div className='container-edit-profile'>
                <CloseIcon className='btn-close' data-testid={'close-edit'} onClick={() => onClose(false)} />
                <h3 className='heading'>Edit Profile</h3>

                <div className='container-fields-bottom'>
                    <div className='cft-name'>
                        <img src={data?.imageURL || require('Assets/images/defaultUser.png')} alt='' />
                        {data?.Name}
                    </div>
                    <div className='cft-status'>
                        <p className='cep-label'>STATUS</p>
                        <div className='d-flex'>
                            <span className='cft-active'>Active</span>
                            <CustomSwitch checked={status} onChange={handleActiveToggle} />
                        </div>
                    </div>
                    <div>
                        <LabelWithTooltip
                            textStyleClass='cep-label'
                            text='Current Points to recognize'
                            tooltipText={`${data?.Name} has ${currentPoints || 0} points currently to recognize others`}
                        />
                        <InputField
                            inputID='profileCurrentPoints'
                            value={currentPoints}
                            handleChange={(_id, value) => {
                                updateCount(parseInt(value) !== parseInt(data?.creditBalance));
                                setCurrentPoints(!value ? '' : Math.abs(value));
                            }}
                            width='203px'
                            inputType='number'
                        />
                    </div>
                    {!isMsteams && (
                        <div>
                            <LabelWithTooltip
                                textStyleClass='cep-label'
                                text='Monthly Point Allowance'
                                tooltipText={`${data?.Name} will get ${
                                    monthlyPoints || 0
                                } points to recognize others every month`}
                            />
                            <InputField
                                inputID='profileMonthlyPoint'
                                value={monthlyPoints}
                                handleChange={(_id, value) => {
                                    updateCount(parseInt(value) !== parseInt(data?.monthlyAllowance));
                                    setMonthlyPoints(!value ? '' : Math.abs(value));
                                }}
                                width='203px'
                                inputType='number'
                            />
                        </div>
                    )}
                    <div>
                        <LabelWithTooltip textStyleClass='cep-label' text='Birthday' />
                        <CustomDatePicker
                            value={birthday || null}
                            onChange={(date) => {
                                updateCount(JSON.stringify(date) !== JSON.stringify(new Date(data?.Birthday)));
                                setBirthday(date);
                            }}
                            placeholder='Add birthday'
                            disablePast={false}
                            disableFuture={true}
                            className='cfb-date'
                            format={'dd MMMM yyyy'}
                        />
                    </div>
                    <div>
                        <LabelWithTooltip textStyleClass='cep-label' text='Joining Date' />
                        <CustomDatePicker
                            value={anniversary || null}
                            onChange={(date) => {
                                updateCount(JSON.stringify(date) !== JSON.stringify(new Date(data?.Anniversary)));
                                setAnniversary(date);
                            }}
                            placeholder='Add joining date'
                            disablePast={false}
                            disableFuture={true}
                            className='cfb-date'
                            format={'MMMM dd yyyy'}
                        />
                    </div>
                    <CustomFilterDropdown
                        title='Manager'
                        selectedName={
                            manager?.userName ? (
                                <LabelWithImage
                                    name={manager?.userName}
                                    image={manager?.imageURL || require('Assets/images/defaultUser.png')}
                                />
                            ) : (
                                'Select'
                            )
                        }
                        optionsSelected={!!manager?.userName}
                        filterOptions={managerList}
                        handleSelection={handleManagerChange}
                        buttonStyleClass='width-203'
                        dropDownID='profileManager'
                        search
                        singleSelect
                    />
                    <CustomFilterDropdown
                        title='Department'
                        filterOptions={departmentList}
                        selectedName={department?.name || department || 'Select Department'}
                        optionsSelected={!!(department?.name || department)}
                        handleSelection={handleDepartmentChange}
                        buttonStyleClass='width-203'
                        dropDownID='profileDepartment'
                        disabled={departmentList.length < 1}
                        search
                        singleSelect
                        customSearchText='Search department'
                    />
                    <CustomFilterDropdown
                        title='Country'
                        filterOptions={countries}
                        selectedName={userCountry || 'Select Country'}
                        optionsSelected={!!userCountry}
                        handleSelection={(index) => handleUserCountryChange(index)}
                        buttonStyleClass='width-203'
                        dropDownID='profileCountry'
                        disabled={countries?.length < 1}
                        search
                        singleSelect
                        customSearchText='Search country'
                    />
                    <CustomFilterDropdown
                        title='Role'
                        dropDownID='profileRole'
                        selectedName={role || 'Select Role'}
                        buttonStyleClass='width-203'
                        filterOptions={ROLES}
                        handleSelection={(index) => handleRoleChange(index)}
                        optionsSelected
                        singleSelect
                    />
                </div>
                <div className='container-btn'>
                    <EWButton plainTextButton={true} primary={false} onClick={onClose} buttonStyleClass='cancel-btn'>
                        Cancel
                    </EWButton>
                    <EWButton buttonText='Save' onClick={handleSave} loading={isLoading} disabled={count < 1} />
                </div>

                {deactivate && (
                    <DeactivateUserModal
                        profile={data}
                        setDeactivateUser={setDeactivate}
                        handleConfirm={handleConfirm}
                    />
                )}
            </div>
        </Modal>
    );
};

const mapStateToProps = ({ User }) => ({ adminId: User.adminId, userId: User._id });

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    platform: PropTypes.string,
};

LabelWithImage.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
};

export default Index;
