import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import { useToaster } from 'Context/SnackbarContext';
import Title from 'components/ReusableComponents/TitleBar';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import Table from 'components/ReusableComponents/Table';
import { GET_EMPLOYEES_WITHOUT_DATES } from 'Services/apiKeys';
import { getEmployeesWithoutDates, specialDateDM } from 'Services/apiFunctions';
import { downLoadFile } from 'Services/apiCall';
import download from 'downloadjs';
import { useQuery } from 'react-query';
import { ReactComponent as EllipsisIcon } from 'Assets/images/circle-small.svg';
import { ReactComponent as ExportIcon } from 'Assets/images/bx_export.svg';
import { ReactComponent as ReminderIcon } from 'Assets/images/notification-bell.svg';
import celebrationFilters from './constants';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { HISTORY_FROM } from 'constants.js';
import CustomCheckbox from 'components/Styles/CustomCheckbox';
import MemberTag from 'components/ReusableComponents/MemberTag';
import Tooltip from 'components/Styles/Tooltip';
import { debounce } from 'lodash';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import './styles.scss';

const EXPORT_FILE_NAME = 'EmployeesWithNoDates.csv';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [selectedOption, setSelectedOption] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedMembersId, setSelectedMembersId] = useState([]);
    const [search, setSearch] = useState('');
    const [isSendingReminder, setIsSendingReminder] = useState(false);
    const [isExportingValues, setIsExportingValues] = useState(false);

    const {
        data: filteredData,
        isLoading,
        isFetching,
    } = useQuery(
        [GET_EMPLOYEES_WITHOUT_DATES, currentPage, search, celebrationFilters[selectedOption].value],
        getEmployeesWithoutDates
    );

    const columnWidth = ['5%', '30%', '40%', '40%'];

    const data = filteredData?.map((user) => {
        const tempRowArray = [
            <span className='checkbox-update' key={user._id}>
                <CustomCheckbox checked={selectedMembersId.includes(user._id)} onClick={() => selectnAssign(user)} />
            </span>,
            <MemberTag
                history={history}
                key={user._id}
                memberID={user._id}
                memberName={user.userName}
                memberPhoto={user.pictureURL}
                navMenuCode={HISTORY_FROM.CELEBRATIONS_EMPLOYEES_WITHOUT_DATES}
            />,
            <p key={user._id} className='celebration-dates'>
                {user?.birthDate ? format(new Date(user.birthDate), 'dd/MM/yyyy') : 'N/A'}
            </p>,
            <p key={user._id} className='celebration-dates'>
                {user?.anniversaryDate ? format(new Date(user.anniversaryDate), 'dd/MM/yyyy') : 'N/A'}
            </p>,
        ];

        return { id: user._id, row: tempRowArray };
    });

    const paginationProps = {
        currentPage,
        totalCount: filteredData?.length || 0,
        setCurrentPage,
        onChange: (page) => setCurrentPage(page),
        pageItem: 'users',
    };
    const noSortColumns = [0];
    const handleSearchChange = debounce((value) => {
        setSearch(value);
    }, 300);

    const getDisplayText = (from) => {
        if (from != 'top-panel') {
            if (selectedMembersId.length === filteredData?.length) {
                return `All ( ${selectedMembersId.length} )`;
            }
            if (selectAll) {
                return `( ${filteredData?.length} )`;
            }
            return `( ${selectedMembersId.length} )`;
        } else {
            return `All ( ${filteredData?.length} )`;
        }
    };

    const selectnAssign = (member) => {
        const memberIndex = selectedMembersId.findIndex((selectedMember) => selectedMember === member._id);
        if (memberIndex > -1) {
            selectedMembersId.splice(memberIndex, 1);
        } else {
            selectedMembersId.push(member._id);
        }
        setSelectedMembersId([...selectedMembersId]);
    };

    const handleSelectAll = (from) => {
        if (from === 'bottom-panel') {
            const allMembersId = filteredData?.map((member) => member._id) || [];
            setSelectedMembersId([...allMembersId]);
        } else {
            if (!selectAll) {
                const allMembersId = filteredData?.map((member) => member._id) || [];
                setSelectedMembersId([...allMembersId]);
            } else {
                setSelectedMembersId([]);
            }
            setSelectAll(!selectAll);
        }
    };

    useEffect(() => {
        setSelectedMembersId([]);
    }, [selectedOption]);

    useEffect(() => {
        setSelectAll(selectedMembersId?.length > 0 && filteredData?.length === selectedMembersId?.length);
    }, [selectedMembersId]);

    const handleExport = async (from) => {
        setIsExportingValues(true);
        const apiData = {
            module: 'celebrations',
            selectAll: selectAll,
        };
        let dataToExport = [];
        if (from === 'top-panel') {
            dataToExport = filteredData?.map((member) => member?._id) || [];
            apiData['userIds'] = dataToExport;
        } else {
            apiData['userIds'] = selectedMembersId;
        }

        try {
            const downloadFileData = await downLoadFile('organization/users/export', 'post', apiData);
            download(downloadFileData?.data, EXPORT_FILE_NAME);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        } finally {
            setSelectAll(false);
            setSelectedMembersId([]);
            setIsExportingValues(false);
        }
    };

    const handleSendReminder = async (data, from) => {
        setIsSendingReminder(true);
        try {
            await specialDateDM(data);
            showSnackBarMessage(SetSnackbar, 'success', `DM sent to ${getDisplayText(from) || ''} users`);
        } catch (e) {
            showSnackBarMessage(SetSnackbar, 'error', e?.message);
        } finally {
            setSelectedMembersId([]);
            setSelectAll(false);
            setIsSendingReminder(false);
        }
    };

    const tableHeaders = [
        <span className='checkbox-update' key={1}>
            <CustomCheckbox checked={selectAll} onClick={handleSelectAll} />
        </span>,
        'Employee',
        'Birthday',
        'Work Anniversary',
    ];

    return (
        <div className=' celebration-main-container'>
            <Title title='Employees with missing dates' back={true} onBack={() => history.goBack()}>
                <EWButton buttonStyleClass='add-dates-btn' onClick={() => history.push('/dashboard/users')}>
                    Add Dates
                </EWButton>
            </Title>
            <div className=' employees-with-missing-dates-conatiner'>
                {(isExportingValues || isSendingReminder) && (
                    <div className=' overlay-loading'>
                        <CustomLoader />
                    </div>
                )}
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='celebrations-top-bar'>
                        <CustomFilterDropdown
                            title='CELEBRATION TYPE'
                            filterOptions={celebrationFilters}
                            optionsSelected
                            selectedName={celebrationFilters[selectedOption].label}
                            handleSelection={(index) => {
                                setSelectedOption(index);
                            }}
                            dropDownID='celebrations'
                            singleSelect
                            dropdownWidth={188}
                            buttonStyleClass='celebrations-filter-dropdown'
                        />
                        <button className='celebrations-cta-btns' onClick={() => handleExport('top-panel')}>
                            <ExportIcon height={'24px'} width={'24px'} />
                            Export List
                        </button>
                        <EllipsisIcon height={'6px'} width={'6px'} />
                        <button
                            className='celebrations-cta-btns'
                            onClick={() => {
                                const tempArray = filteredData?.map((member) => member?._id) || [];
                                handleSendReminder(tempArray, 'top-panel');
                            }}
                        >
                            <ReminderIcon />
                            Send Reminder
                        </button>
                    </div>
                    <CustomSearchBox
                        width='300px'
                        placeholder='Search'
                        handleSearch={(event) => handleSearchChange(event.target.value)}
                    />
                </div>
                <div className='table-container'>
                    {filteredData?.length < 1 && isLoading ? (
                        <CustomLoader />
                    ) : (
                        <Table
                            className='employees-with-missing-dates-table'
                            header={tableHeaders}
                            data={data}
                            noSortColumns={noSortColumns}
                            paginationProps={paginationProps}
                            loading={isLoading || isFetching}
                            columnWidth={columnWidth}
                        />
                    )}
                </div>
            </div>
            {selectedMembersId.length > 0 && (
                <div className='assign-button-container'>
                    <div className='d-flex align-items-center selection-info-container'>
                        <p className='selection-info'>
                            <span>{getDisplayText()}</span> users selected
                        </p>
                        <EllipsisIcon />
                        <EWButton
                            primary={false}
                            plainTextButton={true}
                            onClick={() => handleSelectAll('bottom-panel')}
                        >
                            Select all {filteredData?.length} Users
                        </EWButton>
                    </div>
                    <div className='d-flex  '>
                        <Tooltip title='Export the selected entries in CSV Format'>
                            <EWButton
                                buttonStyleClass='mr-2 pointer-cursor'
                                primary={false}
                                plainTextButton={true}
                                disabled={isExportingValues}
                                onClick={() => handleExport()}
                            >
                                <ExportIcon />
                            </EWButton>
                        </Tooltip>
                        <button
                            className='ew-btn ob-btn mr-3 d-flex align-items-center send-reminder-btn'
                            disabled={selectedMembersId.length < 1 || isSendingReminder}
                            onClick={() => handleSendReminder(selectedMembersId)}
                        >
                            <ReminderIcon />
                            Remind on Slack DM
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
