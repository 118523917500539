import { CARD_LOGS } from '../../PaymentNew/constants';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardSection } from './styles';
import CustomRadio from 'components/Styles/CustomRadioButton';
import { CARDVIEW_TYPES, handlePlanAction } from '../constants';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import { capitalize } from 'lodash';
import { BeatLoader } from 'react-spinners';

export const SingleCard = ({ cardView, cardDetails, handleChangeCard, selectedIndex }) => {
    // only one card to show
    if (cardView === CARDVIEW_TYPES.DETAILED) {
        return (
            <div>
                <img src={CARD_LOGS[cardDetails[0].brand]} alt={cardDetails[0].brand} />
                <span>
                    **** <b>{cardDetails[0].last4}</b>
                </span>
                <span>PRIMARY CARD</span>
            </div>
        );
    }

    if (cardView === CARDVIEW_TYPES.LEAN && cardDetails.length === 1) {
        return (
            <span>
                Credit card ending with &nbsp;
                <img src={CARD_LOGS[cardDetails[0].brand]} alt={cardDetails[0].brand} width='20' />
                &nbsp;
                <b>{cardDetails[0].last4}</b>
            </span>
        );
    }

    if (cardView === CARDVIEW_TYPES.LEAN && cardDetails.length > 1) {
        return cardDetails.map((card, index) => (
            <CustomRadio
                key={card.paymentMethodId}
                name='headerradio'
                title={
                    <div className='single-cardView' key={card.paymentMethodId}>
                        <span>{capitalize(card.funding)} card ending with &nbsp;</span>
                        <img src={CARD_LOGS[card.brand]} width='26' height='16' alt={card.brand} />
                        <span className='card-last4'>&nbsp;{card.last4}</span>
                    </div>
                }
                checked={selectedIndex === index}
                onChange={() => handleChangeCard(index)}
            />
        ));
    }
    return null;
};

export const CardInfo = ({
    cardDetails,
    cardView = CARDVIEW_TYPES.LEAN,
    handleChangeCard,
    isloading,
    selectedIndex,
    team,
}) => {
    const [loading, setLoading] = useState(false);
    const handleOnCLick = () => handlePlanAction(setLoading, team);

    if (isloading) {
        return (
            <CardSection>
                <p> Payment method </p>
                Loading
            </CardSection>
        );
    }

    if (!cardDetails || !cardDetails?.[0]?.last4) {
        return (
            <CardSection>
                <p> Payment method </p>
                <div className='cardView'>xxxx xxxx xxxx</div>
                <div className='floatingButton'>
                    <button className='ew-btn ob-btn ew-btn-slim' onClick={handleOnCLick}>
                        {loading ? <BeatLoader color='#5D5CF5' size={10} loading /> : 'Add Card'}
                    </button>
                </div>
            </CardSection>
        );
    }

    return (
        <CardSection>
            <p> Payment method </p>
            <div className='cardView'>
                <SingleCard
                    cardView={cardView}
                    cardDetails={cardDetails}
                    handleChangeCard={handleChangeCard}
                    selectedIndex={selectedIndex}
                />
            </div>

            <div className='floatingButton' hidden={cardView === CARDVIEW_TYPES.LEAN && cardDetails.length > 1}>
                <button
                    className='ew-btn ob-btn ew-btn-slim'
                    onClick={() => handlePlanAction(setLoading, team)}
                    disabled={loading}
                >
                    {loading ? <BeatLoader color='#5D5CF5' size={10} loading /> : 'Change Card'}
                </button>
            </div>
        </CardSection>
    );
};

export const PlanToolTip = (
    <>
        <InfoIcon style={{ width: 16, height: 16 }} data-tip data-for='info-icon' />
        <ReactTooltip
            id='info-icon'
            place='right'
            effect='solid'
            backgroundColor='#05060a'
            textColor='#FFFFFF'
            clickable
            multiline
        >
            The Basic plan supports up to 35 active
            <br />
            users. To activate more than 35 users
            <br />
            in EngageWith, upgrade to the Pro plan.
        </ReactTooltip>
    </>
);

SingleCard.propTypes = {
    cardView: PropTypes.string,
    cardDetails: PropTypes.array,
    handleChangeCard: PropTypes.func,
    selectedIndex: PropTypes.number,
};

CardInfo.propTypes = {
    cardDetails: PropTypes.array,
    cardView: PropTypes.array,
    handleChangeCard: PropTypes.func,
    isloading: PropTypes.bool,
    selectedIndex: PropTypes.number,
    team: PropTypes.object,
};
