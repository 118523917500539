import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ buttonText, fileName, acceptedFiles, handleFileSelection, handleRemoveFile }) => {
    return (
        <div className='container-file-selection'>
            {fileName && (
                <div>
                    {fileName.length > 25 ? `${fileName.slice(0, 22)}...` : fileName}
                    <CloseIcon
                        data-testid='remove-file-icon'
                        className='remove-file-icon'
                        onClick={(event) => {
                            event.stopPropagation();
                            handleRemoveFile();
                        }}
                    />
                </div>
            )}

            {!fileName && (
                <>
                    <CloudUploadIcon />
                    <span>{buttonText || 'Import File'}</span>
                </>
            )}
            <input
                data-testid='file-input'
                type='file'
                accept={acceptedFiles}
                onChange={(event) => {
                    handleFileSelection(event);
                }}
            />
        </div>
    );
};

Index.propTypes = {
    buttonText: PropTypes.string,
    fileName: PropTypes.string,
    acceptedFiles: PropTypes.string,
    handleFileSelection: PropTypes.func,
    handleRemoveFile: PropTypes.func,
};

export default Index;
