import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import './style.scss';
import slack from 'Assets/images/slack.svg';
import teams from 'Assets/images/ms-teams.svg';
import clsx from 'clsx';
import { isSlack } from 'utils/HelperFunctions';

const Index = ({ history }) => {
    localStorage.clear();
    Cookies.remove('AuthToken');
    const { visitor, platform } = queryString.parse(history.location.search);
    const isSlackWindow = isSlack(platform);
    return (
        <div className='login-screen text-center'>
            <div className='login-content-container'>
                <img className='ew-logo-login' src={require('Assets/images/Logo.svg')} alt='logo' />
                <h5>{visitor && 'App Installed. '}Log in to continue</h5>
                <div className='WrapperCTA'>
                    {!history.location.search || isSlackWindow ? (
                        <button
                            className={clsx({ 'mb-5': isSlackWindow, 'mt-4': isSlackWindow })}
                            onClick={() => window.open(process.env.REACT_APP_SLACK_URL, '_self')}
                        >
                            <img src={slack} alt={'slack'} /> Sign in with Slack
                        </button>
                    ) : null}
                    <br />
                    {!history.location.search || !isSlackWindow ? (
                        <button
                            className={clsx({ 'mb-5': !isSlackWindow })}
                            onClick={() => window.open(process.env.REACT_APP_MS_TEAMS_URL, '_self')}
                        >
                            <img src={teams} alt={'microsoft teams'} />I use Teams
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
