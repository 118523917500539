import React, { useState } from 'react';
import { differenceInDays } from 'date-fns';
import CustomRadioButton from 'components/Styles/CustomRadioButton';
import CohortDropdown from '../CohortDropdown';
import AnalyticsDateRangeSelector from '../AnalyticsDateRangeSelector';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import { ANALYTICS_DATE_RANGE } from 'constants.js';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getLastQuarterDates,
    getLastMonthDates,
    getLastYearDates,
    getQuarterToDate,
    getYearToDate,
    getLastNDays,
    getDefaultDateRange,
    getAllTime,
} from 'utils/HelperFunctions';
import './style.scss';

const Index = ({
    cohortData,
    handleCohortSelection,
    selectedCohort,
    selectedDateRange,
    setSelectedDateRange,
    setView,
    blockSelection,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { workspaceCreatedAt } = useSelector(mapStateToProps, shallowEqual);

    const handleDateRange = (index) => {
        const title = ANALYTICS_DATE_RANGE[index];
        switch (index) {
            case 0:
                setDateRange(index, title, { ...getLastNDays(7) });
                break;
            case 1:
                setDateRange(index, title, { ...getLastNDays(30) });
                break;
            case 2:
                setDateRange(index, title, { ...getLastQuarterDates() });
                break;
            case 3:
                setDateRange(index, title, { ...getLastMonthDates() });
                break;
            case 4:
                setDateRange(index, title, { ...getLastYearDates() });
                break;
            case 5:
                setDateRange(index, title, { ...getLastNDays(new Date().getDate() - 1) });
                break;
            case 6:
                setDateRange(index, title, { ...getQuarterToDate() });
                break;
            case 7:
                setDateRange(index, title, { ...getYearToDate() });
                break;
            case 8:
                setDateRange(index, title, { ...getAllTime(workspaceCreatedAt) });
                break;
            default:
                setSelectedDateRange(getDefaultDateRange());
        }
    };

    const setDateRange = (index, title, { startDate, endDate }) => {
        setSelectedDateRange({ index, title, startDate, endDate });
        // the same logic is used in the backend to set the view
        const dayDiff = differenceInDays(endDate, startDate);
        if (dayDiff > 31 && dayDiff < 92) {
            setView('weekly');
        } else if (dayDiff > 91 && dayDiff < 366) {
            setView('monthly');
        } else if (dayDiff > 366) {
            setView('yearly');
        }
        setDropdownOpen(false);
    };

    const onCustomDateRangeSelection = (value) => setDateRange(-1, 'Custom dates', { ...value });

    const DateRangeDropDown = ({ daterangeOptions, selectedIndex, handleSelection }) => {
        return daterangeOptions.map((item, index) => (
            <div className='date-range-dropdown-item' key={item}>
                <CustomRadioButton
                    checked={selectedIndex === index}
                    title={item}
                    name={`cr${index}`}
                    size={24}
                    onChange={() => handleSelection(index)}
                />
            </div>
        ));
    };

    return (
        <div className='analytics-filters-container'>
            <CohortDropdown
                cohortData={cohortData}
                selectedCohort={selectedCohort}
                handleCohortSelection={handleCohortSelection}
                blockSelection={blockSelection}
            />
            <CustomDropdown
                title='Date Range'
                selectedName={selectedDateRange.title}
                buttonWidth={180}
                dropdownWidth={232}
                dropdownHeight={365}
                buttonStyleClass={'date-range-dropdown'}
                open={dropdownOpen}
                setOpen={setDropdownOpen}
            >
                <DateRangeDropDown
                    daterangeOptions={ANALYTICS_DATE_RANGE}
                    handleSelection={handleDateRange}
                    selectedIndex={selectedDateRange.index}
                />
            </CustomDropdown>
            <AnalyticsDateRangeSelector selectedDateRange={selectedDateRange} onConfirm={onCustomDateRangeSelection} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    workspaceCreatedAt: state.Workspace.workspaceCreatedAt,
});

Index.propTypes = {
    cohortData: PropTypes.array,
    handleCohortSelection: PropTypes.func,
    selectedCohort: PropTypes.object,
    selectedDateRange: PropTypes.object,
    setSelectedDateRange: PropTypes.func,
    setView: PropTypes.func,
    blockSelection: PropTypes.bool,
};

export default Index;
