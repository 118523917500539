import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { useQuery } from 'react-query';
import { GET_MISSING_SPECIAL_DATE_USER_COUNT } from 'Services/apiKeys';
import { getMissingSpecialDateUserCount, specialDateDM } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import UpdateMissingInfoModal from './UpdateMissingInfoModal';
import Banner from './Banner';
import EWButton from 'components/ReusableComponents/EWButton';
import CelebrationSettings from './CelebrationSettings';

const CELEBRATION_IMAGE =
    'https://assets-springengage.s3.amazonaws.com/production/icons/celebrations-people-grouping.svg';

const Heading = ({ missingDateCount, history }) => {
    return (
        <p>
            <EWButton
                plainTextButton={true}
                primary={false}
                buttonStyleClass='missing-date-btn'
                onClick={() => history.push('/dashboard/celebration/employees-without-dates')}
            >
                {missingDateCount} employees
            </EWButton>{' '}
            do not have their birthdays and work anniversary dates added.
        </p>
    );
};

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [missingDateCount, setMissingDateCount] = useState(0);
    const [openUpdateMissingInfoModal, setOpenUpdateMissingInfoModal] = useState(false);

    const openSnackbar = (variant, message) => {
        SetSnackbar({ open: true, variant, message: message || 'Some error occurred' });
    };

    useQuery([GET_MISSING_SPECIAL_DATE_USER_COUNT], getMissingSpecialDateUserCount, {
        onSuccess: (count) => setMissingDateCount(count),
        onError: (err) => openSnackbar('error', err?.message),
    });

    const handleSendDM = async () => {
        try {
            await specialDateDM();
            openSnackbar('success', `DM sent to ${missingDateCount || ''} users`);
        } catch (e) {
            openSnackbar('error', e?.message || 'Some error occurred');
        } finally {
            setOpenUpdateMissingInfoModal(false);
        }
    };

    const handleManualUpdate = () => history.push(`/dashboard/users`);

    return (
        <div className='celebration-container'>
            <TitleBar title='Celebrations' />
            {missingDateCount > 0 && (
                <Banner
                    heading={<Heading missingDateCount={missingDateCount} history={history} />}
                    subHeading={'Add dates so your employees get gifts on their special days'}
                    buttonText='Add Dates'
                    handleClick={() => setOpenUpdateMissingInfoModal(true)}
                    image={<img src={CELEBRATION_IMAGE} alt='People grouping together' />}
                />
            )}
            <CelebrationSettings history={history} />
            {openUpdateMissingInfoModal && (
                <UpdateMissingInfoModal
                    open={openUpdateMissingInfoModal}
                    setOpen={setOpenUpdateMissingInfoModal}
                    handleManualUpdate={handleManualUpdate}
                    handleSendDM={handleSendDM}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

Heading.propTypes = {
    missingDateCount: PropTypes.number,
    history: PropTypes.object,
};

export default Index;
