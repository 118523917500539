import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { ReactComponent as Warning } from 'Assets/images/warning-red.svg';

const Index = ({ errorText, className = '' }) => {
    return (
        <div className={`error-field ${className}`}>
            <div className='d-flex align-items-center'>
                <Warning />
            </div>
            <div className='error-text'>{errorText}</div>
        </div>
    );
};

Index.propTypes = {
    errorText: PropTypes.string,
    className: PropTypes.string,
};

export default Index;
