import { isSlack } from 'utils/HelperFunctions';

export const VALUES_LENGTH_LIMIT = 75;
export const FEEDBACK_TEXT_LENGTH_LIMIT = 2900;
export const BASE_URL_S3 = 'https://assets-springengage.s3.us-east-1.amazonaws.com/production/icons/';
export const EW_LOGO_URL = 'https://springengage-develop.s3.amazonaws.com/static/media/Engagewith+Logo.jpg';

export const awardFrequency = [
    { label: 'One time', value: 'onetime' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
];

export const binary = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
];

export const dateRanges = [
    {
        value: '',
        label: 'All',
    },
    {
        value: 'today',
        label: 'Today',
    },
    {
        value: 'last7',
        label: 'Last 7 Days',
    },
    {
        value: 'last30',
        label: 'Last 30 Days',
    },
    {
        value: 'last60',
        label: 'Last 60 Days',
    },
    {
        value: 'last90',
        label: 'Last 90 Days',
    },
    {
        value: 'Custom Date',
        label: 'Custom Date',
    },
];

const questionTypes = [
    { label: 'Short text', value: 'SHORT_TEXT' },
    { label: 'Long text', value: 'LONG_TEXT' },
    { label: 'Yes/No', value: 'BINARY' },
    { label: 'Rating 1-5', value: 'RATING_5' },
    { label: 'Rating 1-10', value: 'RATING' },
    { label: 'Agree/Disagree', value: 'LIKERT' },
];

export const pulseQuestionTypes = [{ label: 'Multiple choice', value: 'MCQ' }, ...questionTypes];

export const pulseQuestionTypesNHO = [{ label: 'Multiple choice', value: 'MULTIPLE_CHOICE' }, ...questionTypes];

export const ratingScale = [
    { label: '1-5', value: '1to5' },
    { label: '1-10', value: '1to10' },
    { label: 'Likert scale', value: 'likert' },
];

export const likertScale = ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'];

export const daysOfWeek = [
    { label: 'Monday', value: 'monday', day: 1 },
    { label: 'Tuesday', value: 'tuesday', day: 2 },
    { label: 'Wednesday', value: 'wednesday', day: 3 },
    { label: 'Thursday', value: 'thursday', day: 4 },
    { label: 'Friday', value: 'friday', day: 5 },
    { label: 'Saturday', value: 'saturday', day: 6 },
    { label: 'Sunday', value: 'sunday', day: 7 },
];

export const recurringFrequency = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
];

export const PULSE_FREQUENCY = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    QUARTERLY: 'quarterly',
    SEMIANNUALLY: 'semi-annually',
};

export const frequencyType = [
    { label: 'One-Time', value: 'one-time', days: 0 },
    { label: 'Weekly', value: 'weekly', days: 7 },
    { label: 'Monthly', value: 'monthly', days: 30 },
    { label: 'Quarterly', value: 'quarterly', days: 90 },
];

export const futureEditFrequencyType = [
    { label: 'Weekly', value: 'weekly', days: 7 },
    { label: 'Monthly', value: 'monthly', days: 30 },
    { label: 'Quarterly', value: 'quarterly', days: 90 },
];

export const eNPSFrequency = [
    { label: 'One-Time', value: 'one-time', days: 0 },
    { label: 'Monthly', value: 'monthly', days: 30 },
    { label: 'Quarterly', value: 'quarterly', days: 90 },
];

export const reminderFrequency = [
    { label: '3 hours before', value: 3, unit: 'hours', isChecked: false },
    { label: '6 hours before', value: 6, unit: 'hours', isChecked: false },
    { label: '12 hours before', value: 12, unit: 'hours', isChecked: false },
    { label: '1 day before', value: 1, unit: 'days', isChecked: false },
    { label: '2 day before', value: 2, unit: 'days', isChecked: false },
    { label: '3 day before', value: 3, unit: 'days', isChecked: false },
    { label: '4 day before', value: 4, unit: 'days', isChecked: false },
    { label: '5 day before', value: 5, unit: 'days', isChecked: false },
    { label: '10 days before', value: 10, unit: 'days', isChecked: false },
];

export const timeUnits = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

export const getUserBulkUploadSampleFile = (platform) =>
    isSlack(platform)
        ? 'https://assets-springengage.s3.us-east-1.amazonaws.com/production/feature-samples/sample_user_bulk_upload_latest.csv'
        : 'https://assets-springengage.s3.us-east-1.amazonaws.com/production/feature-samples/sample_user_bulk_upload_msteams_latest.csv';

// Last 7 days, Last 30 days, Last quarter, Last month, Last year, Month to date, Quarter to date, Year to date,  Custom
export const ANALYTICS_DATE_RANGE = [
    'Last 7 days',
    'Last 30 days',
    'Last quarter',
    'Last month',
    'Last year',
    'Month to date',
    'Quarter to date',
    'Year to date',
    'All time',
];

export const ANALYTICS_GROUPBY = { RECOGNITIONS: 'recognition', REDEMPTIONS: 'redemption' };

export const ANALYTICS_VIEWS = ['weekly', 'monthly', 'yearly'];

export const COHORT_TYPE = {
    WORKSPACE: 'workspace',
    DEPARTMENT: 'department',
    MANAGER: 'manager',
};

export const HISTORY_FROM = {
    RECOGNITIONS: 'RECOGNITIONS',
    CELEBRATIONS: 'CELEBRATIONS',
    ORG: 'ORGANIZATION',
    ANALYTICS: 'ANALYTICS',
    REDEMPTIONS: 'REDEMPTIONS',
    LEADERBOARD: 'LEADERBOARD',
    PULSE: 'PULSE',
    ALL_ACTIVITIES: 'ALL_ACTIVITIES',
    MY_ACTIVITIES: 'MY_ACTIVITIES',
    EMPLOYEE_AWARDS: 'EMPLOYEE_AWARDS',
    FEEDBACK: 'FEEDBACK',
    SUGGESTION_BOX: 'SUGGESTION_BOX',
    TASKS: 'TASKS',
    ACTIVITIES: 'ACTIVITIES',
    CELEBRATIONS_EMPLOYEES_WITHOUT_DATES: 'CELEBRATIONS_EMPLOYEES_WITHOUT_DATES',
};

export const demoCallURL = 'https://calendly.com/kanisha-parikh/30min-1';

export const countryMap = {
    Afghanistan: 'AF',
    Albania: 'AL',
    Algeria: 'DZ',
    Andorra: 'AD',
    Angola: 'AO',
    Anguilla: 'AI',
    Antarctica: 'AQ',
    'Antigua and Barbuda': 'AG',
    Argentina: 'AR',
    Armenia: 'AM',
    Aruba: 'AW',
    Australia: 'AU',
    Austria: 'AT',
    Azerbaijan: 'AZ',
    Bahamas: 'BS',
    Bahrain: 'BH',
    Bangladesh: 'BD',
    Barbados: 'BB',
    Belarus: 'BY',
    Belgium: 'BE',
    Belize: 'BZ',
    Benin: 'BJ',
    Bermuda: 'BM',
    Bhutan: 'BT',
    Bolivia: 'BO',
    'Bosnia and Herzegovina': 'BA',
    Botswana: 'BW',
    'Bouvet Island': 'BV',
    Brazil: 'BR',
    'British Indian Ocean Territory': 'IO',
    Brunei: 'BN',
    Bulgaria: 'BG',
    'Burkina Faso': 'BF',
    Burundi: 'BI',
    Cambodia: 'KH',
    Cameroon: 'CM',
    Canada: 'CA',
    'Cape Verde': 'CV',
    'Cayman Islands': 'KY',
    'Central African Republic': 'CF',
    Chad: 'TD',
    Chile: 'CL',
    China: 'CN',
    'Christmas Island': 'CX',
    'Cocos (Keeling) Islands': 'CC',
    Colombia: 'CO',
    Comoros: 'KM',
    Congo: 'CG',
    'Cook Islands': 'CK',
    'Costa Rica': 'CR',
    Croatia: 'HR',
    Cuba: 'CU',
    Cyprus: 'CY',
    'Czech Republic': 'CZ',
    Denmark: 'DK',
    Djibouti: 'DJ',
    Dominica: 'DM',
    'Dominican Republic': 'DO',
    'East Timor': 'TP',
    Ecuador: 'EC',
    Egypt: 'EG',
    'El Salvador': 'SV',
    'Equatorial Guinea': 'GQ',
    Eritrea: 'ER',
    Estonia: 'EE',
    Ethiopia: 'ET',
    'Falkland Islands': 'FK',
    'Faroe Islands': 'FO',
    'Fiji Islands': 'FJ',
    Finland: 'FI',
    France: 'FR',
    'French Guiana': 'GF',
    'French Polynesia': 'PF',
    'French Southern territories': 'TF',
    Gabon: 'GA',
    Gambia: 'GM',
    Georgia: 'GE',
    Germany: 'DE',
    Ghana: 'GH',
    Gibraltar: 'GI',
    Greece: 'GR',
    Greenland: 'GL',
    Grenada: 'GD',
    Guadeloupe: 'GP',
    Guam: 'GU',
    Guatemala: 'GT',
    Guernsey: 'GG',
    Guinea: 'GN',
    'Guinea-Bissau': 'GW',
    Guyana: 'GY',
    Haiti: 'HT',
    'Heard Island and McDonald Islands': 'HM',
    'Holy See (Vatican City State)': 'VA',
    Honduras: 'HN',
    'Hong Kong': 'HK',
    Hungary: 'HU',
    Iceland: 'IS',
    India: 'IN',
    Indonesia: 'ID',
    Iran: 'IR',
    Iraq: 'IQ',
    Ireland: 'IE',
    'Isle of Man': 'IM',
    Israel: 'IL',
    Italy: 'IT',
    'Ivory Coast': 'CI',
    Jamaica: 'JM',
    Japan: 'JP',
    Jersey: 'JE',
    Jordan: 'JO',
    Kazakhstan: 'KZ',
    Kenya: 'KE',
    Kiribati: 'KI',
    Kuwait: 'KW',
    Kyrgyzstan: 'KG',
    Laos: 'LA',
    Latvia: 'LV',
    Lebanon: 'LB',
    Lesotho: 'LS',
    Liberia: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Macao: 'MO',
    'North Macedonia': 'MK',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaysia: 'MY',
    Maldives: 'MV',
    Mali: 'ML',
    Malta: 'MT',
    'Marshall Islands': 'MH',
    Martinique: 'MQ',
    Mauritania: 'MR',
    Mauritius: 'MU',
    Mayotte: 'YT',
    Mexico: 'MX',
    'Micronesia, Federated States of': 'FM',
    Moldova: 'MD',
    Monaco: 'MC',
    Mongolia: 'MN',
    Montenegro: 'ME',
    Montserrat: 'MS',
    Morocco: 'MA',
    Mozambique: 'MZ',
    Myanmar: 'MM',
    Namibia: 'NA',
    Nauru: 'NR',
    Nepal: 'NP',
    Netherlands: 'NL',
    'Netherlands Antilles': 'AN',
    'New Caledonia': 'NC',
    'New Zealand': 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigeria: 'NG',
    Niue: 'NU',
    'Norfolk Island': 'NF',
    'North Korea': 'KP',
    'Northern Ireland': 'GB',
    'Northern Mariana Islands': 'MP',
    Norway: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palau: 'PW',
    Palestine: 'PS',
    Panama: 'PA',
    'Papua New Guinea': 'PG',
    Paraguay: 'PY',
    Peru: 'PE',
    Philippines: 'PH',
    Pitcairn: 'PN',
    Poland: 'PL',
    Portugal: 'PT',
    'Puerto Rico': 'PR',
    Qatar: 'QA',
    Romania: 'RO',
    'Russian Federation': 'RU',
    Rwanda: 'RW',
    'Saint Helena': 'SH',
    'Saint Kitts and Nevis': 'KN',
    'Saint Lucia': 'LC',
    'Saint Pierre and Miquelon': 'PM',
    'Saint Vincent and the Grenadines': 'VC',
    Samoa: 'WS',
    'San Marino': 'SM',
    'Sao Tome and Principe': 'ST',
    'Saudi Arabia': 'SA',
    Senegal: 'SN',
    Serbia: 'RS',
    Seychelles: 'SC',
    'Sierra Leone': 'SL',
    Singapore: 'SG',
    Slovakia: 'SK',
    Slovenia: 'SI',
    'Solomon Islands': 'SB',
    Somalia: 'SO',
    'South Africa': 'ZA',
    'South Georgia and the South Sandwich Islands': 'GS',
    'South Korea': 'KR',
    'South Sudan': 'SS',
    Spain: 'ES',
    'Sri Lanka': 'LK',
    Sudan: 'SD',
    Suriname: 'SR',
    'Svalbard and Jan Mayen': 'SJ',
    Swaziland: 'SZ',
    Sweden: 'SE',
    Switzerland: 'CH',
    Syria: 'SY',
    Tajikistan: 'TJ',
    Tanzania: 'TZ',
    Thailand: 'TH',
    'The Democratic Republic of Congo': 'CD',
    'Timor-Leste': 'TL',
    Taiwan: 'TW',
    Togo: 'TG',
    Tokelau: 'TK',
    Tonga: 'TO',
    'Trinidad and Tobago': 'TT',
    Tunisia: 'TN',
    Turkey: 'TR',
    Turkmenistan: 'TM',
    'Turks and Caicos Islands': 'TC',
    Tuvalu: 'TV',
    Uganda: 'UG',
    Ukraine: 'UA',
    'United Arab Emirates': 'AE',
    'United Kingdom': 'UK',
    'United States': 'US',
    'United States of America': 'USA',
    USA: 'USA',
    UK: 'UK',
    'United States Minor Outlying Islands': 'UM',
    Uruguay: 'UY',
    Uzbekistan: 'UZ',
    Vanuatu: 'VU',
    Venezuela: 'VE',
    Vietnam: 'VN',
    'Virgin Islands, British': 'VG',
    'Virgin Islands, U.S.': 'VI',
    'Wallis and Futuna': 'WF',
    'Western Sahara': 'EH',
    Yemen: 'YE',
    Zambia: 'ZM',
    Zimbabwe: 'ZW',
};

export const timezones = [
    {
        id: 1,
        countryCode: 'AD',
        timezone: 'Europe/Andorra',
    },
    {
        id: 2,
        countryCode: 'AE',
        timezone: 'Asia/Dubai',
    },
    {
        id: 3,
        countryCode: 'AF',
        timezone: 'Asia/Kabul',
    },
    {
        id: 4,
        countryCode: 'AG',
        timezone: 'America/Antigua',
    },
    {
        id: 5,
        countryCode: 'AI',
        timezone: 'America/Anguilla',
    },
    {
        id: 6,
        countryCode: 'AL',
        timezone: 'Europe/Tirane',
    },
    {
        id: 7,
        countryCode: 'AM',
        timezone: 'Asia/Yerevan',
    },
    {
        id: 8,
        countryCode: 'AO',
        timezone: 'Africa/Luanda',
    },
    {
        id: 9,
        countryCode: 'AQ',
        timezone: 'Antarctica/McMurdo',
    },
    {
        id: 10,
        countryCode: 'AQ',
        timezone: 'Antarctica/Casey',
    },
    {
        id: 11,
        countryCode: 'AQ',
        timezone: 'Antarctica/Davis',
    },
    {
        id: 12,
        countryCode: 'AQ',
        timezone: 'Antarctica/DumontDUrville',
    },
    {
        id: 13,
        countryCode: 'AQ',
        timezone: 'Antarctica/Mawson',
    },
    {
        id: 14,
        countryCode: 'AQ',
        timezone: 'Antarctica/Palmer',
    },
    {
        id: 15,
        countryCode: 'AQ',
        timezone: 'Antarctica/Rothera',
    },
    {
        id: 16,
        countryCode: 'AQ',
        timezone: 'Antarctica/Syowa',
    },
    {
        id: 17,
        countryCode: 'AQ',
        timezone: 'Antarctica/Troll',
    },
    {
        id: 18,
        countryCode: 'AQ',
        timezone: 'Antarctica/Vostok',
    },
    {
        id: 19,
        countryCode: 'AR',
        timezone: 'America/Argentina/Buenos_Aires',
    },
    {
        id: 20,
        countryCode: 'AR',
        timezone: 'America/Argentina/Cordoba',
    },
    {
        id: 21,
        countryCode: 'AR',
        timezone: 'America/Argentina/Salta',
    },
    {
        id: 22,
        countryCode: 'AR',
        timezone: 'America/Argentina/Jujuy',
    },
    {
        id: 23,
        countryCode: 'AR',
        timezone: 'America/Argentina/Tucuman',
    },
    {
        id: 24,
        countryCode: 'AR',
        timezone: 'America/Argentina/Catamarca',
    },
    {
        id: 25,
        countryCode: 'AR',
        timezone: 'America/Argentina/La_Rioja',
    },
    {
        id: 26,
        countryCode: 'AR',
        timezone: 'America/Argentina/San_Juan',
    },
    {
        id: 27,
        countryCode: 'AR',
        timezone: 'America/Argentina/Mendoza',
    },
    {
        id: 28,
        countryCode: 'AR',
        timezone: 'America/Argentina/San_Luis',
    },
    {
        id: 29,
        countryCode: 'AR',
        timezone: 'America/Argentina/Rio_Gallegos',
    },
    {
        id: 30,
        countryCode: 'AR',
        timezone: 'America/Argentina/Ushuaia',
    },
    {
        id: 31,
        countryCode: 'AS',
        timezone: 'Pacific/Pago_Pago',
    },
    {
        id: 32,
        countryCode: 'AT',
        timezone: 'Europe/Vienna',
    },
    {
        id: 33,
        countryCode: 'AU',
        timezone: 'Australia/Lord_Howe',
    },
    {
        id: 34,
        countryCode: 'AU',
        timezone: 'Antarctica/Macquarie',
    },
    {
        id: 35,
        countryCode: 'AU',
        timezone: 'Australia/Hobart',
    },
    {
        id: 36,
        countryCode: 'AU',
        timezone: 'Australia/Currie',
    },
    {
        id: 37,
        countryCode: 'AU',
        timezone: 'Australia/Melbourne',
    },
    {
        id: 38,
        countryCode: 'AU',
        timezone: 'Australia/Sydney',
    },
    {
        id: 39,
        countryCode: 'AU',
        timezone: 'Australia/Broken_Hill',
    },
    {
        id: 40,
        countryCode: 'AU',
        timezone: 'Australia/Brisbane',
    },
    {
        id: 41,
        countryCode: 'AU',
        timezone: 'Australia/Lindeman',
    },
    {
        id: 42,
        countryCode: 'AU',
        timezone: 'Australia/Adelaide',
    },
    {
        id: 43,
        countryCode: 'AU',
        timezone: 'Australia/Darwin',
    },
    {
        id: 44,
        countryCode: 'AU',
        timezone: 'Australia/Perth',
    },
    {
        id: 45,
        countryCode: 'AU',
        timezone: 'Australia/Eucla',
    },
    {
        id: 46,
        countryCode: 'AW',
        timezone: 'America/Aruba',
    },
    {
        id: 47,
        countryCode: 'AX',
        timezone: 'Europe/Mariehamn',
    },
    {
        id: 48,
        countryCode: 'AZ',
        timezone: 'Asia/Baku',
    },
    {
        id: 49,
        countryCode: 'BA',
        timezone: 'Europe/Sarajevo',
    },
    {
        id: 50,
        countryCode: 'BB',
        timezone: 'America/Barbados',
    },
    {
        id: 51,
        countryCode: 'BD',
        timezone: 'Asia/Dhaka',
    },
    {
        id: 52,
        countryCode: 'BE',
        timezone: 'Europe/Brussels',
    },
    {
        id: 53,
        countryCode: 'BF',
        timezone: 'Africa/Ouagadougou',
    },
    {
        id: 54,
        countryCode: 'BG',
        timezone: 'Europe/Sofia',
    },
    {
        id: 55,
        countryCode: 'BH',
        timezone: 'Asia/Bahrain',
    },
    {
        id: 56,
        countryCode: 'BI',
        timezone: 'Africa/Bujumbura',
    },
    {
        id: 57,
        countryCode: 'BJ',
        timezone: 'Africa/Porto-Novo',
    },
    {
        id: 58,
        countryCode: 'BL',
        timezone: 'America/St_Barthelemy',
    },
    {
        id: 59,
        countryCode: 'BM',
        timezone: 'Atlantic/Bermuda',
    },
    {
        id: 60,
        countryCode: 'BN',
        timezone: 'Asia/Brunei',
    },
    {
        id: 61,
        countryCode: 'BO',
        timezone: 'America/La_Paz',
    },
    {
        id: 62,
        countryCode: 'BQ',
        timezone: 'America/Kralendijk',
    },
    {
        id: 63,
        countryCode: 'BR',
        timezone: 'America/Noronha',
    },
    {
        id: 64,
        countryCode: 'BR',
        timezone: 'America/Belem',
    },
    {
        id: 65,
        countryCode: 'BR',
        timezone: 'America/Fortaleza',
    },
    {
        id: 66,
        countryCode: 'BR',
        timezone: 'America/Recife',
    },
    {
        id: 67,
        countryCode: 'BR',
        timezone: 'America/Araguaina',
    },
    {
        id: 68,
        countryCode: 'BR',
        timezone: 'America/Maceio',
    },
    {
        id: 69,
        countryCode: 'BR',
        timezone: 'America/Bahia',
    },
    {
        id: 70,
        countryCode: 'BR',
        timezone: 'America/Sao_Paulo',
    },
    {
        id: 71,
        countryCode: 'BR',
        timezone: 'America/Campo_Grande',
    },
    {
        id: 72,
        countryCode: 'BR',
        timezone: 'America/Cuiaba',
    },
    {
        id: 73,
        countryCode: 'BR',
        timezone: 'America/Santarem',
    },
    {
        id: 74,
        countryCode: 'BR',
        timezone: 'America/Porto_Velho',
    },
    {
        id: 75,
        countryCode: 'BR',
        timezone: 'America/Boa_Vista',
    },
    {
        id: 76,
        countryCode: 'BR',
        timezone: 'America/Manaus',
    },
    {
        id: 77,
        countryCode: 'BR',
        timezone: 'America/Eirunepe',
    },
    {
        id: 78,
        countryCode: 'BR',
        timezone: 'America/Rio_Branco',
    },
    {
        id: 79,
        countryCode: 'BS',
        timezone: 'America/Nassau',
    },
    {
        id: 80,
        countryCode: 'BT',
        timezone: 'Asia/Thimphu',
    },
    {
        id: 81,
        countryCode: 'BW',
        timezone: 'Africa/Gaborone',
    },
    {
        id: 82,
        countryCode: 'BY',
        timezone: 'Europe/Minsk',
    },
    {
        id: 83,
        countryCode: 'BZ',
        timezone: 'America/Belize',
    },
    {
        id: 84,
        countryCode: 'CA',
        timezone: 'America/St_Johns',
    },
    {
        id: 85,
        countryCode: 'CA',
        timezone: 'America/Halifax',
    },
    {
        id: 86,
        countryCode: 'CA',
        timezone: 'America/Glace_Bay',
    },
    {
        id: 87,
        countryCode: 'CA',
        timezone: 'America/Moncton',
    },
    {
        id: 88,
        countryCode: 'CA',
        timezone: 'America/Goose_Bay',
    },
    {
        id: 89,
        countryCode: 'CA',
        timezone: 'America/Blanc-Sablon',
    },
    {
        id: 90,
        countryCode: 'CA',
        timezone: 'America/Toronto',
    },
    {
        id: 91,
        countryCode: 'CA',
        timezone: 'America/Nipigon',
    },
    {
        id: 92,
        countryCode: 'CA',
        timezone: 'America/Thunder_Bay',
    },
    {
        id: 93,
        countryCode: 'CA',
        timezone: 'America/Iqaluit',
    },
    {
        id: 94,
        countryCode: 'CA',
        timezone: 'America/Pangnirtung',
    },
    {
        id: 95,
        countryCode: 'CA',
        timezone: 'America/Atikokan',
    },
    {
        id: 96,
        countryCode: 'CA',
        timezone: 'America/Winnipeg',
    },
    {
        id: 97,
        countryCode: 'CA',
        timezone: 'America/Rainy_River',
    },
    {
        id: 98,
        countryCode: 'CA',
        timezone: 'America/Resolute',
    },
    {
        id: 99,
        countryCode: 'CA',
        timezone: 'America/Rankin_Inlet',
    },
    {
        id: 100,
        countryCode: 'CA',
        timezone: 'America/Regina',
    },
    {
        id: 101,
        countryCode: 'CA',
        timezone: 'America/Swift_Current',
    },
    {
        id: 102,
        countryCode: 'CA',
        timezone: 'America/Edmonton',
    },
    {
        id: 103,
        countryCode: 'CA',
        timezone: 'America/Cambridge_Bay',
    },
    {
        id: 104,
        countryCode: 'CA',
        timezone: 'America/Yellowknife',
    },
    {
        id: 105,
        countryCode: 'CA',
        timezone: 'America/Inuvik',
    },
    {
        id: 106,
        countryCode: 'CA',
        timezone: 'America/Creston',
    },
    {
        id: 107,
        countryCode: 'CA',
        timezone: 'America/Dawson_Creek',
    },
    {
        id: 108,
        countryCode: 'CA',
        timezone: 'America/Fort_Nelson',
    },
    {
        id: 109,
        countryCode: 'CA',
        timezone: 'America/Vancouver',
    },
    {
        id: 110,
        countryCode: 'CA',
        timezone: 'America/Whitehorse',
    },
    {
        id: 111,
        countryCode: 'CA',
        timezone: 'America/Dawson',
    },
    {
        id: 112,
        countryCode: 'CC',
        timezone: 'Indian/Cocos',
    },
    {
        id: 113,
        countryCode: 'CD',
        timezone: 'Africa/Kinshasa',
    },
    {
        id: 114,
        countryCode: 'CD',
        timezone: 'Africa/Lubumbashi',
    },
    {
        id: 115,
        countryCode: 'CF',
        timezone: 'Africa/Bangui',
    },
    {
        id: 116,
        countryCode: 'CG',
        timezone: 'Africa/Brazzaville',
    },
    {
        id: 117,
        countryCode: 'CH',
        timezone: 'Europe/Zurich',
    },
    {
        id: 118,
        countryCode: 'CI',
        timezone: 'Africa/Abidjan',
    },
    {
        id: 119,
        countryCode: 'CK',
        timezone: 'Pacific/Rarotonga',
    },
    {
        id: 120,
        countryCode: 'CL',
        timezone: 'America/Santiago',
    },
    {
        id: 121,
        countryCode: 'CL',
        timezone: 'America/Punta_Arenas',
    },
    {
        id: 122,
        countryCode: 'CL',
        timezone: 'Pacific/Easter',
    },
    {
        id: 123,
        countryCode: 'CM',
        timezone: 'Africa/Douala',
    },
    {
        id: 124,
        countryCode: 'CN',
        timezone: 'Asia/Shanghai',
    },
    {
        id: 125,
        countryCode: 'CN',
        timezone: 'Asia/Urumqi',
    },
    {
        id: 126,
        countryCode: 'CO',
        timezone: 'America/Bogota',
    },
    {
        id: 127,
        countryCode: 'CR',
        timezone: 'America/Costa_Rica',
    },
    {
        id: 128,
        countryCode: 'CU',
        timezone: 'America/Havana',
    },
    {
        id: 129,
        countryCode: 'CV',
        timezone: 'Atlantic/Cape_Verde',
    },
    {
        id: 130,
        countryCode: 'CW',
        timezone: 'America/Curacao',
    },
    {
        id: 131,
        countryCode: 'CX',
        timezone: 'Indian/Christmas',
    },
    {
        id: 132,
        countryCode: 'CY',
        timezone: 'Asia/Nicosia',
    },
    {
        id: 133,
        countryCode: 'CY',
        timezone: 'Asia/Famagusta',
    },
    {
        id: 134,
        countryCode: 'CZ',
        timezone: 'Europe/Prague',
    },
    {
        id: 135,
        countryCode: 'DE',
        timezone: 'Europe/Berlin',
    },
    {
        id: 136,
        countryCode: 'DE',
        timezone: 'Europe/Busingen',
    },
    {
        id: 137,
        countryCode: 'DJ',
        timezone: 'Africa/Djibouti',
    },
    {
        id: 138,
        countryCode: 'DK',
        timezone: 'Europe/Copenhagen',
    },
    {
        id: 139,
        countryCode: 'DM',
        timezone: 'America/Dominica',
    },
    {
        id: 140,
        countryCode: 'DO',
        timezone: 'America/Santo_Domingo',
    },
    {
        id: 141,
        countryCode: 'DZ',
        timezone: 'Africa/Algiers',
    },
    {
        id: 142,
        countryCode: 'EC',
        timezone: 'America/Guayaquil',
    },
    {
        id: 143,
        countryCode: 'EC',
        timezone: 'Pacific/Galapagos',
    },
    {
        id: 144,
        countryCode: 'EE',
        timezone: 'Europe/Tallinn',
    },
    {
        id: 145,
        countryCode: 'EG',
        timezone: 'Africa/Cairo',
    },
    {
        id: 146,
        countryCode: 'EH',
        timezone: 'Africa/El_Aaiun',
    },
    {
        id: 147,
        countryCode: 'ER',
        timezone: 'Africa/Asmara',
    },
    {
        id: 148,
        countryCode: 'ES',
        timezone: 'Europe/Madrid',
    },
    {
        id: 149,
        countryCode: 'ES',
        timezone: 'Africa/Ceuta',
    },
    {
        id: 150,
        countryCode: 'ES',
        timezone: 'Atlantic/Canary',
    },
    {
        id: 151,
        countryCode: 'ET',
        timezone: 'Africa/Addis_Ababa',
    },
    {
        id: 152,
        countryCode: 'FI',
        timezone: 'Europe/Helsinki',
    },
    {
        id: 153,
        countryCode: 'FJ',
        timezone: 'Pacific/Fiji',
    },
    {
        id: 154,
        countryCode: 'FK',
        timezone: 'Atlantic/Stanley',
    },
    {
        id: 155,
        countryCode: 'FM',
        timezone: 'Pacific/Chuuk',
    },
    {
        id: 156,
        countryCode: 'FM',
        timezone: 'Pacific/Pohnpei',
    },
    {
        id: 157,
        countryCode: 'FM',
        timezone: 'Pacific/Kosrae',
    },
    {
        id: 158,
        countryCode: 'FO',
        timezone: 'Atlantic/Faroe',
    },
    {
        id: 159,
        countryCode: 'FR',
        timezone: 'Europe/Paris',
    },
    {
        id: 160,
        countryCode: 'GA',
        timezone: 'Africa/Libreville',
    },
    {
        id: 161,
        countryCode: 'UK',
        timezone: 'Europe/London',
    },
    {
        id: 162,
        countryCode: 'GD',
        timezone: 'America/Grenada',
    },
    {
        id: 163,
        countryCode: 'GE',
        timezone: 'Asia/Tbilisi',
    },
    {
        id: 164,
        countryCode: 'GF',
        timezone: 'America/Cayenne',
    },
    {
        id: 165,
        countryCode: 'GG',
        timezone: 'Europe/Guernsey',
    },
    {
        id: 166,
        countryCode: 'GH',
        timezone: 'Africa/Accra',
    },
    {
        id: 167,
        countryCode: 'GI',
        timezone: 'Europe/Gibraltar',
    },
    {
        id: 168,
        countryCode: 'GL',
        timezone: 'America/Nuuk',
    },
    {
        id: 169,
        countryCode: 'GL',
        timezone: 'America/Danmarkshavn',
    },
    {
        id: 170,
        countryCode: 'GL',
        timezone: 'America/Scoresbysund',
    },
    {
        id: 171,
        countryCode: 'GL',
        timezone: 'America/Thule',
    },
    {
        id: 172,
        countryCode: 'GM',
        timezone: 'Africa/Banjul',
    },
    {
        id: 173,
        countryCode: 'GN',
        timezone: 'Africa/Conakry',
    },
    {
        id: 174,
        countryCode: 'GP',
        timezone: 'America/Guadeloupe',
    },
    {
        id: 175,
        countryCode: 'GQ',
        timezone: 'Africa/Malabo',
    },
    {
        id: 176,
        countryCode: 'GR',
        timezone: 'Europe/Athens',
    },
    {
        id: 177,
        countryCode: 'GS',
        timezone: 'Atlantic/South_Georgia',
    },
    {
        id: 178,
        countryCode: 'GT',
        timezone: 'America/Guatemala',
    },
    {
        id: 179,
        countryCode: 'GU',
        timezone: 'Pacific/Guam',
    },
    {
        id: 180,
        countryCode: 'GW',
        timezone: 'Africa/Bissau',
    },
    {
        id: 181,
        countryCode: 'GY',
        timezone: 'America/Guyana',
    },
    {
        id: 182,
        countryCode: 'HK',
        timezone: 'Asia/Hong_Kong',
    },
    {
        id: 183,
        countryCode: 'HN',
        timezone: 'America/Tegucigalpa',
    },
    {
        id: 184,
        countryCode: 'HR',
        timezone: 'Europe/Zagreb',
    },
    {
        id: 185,
        countryCode: 'HT',
        timezone: 'America/Port-au-Prince',
    },
    {
        id: 186,
        countryCode: 'HU',
        timezone: 'Europe/Budapest',
    },
    {
        id: 187,
        countryCode: 'ID',
        timezone: 'Asia/Jakarta',
    },
    {
        id: 188,
        countryCode: 'ID',
        timezone: 'Asia/Pontianak',
    },
    {
        id: 189,
        countryCode: 'ID',
        timezone: 'Asia/Makassar',
    },
    {
        id: 190,
        countryCode: 'ID',
        timezone: 'Asia/Jayapura',
    },
    {
        id: 191,
        countryCode: 'IE',
        timezone: 'Europe/Dublin',
    },
    {
        id: 192,
        countryCode: 'IL',
        timezone: 'Asia/Jerusalem',
    },
    {
        id: 193,
        countryCode: 'IM',
        timezone: 'Europe/Isle_of_Man',
    },
    {
        id: 194,
        countryCode: 'IN',
        timezone: 'Asia/Kolkata',
        alternate: 'Asia/Calcutta',
    },
    {
        id: 195,
        countryCode: 'IO',
        timezone: 'Indian/Chagos',
    },
    {
        id: 196,
        countryCode: 'IQ',
        timezone: 'Asia/Baghdad',
    },
    {
        id: 197,
        countryCode: 'IR',
        timezone: 'Asia/Tehran',
    },
    {
        id: 198,
        countryCode: 'IS',
        timezone: 'Atlantic/Reykjavik',
    },
    {
        id: 199,
        countryCode: 'IT',
        timezone: 'Europe/Rome',
    },
    {
        id: 200,
        countryCode: 'JE',
        timezone: 'Europe/Jersey',
    },
    {
        id: 201,
        countryCode: 'JM',
        timezone: 'America/Jamaica',
    },
    {
        id: 202,
        countryCode: 'JO',
        timezone: 'Asia/Amman',
    },
    {
        id: 203,
        countryCode: 'JP',
        timezone: 'Asia/Tokyo',
    },
    {
        id: 204,
        countryCode: 'KE',
        timezone: 'Africa/Nairobi',
    },
    {
        id: 205,
        countryCode: 'KG',
        timezone: 'Asia/Bishkek',
    },
    {
        id: 206,
        countryCode: 'KH',
        timezone: 'Asia/Phnom_Penh',
    },
    {
        id: 207,
        countryCode: 'KI',
        timezone: 'Pacific/Tarawa',
    },
    {
        id: 208,
        countryCode: 'KI',
        timezone: 'Pacific/Enderbury',
    },
    {
        id: 209,
        countryCode: 'KI',
        timezone: 'Pacific/Kiritimati',
    },
    {
        id: 210,
        countryCode: 'KM',
        timezone: 'Indian/Comoro',
    },
    {
        id: 211,
        countryCode: 'KN',
        timezone: 'America/St_Kitts',
    },
    {
        id: 212,
        countryCode: 'KP',
        timezone: 'Asia/Pyongyang',
    },
    {
        id: 213,
        countryCode: 'KR',
        timezone: 'Asia/Seoul',
    },
    {
        id: 214,
        countryCode: 'KW',
        timezone: 'Asia/Kuwait',
    },
    {
        id: 215,
        countryCode: 'KY',
        timezone: 'America/Cayman',
    },
    {
        id: 216,
        countryCode: 'KZ',
        timezone: 'Asia/Almaty',
    },
    {
        id: 217,
        countryCode: 'KZ',
        timezone: 'Asia/Qyzylorda',
    },
    {
        id: 218,
        countryCode: 'KZ',
        timezone: 'Asia/Qostanay',
    },
    {
        id: 219,
        countryCode: 'KZ',
        timezone: 'Asia/Aqtobe',
    },
    {
        id: 220,
        countryCode: 'KZ',
        timezone: 'Asia/Aqtau',
    },
    {
        id: 221,
        countryCode: 'KZ',
        timezone: 'Asia/Atyrau',
    },
    {
        id: 222,
        countryCode: 'KZ',
        timezone: 'Asia/Oral',
    },
    {
        id: 223,
        countryCode: 'LA',
        timezone: 'Asia/Vientiane',
    },
    {
        id: 224,
        countryCode: 'LB',
        timezone: 'Asia/Beirut',
    },
    {
        id: 225,
        countryCode: 'LC',
        timezone: 'America/St_Lucia',
    },
    {
        id: 226,
        countryCode: 'LI',
        timezone: 'Europe/Vaduz',
    },
    {
        id: 227,
        countryCode: 'LK',
        timezone: 'Asia/Colombo',
    },
    {
        id: 228,
        countryCode: 'LR',
        timezone: 'Africa/Monrovia',
    },
    {
        id: 229,
        countryCode: 'LS',
        timezone: 'Africa/Maseru',
    },
    {
        id: 230,
        countryCode: 'LT',
        timezone: 'Europe/Vilnius',
    },
    {
        id: 231,
        countryCode: 'LU',
        timezone: 'Europe/Luxembourg',
    },
    {
        id: 232,
        countryCode: 'LV',
        timezone: 'Europe/Riga',
    },
    {
        id: 233,
        countryCode: 'LY',
        timezone: 'Africa/Tripoli',
    },
    {
        id: 234,
        countryCode: 'MA',
        timezone: 'Africa/Casablanca',
    },
    {
        id: 235,
        countryCode: 'MC',
        timezone: 'Europe/Monaco',
    },
    {
        id: 236,
        countryCode: 'MD',
        timezone: 'Europe/Chisinau',
    },
    {
        id: 237,
        countryCode: 'ME',
        timezone: 'Europe/Podgorica',
    },
    {
        id: 238,
        countryCode: 'MF',
        timezone: 'America/Marigot',
    },
    {
        id: 239,
        countryCode: 'MG',
        timezone: 'Indian/Antananarivo',
    },
    {
        id: 240,
        countryCode: 'MH',
        timezone: 'Pacific/Majuro',
    },
    {
        id: 241,
        countryCode: 'MH',
        timezone: 'Pacific/Kwajalein',
    },
    {
        id: 242,
        countryCode: 'MK',
        timezone: 'Europe/Skopje',
    },
    {
        id: 243,
        countryCode: 'ML',
        timezone: 'Africa/Bamako',
    },
    {
        id: 244,
        countryCode: 'MM',
        timezone: 'Asia/Yangon',
    },
    {
        id: 245,
        countryCode: 'MN',
        timezone: 'Asia/Ulaanbaatar',
    },
    {
        id: 246,
        countryCode: 'MN',
        timezone: 'Asia/Hovd',
    },
    {
        id: 247,
        countryCode: 'MN',
        timezone: 'Asia/Choibalsan',
    },
    {
        id: 248,
        countryCode: 'MO',
        timezone: 'Asia/Macau',
    },
    {
        id: 249,
        countryCode: 'MP',
        timezone: 'Pacific/Saipan',
    },
    {
        id: 250,
        countryCode: 'MQ',
        timezone: 'America/Martinique',
    },
    {
        id: 251,
        countryCode: 'MR',
        timezone: 'Africa/Nouakchott',
    },
    {
        id: 252,
        countryCode: 'MS',
        timezone: 'America/Montserrat',
    },
    {
        id: 253,
        countryCode: 'MT',
        timezone: 'Europe/Malta',
    },
    {
        id: 254,
        countryCode: 'MU',
        timezone: 'Indian/Mauritius',
    },
    {
        id: 255,
        countryCode: 'MV',
        timezone: 'Indian/Maldives',
    },
    {
        id: 256,
        countryCode: 'MW',
        timezone: 'Africa/Blantyre',
    },
    {
        id: 257,
        countryCode: 'MX',
        timezone: 'America/Mexico_City',
    },
    {
        id: 258,
        countryCode: 'MX',
        timezone: 'America/Cancun',
    },
    {
        id: 259,
        countryCode: 'MX',
        timezone: 'America/Merida',
    },
    {
        id: 260,
        countryCode: 'MX',
        timezone: 'America/Monterrey',
    },
    {
        id: 261,
        countryCode: 'MX',
        timezone: 'America/Matamoros',
    },
    {
        id: 262,
        countryCode: 'MX',
        timezone: 'America/Mazatlan',
    },
    {
        id: 263,
        countryCode: 'MX',
        timezone: 'America/Chihuahua',
    },
    {
        id: 264,
        countryCode: 'MX',
        timezone: 'America/Ojinaga',
    },
    {
        id: 265,
        countryCode: 'MX',
        timezone: 'America/Hermosillo',
    },
    {
        id: 266,
        countryCode: 'MX',
        timezone: 'America/Tijuana',
    },
    {
        id: 267,
        countryCode: 'MX',
        timezone: 'America/Bahia_Banderas',
    },
    {
        id: 268,
        countryCode: 'MY',
        timezone: 'Asia/Kuala_Lumpur',
    },
    {
        id: 269,
        countryCode: 'MY',
        timezone: 'Asia/Kuching',
    },
    {
        id: 270,
        countryCode: 'MZ',
        timezone: 'Africa/Maputo',
    },
    {
        id: 271,
        countryCode: 'NA',
        timezone: 'Africa/Windhoek',
    },
    {
        id: 272,
        countryCode: 'NC',
        timezone: 'Pacific/Noumea',
    },
    {
        id: 273,
        countryCode: 'NE',
        timezone: 'Africa/Niamey',
    },
    {
        id: 274,
        countryCode: 'NF',
        timezone: 'Pacific/Norfolk',
    },
    {
        id: 275,
        countryCode: 'NG',
        timezone: 'Africa/Lagos',
    },
    {
        id: 276,
        countryCode: 'NI',
        timezone: 'America/Managua',
    },
    {
        id: 277,
        countryCode: 'NL',
        timezone: 'Europe/Amsterdam',
    },
    {
        id: 278,
        countryCode: 'NO',
        timezone: 'Europe/Oslo',
    },
    {
        id: 279,
        countryCode: 'NP',
        timezone: 'Asia/Kathmandu',
    },
    {
        id: 280,
        countryCode: 'NR',
        timezone: 'Pacific/Nauru',
    },
    {
        id: 281,
        countryCode: 'NU',
        timezone: 'Pacific/Niue',
    },
    {
        id: 282,
        countryCode: 'NZ',
        timezone: 'Pacific/Auckland',
    },
    {
        id: 283,
        countryCode: 'NZ',
        timezone: 'Pacific/Chatham',
    },
    {
        id: 284,
        countryCode: 'OM',
        timezone: 'Asia/Muscat',
    },
    {
        id: 285,
        countryCode: 'PA',
        timezone: 'America/Panama',
    },
    {
        id: 286,
        countryCode: 'PE',
        timezone: 'America/Lima',
    },
    {
        id: 287,
        countryCode: 'PF',
        timezone: 'Pacific/Tahiti',
    },
    {
        id: 288,
        countryCode: 'PF',
        timezone: 'Pacific/Marquesas',
    },
    {
        id: 289,
        countryCode: 'PF',
        timezone: 'Pacific/Gambier',
    },
    {
        id: 290,
        countryCode: 'PG',
        timezone: 'Pacific/Port_Moresby',
    },
    {
        id: 291,
        countryCode: 'PG',
        timezone: 'Pacific/Bougainville',
    },
    {
        id: 292,
        countryCode: 'PH',
        timezone: 'Asia/Manila',
    },
    {
        id: 293,
        countryCode: 'PK',
        timezone: 'Asia/Karachi',
    },
    {
        id: 294,
        countryCode: 'PL',
        timezone: 'Europe/Warsaw',
    },
    {
        id: 295,
        countryCode: 'PM',
        timezone: 'America/Miquelon',
    },
    {
        id: 296,
        countryCode: 'PN',
        timezone: 'Pacific/Pitcairn',
    },
    {
        id: 297,
        countryCode: 'PR',
        timezone: 'America/Puerto_Rico',
    },
    {
        id: 298,
        countryCode: 'PS',
        timezone: 'Asia/Gaza',
    },
    {
        id: 299,
        countryCode: 'PS',
        timezone: 'Asia/Hebron',
    },
    {
        id: 300,
        countryCode: 'PT',
        timezone: 'Europe/Lisbon',
    },
    {
        id: 301,
        countryCode: 'PT',
        timezone: 'Atlantic/Madeira',
    },
    {
        id: 302,
        countryCode: 'PT',
        timezone: 'Atlantic/Azores',
    },
    {
        id: 303,
        countryCode: 'PW',
        timezone: 'Pacific/Palau',
    },
    {
        id: 304,
        countryCode: 'PY',
        timezone: 'America/Asuncion',
    },
    {
        id: 305,
        countryCode: 'QA',
        timezone: 'Asia/Qatar',
    },
    {
        id: 306,
        countryCode: 'RE',
        timezone: 'Indian/Reunion',
    },
    {
        id: 307,
        countryCode: 'RO',
        timezone: 'Europe/Bucharest',
    },
    {
        id: 308,
        countryCode: 'RS',
        timezone: 'Europe/Belgrade',
    },
    {
        id: 309,
        countryCode: 'RU',
        timezone: 'Europe/Kaliningrad',
    },
    {
        id: 310,
        countryCode: 'RU',
        timezone: 'Europe/Moscow',
    },
    {
        id: 311,
        countryCode: 'UA',
        timezone: 'Europe/Simferopol',
    },
    {
        id: 312,
        countryCode: 'RU',
        timezone: 'Europe/Kirov',
    },
    {
        id: 313,
        countryCode: 'RU',
        timezone: 'Europe/Astrakhan',
    },
    {
        id: 314,
        countryCode: 'RU',
        timezone: 'Europe/Volgograd',
    },
    {
        id: 315,
        countryCode: 'RU',
        timezone: 'Europe/Saratov',
    },
    {
        id: 316,
        countryCode: 'RU',
        timezone: 'Europe/Ulyanovsk',
    },
    {
        id: 317,
        countryCode: 'RU',
        timezone: 'Europe/Samara',
    },
    {
        id: 318,
        countryCode: 'RU',
        timezone: 'Asia/Yekaterinburg',
    },
    {
        id: 319,
        countryCode: 'RU',
        timezone: 'Asia/Omsk',
    },
    {
        id: 320,
        countryCode: 'RU',
        timezone: 'Asia/Novosibirsk',
    },
    {
        id: 321,
        countryCode: 'RU',
        timezone: 'Asia/Barnaul',
    },
    {
        id: 322,
        countryCode: 'RU',
        timezone: 'Asia/Tomsk',
    },
    {
        id: 323,
        countryCode: 'RU',
        timezone: 'Asia/Novokuznetsk',
    },
    {
        id: 324,
        countryCode: 'RU',
        timezone: 'Asia/Krasnoyarsk',
    },
    {
        id: 325,
        countryCode: 'RU',
        timezone: 'Asia/Irkutsk',
    },
    {
        id: 326,
        countryCode: 'RU',
        timezone: 'Asia/Chita',
    },
    {
        id: 327,
        countryCode: 'RU',
        timezone: 'Asia/Yakutsk',
    },
    {
        id: 328,
        countryCode: 'RU',
        timezone: 'Asia/Khandyga',
    },
    {
        id: 329,
        countryCode: 'RU',
        timezone: 'Asia/Vladivostok',
    },
    {
        id: 330,
        countryCode: 'RU',
        timezone: 'Asia/Ust-Nera',
    },
    {
        id: 331,
        countryCode: 'RU',
        timezone: 'Asia/Magadan',
    },
    {
        id: 332,
        countryCode: 'RU',
        timezone: 'Asia/Sakhalin',
    },
    {
        id: 333,
        countryCode: 'RU',
        timezone: 'Asia/Srednekolymsk',
    },
    {
        id: 334,
        countryCode: 'RU',
        timezone: 'Asia/Kamchatka',
    },
    {
        id: 335,
        countryCode: 'RU',
        timezone: 'Asia/Anadyr',
    },
    {
        id: 336,
        countryCode: 'RW',
        timezone: 'Africa/Kigali',
    },
    {
        id: 337,
        countryCode: 'SA',
        timezone: 'Asia/Riyadh',
    },
    {
        id: 338,
        countryCode: 'SB',
        timezone: 'Pacific/Guadalcanal',
    },
    {
        id: 339,
        countryCode: 'SC',
        timezone: 'Indian/Mahe',
    },
    {
        id: 340,
        countryCode: 'SD',
        timezone: 'Africa/Khartoum',
    },
    {
        id: 341,
        countryCode: 'SE',
        timezone: 'Europe/Stockholm',
    },
    {
        id: 342,
        countryCode: 'SG',
        timezone: 'Asia/Singapore',
    },
    {
        id: 343,
        countryCode: 'SH',
        timezone: 'Atlantic/St_Helena',
    },
    {
        id: 344,
        countryCode: 'SI',
        timezone: 'Europe/Ljubljana',
    },
    {
        id: 345,
        countryCode: 'SJ',
        timezone: 'Arctic/Longyearbyen',
    },
    {
        id: 346,
        countryCode: 'SK',
        timezone: 'Europe/Bratislava',
    },
    {
        id: 347,
        countryCode: 'SL',
        timezone: 'Africa/Freetown',
    },
    {
        id: 348,
        countryCode: 'SM',
        timezone: 'Europe/San_Marino',
    },
    {
        id: 349,
        countryCode: 'SN',
        timezone: 'Africa/Dakar',
    },
    {
        id: 350,
        countryCode: 'SO',
        timezone: 'Africa/Mogadishu',
    },
    {
        id: 351,
        countryCode: 'SR',
        timezone: 'America/Paramaribo',
    },
    {
        id: 352,
        countryCode: 'SS',
        timezone: 'Africa/Juba',
    },
    {
        id: 353,
        countryCode: 'ST',
        timezone: 'Africa/Sao_Tome',
    },
    {
        id: 354,
        countryCode: 'SV',
        timezone: 'America/El_Salvador',
    },
    {
        id: 355,
        countryCode: 'SX',
        timezone: 'America/Lower_Princes',
    },
    {
        id: 356,
        countryCode: 'SY',
        timezone: 'Asia/Damascus',
    },
    {
        id: 357,
        countryCode: 'SZ',
        timezone: 'Africa/Mbabane',
    },
    {
        id: 358,
        countryCode: 'TC',
        timezone: 'America/Grand_Turk',
    },
    {
        id: 359,
        countryCode: 'TD',
        timezone: 'Africa/Ndjamena',
    },
    {
        id: 360,
        countryCode: 'TF',
        timezone: 'Indian/Kerguelen',
    },
    {
        id: 361,
        countryCode: 'TG',
        timezone: 'Africa/Lome',
    },
    {
        id: 362,
        countryCode: 'TH',
        timezone: 'Asia/Bangkok',
    },
    {
        id: 363,
        countryCode: 'TJ',
        timezone: 'Asia/Dushanbe',
    },
    {
        id: 364,
        countryCode: 'TK',
        timezone: 'Pacific/Fakaofo',
    },
    {
        id: 365,
        countryCode: 'TL',
        timezone: 'Asia/Dili',
    },
    {
        id: 366,
        countryCode: 'TM',
        timezone: 'Asia/Ashgabat',
    },
    {
        id: 367,
        countryCode: 'TN',
        timezone: 'Africa/Tunis',
    },
    {
        id: 368,
        countryCode: 'TO',
        timezone: 'Pacific/Tongatapu',
    },
    {
        id: 369,
        countryCode: 'TR',
        timezone: 'Europe/Istanbul',
    },
    {
        id: 370,
        countryCode: 'TT',
        timezone: 'America/Port_of_Spain',
    },
    {
        id: 371,
        countryCode: 'TV',
        timezone: 'Pacific/Funafuti',
    },
    {
        id: 372,
        countryCode: 'TW',
        timezone: 'Asia/Taipei',
    },
    {
        id: 373,
        countryCode: 'TZ',
        timezone: 'Africa/Dar_es_Salaam',
    },
    {
        id: 374,
        countryCode: 'UA',
        timezone: 'Europe/Kiev',
    },
    {
        id: 375,
        countryCode: 'UA',
        timezone: 'Europe/Uzhgorod',
    },
    {
        id: 376,
        countryCode: 'UA',
        timezone: 'Europe/Zaporozhye',
    },
    {
        id: 377,
        countryCode: 'UG',
        timezone: 'Africa/Kampala',
    },
    {
        id: 378,
        countryCode: 'UM',
        timezone: 'Pacific/Midway',
    },
    {
        id: 379,
        countryCode: 'UM',
        timezone: 'Pacific/Wake',
    },
    {
        id: 380,
        countryCode: 'US',
        timezone: 'America/New_York',
    },
    {
        id: 381,
        countryCode: 'US',
        timezone: 'America/Detroit',
    },
    {
        id: 382,
        countryCode: 'US',
        timezone: 'America/Kentucky/Louisville',
    },
    {
        id: 383,
        countryCode: 'US',
        timezone: 'America/Kentucky/Monticello',
    },
    {
        id: 384,
        countryCode: 'US',
        timezone: 'America/Indiana/Indianapolis',
    },
    {
        id: 385,
        countryCode: 'US',
        timezone: 'America/Indiana/Vincennes',
    },
    {
        id: 386,
        countryCode: 'US',
        timezone: 'America/Indiana/Winamac',
    },
    {
        id: 387,
        countryCode: 'US',
        timezone: 'America/Indiana/Marengo',
    },
    {
        id: 388,
        countryCode: 'US',
        timezone: 'America/Indiana/Petersburg',
    },
    {
        id: 389,
        countryCode: 'US',
        timezone: 'America/Indiana/Vevay',
    },
    {
        id: 390,
        countryCode: 'US',
        timezone: 'America/Chicago',
    },
    {
        id: 391,
        countryCode: 'US',
        timezone: 'America/Indiana/Tell_City',
    },
    {
        id: 392,
        countryCode: 'US',
        timezone: 'America/Indiana/Knox',
    },
    {
        id: 393,
        countryCode: 'US',
        timezone: 'America/Menominee',
    },
    {
        id: 394,
        countryCode: 'US',
        timezone: 'America/North_Dakota/Center',
    },
    {
        id: 395,
        countryCode: 'US',
        timezone: 'America/North_Dakota/New_Salem',
    },
    {
        id: 396,
        countryCode: 'US',
        timezone: 'America/North_Dakota/Beulah',
    },
    {
        id: 397,
        countryCode: 'US',
        timezone: 'America/Denver',
    },
    {
        id: 398,
        countryCode: 'US',
        timezone: 'America/Boise',
    },
    {
        id: 399,
        countryCode: 'US',
        timezone: 'America/Phoenix',
    },
    {
        id: 400,
        countryCode: 'US',
        timezone: 'America/Los_Angeles',
    },
    {
        id: 401,
        countryCode: 'US',
        timezone: 'America/Anchorage',
    },
    {
        id: 402,
        countryCode: 'US',
        timezone: 'America/Juneau',
    },
    {
        id: 403,
        countryCode: 'US',
        timezone: 'America/Sitka',
    },
    {
        id: 404,
        countryCode: 'US',
        timezone: 'America/Metlakatla',
    },
    {
        id: 405,
        countryCode: 'US',
        timezone: 'America/Yakutat',
    },
    {
        id: 406,
        countryCode: 'US',
        timezone: 'America/Nome',
    },
    {
        id: 407,
        countryCode: 'US',
        timezone: 'America/Adak',
    },
    {
        id: 408,
        countryCode: 'US',
        timezone: 'Pacific/Honolulu',
    },
    {
        id: 409,
        countryCode: 'UY',
        timezone: 'America/Montevideo',
    },
    {
        id: 410,
        countryCode: 'UZ',
        timezone: 'Asia/Samarkand',
    },
    {
        id: 411,
        countryCode: 'UZ',
        timezone: 'Asia/Tashkent',
    },
    {
        id: 412,
        countryCode: 'VA',
        timezone: 'Europe/Vatican',
    },
    {
        id: 413,
        countryCode: 'VC',
        timezone: 'America/St_Vincent',
    },
    {
        id: 414,
        countryCode: 'VE',
        timezone: 'America/Caracas',
    },
    {
        id: 415,
        countryCode: 'VG',
        timezone: 'America/Tortola',
    },
    {
        id: 416,
        countryCode: 'VI',
        timezone: 'America/St_Thomas',
    },
    {
        id: 417,
        countryCode: 'VN',
        timezone: 'Asia/Ho_Chi_Minh',
    },
    {
        id: 418,
        countryCode: 'VU',
        timezone: 'Pacific/Efate',
    },
    {
        id: 419,
        countryCode: 'WF',
        timezone: 'Pacific/Wallis',
    },
    {
        id: 420,
        countryCode: 'WS',
        timezone: 'Pacific/Apia',
    },
    {
        id: 421,
        countryCode: 'YE',
        timezone: 'Asia/Aden',
    },
    {
        id: 422,
        countryCode: 'YT',
        timezone: 'Indian/Mayotte',
    },
    {
        id: 423,
        countryCode: 'ZA',
        timezone: 'Africa/Johannesburg',
    },
    {
        id: 424,
        countryCode: 'ZM',
        timezone: 'Africa/Lusaka',
    },
    {
        id: 425,
        countryCode: 'ZW',
        timezone: 'Africa/Harare',
    },
];

export const DOUGHNUT_CHART_COLORS = [
    '#F2CC48',
    '#489D73',
    '#F19EF9',
    '#5182F0',
    '#B1FCA3',
    '#EC837D',
    '#61DC8B',
    '#FFFF6E',
    '#62A2F6',
    '#EC6637',
];

export const countryOptions = Object.keys(countryMap).map((item) => {
    return { label: item, value: item };
});

export const UPDATE_TYPE = {
    WORKSPACE: 'WORKSPACE',
    PHONE: 'PHONE',
    COUNTRY: 'COUNTRY',
    CHANNEL: 'CHANNEL',
    TIMEZONE: 'TIMEZONE',
    REFRESH: 'REFRESH',
};

export const PLATFORM = {
    SLACK: 'slack',
    TEAMS: 'MSTeams',
};
