import React from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as ManagerMappingLogo } from 'Assets/images/manager-mapping-logo.svg';
import { ReactComponent as Tick } from 'Assets/images/wright-tick.svg';
import { mapTeamsModalPoints, TITLE } from './constants';
import './style.scss';

const index = ({ open, setOpen, handleButtonClick }) => {
    const handleClick = () => {
        setOpen(false);
        handleButtonClick(true);
    };
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className='welcome-modal-container'>
                <CloseIcon className='btn-close' onClick={() => setOpen(false)} />
                <div className='welcome-modal-content'>
                    <h3 className='title'>{TITLE}</h3>
                    <div className='d-flex align-center justify-content-center width-100'>
                        <ManagerMappingLogo
                            style={{ stroke: 'transparent', width: '139px', height: '92px', marginBottom: '12px' }}
                        />
                    </div>
                    <div className='content'>
                        <div className='points-Container'>
                            {mapTeamsModalPoints.map((point) => (
                                <div className='points-Item' key={point}>
                                    <Tick className='tick' />
                                    <p>{point}</p>
                                </div>
                            ))}
                        </div>
                        <div className='subheading'>
                            Map your teammates to their managers on EngageWith to uncover deeper analytics <br /> within
                            your organization and across departments.
                        </div>
                    </div>
                    <button className='ew-btn pb-btn btn width-100' onClick={handleClick}>
                        Bulk update
                    </button>
                    <div className='mark-manager margin-top-10 pointer-cursor' onClick={() => setOpen(false)}>
                        Mark managers individually
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default index;
