import React, { useState } from 'react';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import CustomDropdown from '../CustomDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { debounce, find } from 'lodash';
import MemberCard from 'components/ReusableComponents/MemberDetailsCard';
import './style.scss';

const Index = ({
    title,
    selectedName,
    buttonWidth = 180,
    dropdownWidth = 200,
    id,
    placeholder = 'Search',
    handleSelection,
    selectedUsers,
    optionsSelected = true,
    managerId,
    apiCase,
    enableApi = true,
}) => {
    const { SetSnackbar } = useToaster();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [dropdownData, setDropdownData] = useState();

    const { fetchNextPage, hasNextPage, isLoading, isFetching } = useInfiniteQuery(
        [GET_USERS_DATA, { search, managerId, apiCase }],
        getUsers,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (data) => {
                setDropdownData(data);
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            retry: 0,
            enabled: enableApi,
        }
    );

    const handleSearch = debounce((value) => {
        setSearch(value);
    }, 300);

    return (
        <CustomDropdown
            dropDownID={id}
            title={title}
            selectedName={selectedName}
            buttonWidth={buttonWidth}
            dropdownWidth={dropdownWidth}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            optionsSelected={optionsSelected}
        >
            <div className='mutiselection-user-container'>
                {search || dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                    <div>
                        <CustomSearchBox
                            width={buttonWidth - 10}
                            handleSearch={(e) => handleSearch(e.target.value)}
                            placeholder={placeholder}
                        />
                        <div className='infinite-scroll-container'>
                            {dropdownData?.pages[0]?.data?.users?.length > 0 && (
                                <InfiniteScroll
                                    dataLength={dropdownData?.pages?.length * 9}
                                    next={fetchNextPage}
                                    hasMore={hasNextPage}
                                    height={160}
                                    loader={<CustomLoader size={10} />}
                                >
                                    {dropdownData?.pages?.map((pageData) =>
                                        pageData?.data?.users?.map((user) => (
                                            <div className='dropdown-list' key={user?._id}>
                                                <MemberCard
                                                    key={user?._id}
                                                    member={user}
                                                    selected={
                                                        !!find(
                                                            selectedUsers,
                                                            (selectedUser) => selectedUser?._id === user?._id
                                                        )
                                                    }
                                                    handleChange={() => handleSelection(user)}
                                                    maxWidth='450px'
                                                    showManagerInfo
                                                />
                                            </div>
                                        ))
                                    )}
                                </InfiniteScroll>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>{(isLoading || isFetching) && <CustomLoader size={10} />}</div>
                )}
            </div>
        </CustomDropdown>
    );
};

Index.propTypes = {
    title: PropTypes.string,
    selectedName: PropTypes.string,
    buttonWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dropdownWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleSelection: PropTypes.func,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    selectedUsers: PropTypes.array,
    optionsSelected: PropTypes.bool,
    managerId: PropTypes.string,
    apiCase: PropTypes.string,
    enableApi: PropTypes.bool,
};

export default Index;
