import React, { useState } from 'react';
import RecognitionTable from '../../components/RecognitionTable';
import RecognitionFilter from '../../components/RecognitionFilter';
import propTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import { format } from 'date-fns';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useQuery } from 'react-query';
import { FETCH_REDEMPTIONS_RECEIVED } from 'Services/apiKeys';
import { fetchTransactions } from 'Services/apiFunctions';

import { STATUS } from '../../../constants';

const Index = ({ history, transactionState, isAdmin }) => {
    const { SetSnackbar } = useToaster();
    const [page, setPage] = useState(1);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [filter, setFilter] = useState({
        page,
        groupBy: 'received',
        sortBy: 'date',
        order: 'descending',
    });
    const [search, setSearch] = useState('');

    const {
        data: dataReceived,
        isLoading,
        isFetching,
    } = useQuery([FETCH_REDEMPTIONS_RECEIVED, page, search, filter], fetchTransactions, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (err) => {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        },
    });

    const handleCustomDate = (selectedDate) => {
        const fromDate = format(selectedDate?.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        const toDate = format(selectedDate?.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        setFilter({
            ...filter,
            dateRange: { start: fromDate, end: toDate },
        });
        setShowDatePicker(false);
    };

    if (!dataReceived) {
        return <CustomLoader />;
    }

    return (
        <div className='my-recognitions-container'>
            <RecognitionFilter
                filter={filter}
                setFilter={setFilter}
                setPage={setPage}
                from={STATUS.RECIEVED}
                setShowDatePicker={setShowDatePicker}
                search={search}
                setSearch={setSearch}
                transactionState={transactionState}
                isAdmin={isAdmin}
            />
            <RecognitionTable
                history={history}
                tableData={dataReceived.activities}
                currentPage={page}
                setCurrentPage={setPage}
                totalCount={dataReceived.totalCount}
                loading={isLoading || isFetching}
                filter={filter}
                setFilter={setFilter}
                from='recieved'
            />
            <CustomDateRangeModal
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onConfirm={(selectedDate) => handleCustomDate(selectedDate)}
            />
        </div>
    );
};

Index.propTypes = {
    history: propTypes.object,
    transactionState: propTypes.string,
    isAdmin: propTypes.bool,
};

export default Index;
