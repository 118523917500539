import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import InputField from 'components/ReusableComponents/InputField';
import { useMutation, useQueryClient } from 'react-query';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { updateSuggestion } from '../../Services/apiFunctions';
import { FEEDBACK_TEXT_LENGTH_LIMIT } from 'constants.js';
import { GET_SUGGESTION_BOX_LIST } from 'Services/apiKeys';
import './style.scss';

const Index = ({ open, onClose, editSuggestionData }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [suggestionMessage, setSuggestionMessage] = useState(editSuggestionData.message);

    const { mutate: sendUpdateSuggestionMessage, isLoading: isSendingMessage } = useMutation(updateSuggestion, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_SUGGESTION_BOX_LIST);
            onClose();
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
    });
    const handleSendSuggestion = () => {
        if (suggestionMessage.length > FEEDBACK_TEXT_LENGTH_LIMIT) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                `Message length should be less than ${FEEDBACK_TEXT_LENGTH_LIMIT} characters`
            );
            return;
        }

        const apiData = {
            message: suggestionMessage,
            suggestionId: editSuggestionData._id,
        };
        sendUpdateSuggestionMessage({ apiData });
    };

    // Data for send suggestion modal
    const modalData = {
        heading: 'Edit Suggestion',
        rightButtonText: 'Update',
        handleRightButtonClick: handleSendSuggestion,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isSendingMessage,
        disabled: suggestionMessage === editSuggestionData.message,
    };

    return (
        <div>
            <EWModal open={open} onClose={onClose} width='528px' modalData={modalData}>
                <div className='container-send-suggestion'>
                    <InputField
                        inputID='suggestion_message'
                        label='Suggestion message'
                        labelClass='csf-dropdown-heading'
                        value={suggestionMessage}
                        placeholder='Enter your suggestion message here'
                        width='480px'
                        height='92px'
                        textArea
                        handleChange={(_id, value) => setSuggestionMessage(value)}
                    />
                </div>
            </EWModal>
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    editSuggestionData: PropTypes.object,
    onClose: PropTypes.func,
};

export default Index;
