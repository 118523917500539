import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as EWLogo } from 'Assets/images/EW-logo-2.svg';
import { ReactComponent as ModuleBanner } from 'Assets/images/EW-module-banner.svg';
import { moduleItems } from './constants';
import { demoCallURL } from 'constants.js';
import Module from './Module';
import { useMutation } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { registerOnboardingModules } from 'Services/apiFunctions';
import { shallowEqual, useSelector } from 'react-redux';
import { computeRedirectionPath, showSnackBarMessage, isSlack } from 'utils/HelperFunctions';
import './style.scss';

const SUB_HEADING_TEXT =
    "Please select all the modules that meet your requirements. It's recommended\nthat you select all modules to have the best experience.";

const Index = ({ history }) => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const SetSnackbar = useToaster();

    const [featureModules, setFeatureModules] = useState(isSlack(platform) ? moduleItems : moduleItems.slice(0, 2));
    const [selectedModuleCount, setSelectedModuleCount] = useState(featureModules.length);

    const handleSelect = (index) => {
        const temp = [...featureModules];
        if (temp[index].selected) {
            setSelectedModuleCount(selectedModuleCount - 1);
        } else {
            setSelectedModuleCount(selectedModuleCount + 1);
        }
        temp[index].selected = !temp[index].selected;
        setFeatureModules(temp);
    };

    const handleSelectAll = () => {
        if (selectedModuleCount !== featureModules.length) {
            setFeatureModules(
                featureModules.map((item) => {
                    return { ...item, selected: true };
                })
            );
            setSelectedModuleCount(featureModules.length);
        }
    };

    const { mutate: registerModules } = useMutation(registerOnboardingModules, {
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error.message),
    });

    const handleSubmit = () => {
        let modules = featureModules.filter((item) => item.selected).map((item) => item.moduleKey);
        registerModules(modules);
        modules = modules.map((item) => ({ module: item, completed: false }));
        const path = computeRedirectionPath(modules, true);
        history.push(path);
    };

    return (
        <div className='d-flex height-100-vh bg-white'>
            <div className='module-left'>
                <div className='module-logo'>
                    <EWLogo />
                </div>
                <div className='module-description'>
                    <div className='banner-heading'>Welcome to EngageWith</div>
                    <div className='banner-sub-heading'>
                        According to a Great Place To Work survey, employees who are appreciated by their peers are{' '}
                        <strong>2.6x</strong> more productive when compared to under-appreciated employees.
                    </div>
                </div>
                <div className='banner'>
                    <ModuleBanner />
                </div>
            </div>
            <div className='module-right'>
                <div className='module-right-wrapper'>
                    <div className='width-100 d-flex flex-column'>
                        <div className='selection-heading'>What would you like to do with EngageWith?</div>
                        <div className='selection-sub-heading'>{SUB_HEADING_TEXT}</div>
                        <div className='selection-count width-100'>
                            <div
                                className={clsx({ 'pointer-cursor': selectedModuleCount !== featureModules.length })}
                                onClick={handleSelectAll}
                            >
                                {selectedModuleCount === featureModules.length
                                    ? `Selected (${selectedModuleCount})`
                                    : 'Select All'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='selection-section'>
                            {featureModules.map((item) => (
                                <Module key={item.id} Logo={item.logo} item={item} handleSelect={handleSelect} />
                            ))}
                        </div>
                    </div>
                    <div className='schedule-section'>
                        <p className='help-text'>Need help?&nbsp;</p>
                        <p className='setup-call' onClick={() => window.open(demoCallURL, '_blank')}>
                            Set up a call with my culture specialist.
                        </p>
                    </div>
                </div>
                <div className='continue-button'>
                    <button
                        className='ew-btn pb-btn btn width-160'
                        disabled={!selectedModuleCount}
                        onClick={handleSubmit}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
