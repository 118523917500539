import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import { StyledDropzone } from 'components/ReusableComponents/StyledDropzone';
import { useToaster } from 'Context/SnackbarContext';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getUserBulkUploadSampleFile } from 'constants.js';
import { bulkUploadUsers } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ setCurrentModal, onClose, setData, platform }) => {
    const [state, setState] = useState({ excelFileError: false });
    const [loading, setLoading] = useState(false);
    const { SetSnackbar } = useToaster();
    const csvDrop = (acceptedFile) => {
        if (acceptedFile.length === 0) {
            setState({ excelFileError: 'Unsupported file format' });
        } else if (acceptedFile[0].size > 20 * 1024 * 1024) {
            setState({ excelFileError: 'File size exceeds maximum limit of 20MB' });
        } else {
            setState({ excelFileError: null, acceptedFile: acceptedFile });
        }
    };
    const handleNext = async () => {
        setLoading(true);
        try {
            const uploadData = await bulkUploadUsers(state.acceptedFile);
            setLoading(false);
            setData(uploadData);
            setCurrentModal(2);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };
    return (
        <div className='upload-csv-container drag-methods-dashboard'>
            <div className='upload-csv-header'>
                <h3>Upload teams/members through CSV</h3>
                <CloseIcon className='close-icon' onClick={onClose} />
            </div>
            <p className='upload-csv-subtext'>
                Once you select this option we will ask you to select managers first and then build their teams in the
                next step. Once you get a hang of it you can choose to do it later as well in settings page of
                dashboard.
            </p>
            <div className='content-container-drag make-relative'>
                <StyledDropzone
                    onDrop={csvDrop}
                    setState={setState}
                    error={state.excelFileError}
                    acceptedFile={state.acceptedFile}
                    content={'Drop your CSV file here'}
                    height={177}
                    width={428}
                    loading={loading}
                />
            </div>
            <div className='upload-csv-text-containers'>
                <p className='upload-csv-subheadings'>Supported Formats- .csv</p>
                <div className='upload-csv-sample-file'>
                    <a href={getUserBulkUploadSampleFile(platform)} rel='noopener noreferrer' target='_blank' download>
                        <CloudDownloadIcon />
                        Download sample file
                    </a>
                </div>
            </div>
            <div>
                <span className='upload-csv-note' aria-label=''>
                    💡 Note- User email is mandatory to identify the user and update the information
                </span>
            </div>
            <div className='upload-csv-button-container'>
                <button className='ew-btn pb-btn' onClick={handleNext} disabled={loading || !state.acceptedFile}>
                    {loading ? <BeatLoader size={10} color={'#fff'} loading={loading} /> : 'Next'}
                </button>
            </div>
        </div>
    );
};

Index.propTypes = {
    setCurrentModal: PropTypes.func,
    onClose: PropTypes.func,
    setData: PropTypes.func,
    platform: PropTypes.string,
};

export default Index;
