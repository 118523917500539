import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'components';
import { getOrderDetails } from 'Services/apiFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import TermsModal from 'components/Dashboard/Content/RedeemPoints/TermsModal';
import { useToaster } from 'Context/SnackbarContext';
import RedeemInstructionModal from 'components/Dashboard/Content/RedeemPoints/RedeemInstructionModal';
import { ReactComponent as CopyIcon } from 'Assets/images/copy.svg';
import { ReactComponent as GreenTickIcon } from 'Assets/images/green-tick.svg';
import Pagination from 'components/ReusableComponents/Pagination';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import clsx from 'clsx';
import Lottie from 'react-lottie';
import Success from 'components/Success/image/success.json';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const handleXoxoDayApiCall = async ({
    orderId,
    setCouponCodes,
    setCouponData,
    SetSnackbar,
    setLoading,
    handleClose,
}) => {
    try {
        setLoading(true);
        const orderData = await getOrderDetails(orderId);
        setCouponCodes(orderData?.orderDetails || []);
        setCouponData(orderData?.orderDetails?.[0]);
    } catch (error) {
        SetSnackbar({
            open: true,
            variant: 'error',
            message: error?.response?.data?.message || 'Some error occurred!',
        });
        handleClose();
    } finally {
        setLoading(false);
    }
};

// this UI is used to show message when the delivery of immediate coupons has been delayed
const DelayedImmediateCoupons = ({ handleClose }) => {
    return (
        <div className='container-delayed-immediate'>
            <p>
                There was an issue in generating a coupon code. Don't worry, we will let you know as soon as the coupon
                is ready. <br />
                <br /> If you do not receive a coupon code within the next 72 hours please send an email to us:
                engagewith@springworks.in
            </p>
            <EWButton buttonText='Close' onClick={handleClose} />
        </div>
    );
};

const DelayedCoupons = ({ delayedProductInfo }) => {
    return (
        <>
            <div className='mt-3 text-center'>
                You will receive your gift card code and pin on{' '}
                <strong>{format(new Date(delayedProductInfo?.fulfillmentDate), 'dd MMM yyyy')}</strong> via email
            </div>
            <div className='my-4 text-center'>
                The Gift card will be sent on:
                <br />
                <strong>{delayedProductInfo?.userEmail}</strong>
            </div>
        </>
    );
};

const CouponLoader = () => {
    return (
        <div className='cmc-loader'>
            <CustomLoader />
        </div>
    );
};

const ModalComponent = ({
    setOpen,
    tnc,
    voucherCodes,
    isDelayed,
    delayedProductInfo,
    redeemInstructions,
    redeemCoupon,
    backButtonText,
    couponName,
}) => {
    const [termsOpen, setTermsOpen] = useState(false);
    const [instructionOpen, setInstructionOpen] = useState(false);
    const [couponCodes, setCouponCodes] = useState(voucherCodes);
    const [couponData, setCouponData] = useState(voucherCodes);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const { SetSnackbar } = useToaster();
    const isDelayedImmediateCoupon = redeemCoupon
        ? !couponData?.isCustom && !isDelayed && voucherCodes?.length <= 0
        : !couponData?.isCustom && !isDelayed && !couponData?.voucherCode;

    const SuccessAnimation = {
        loop: true,
        autoplay: true,
        animationData: Success,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (!couponData?.voucherCode && !couponData?.isCustom && typeof couponData?.isCustom !== 'undefined') {
            handleXoxoDayApiCall({
                orderId: couponData?.orderId,
                setCouponData,
                setCouponCodes,
                SetSnackbar,
                setLoading,
                handleClose,
            });
        }
    }, []);

    useEffect(() => {
        if (!couponData?.isCustom) {
            setCouponData(couponCodes?.[currentPage - 1]);
        }
    }, [currentPage]);

    return (
        <Modal open onClose={handleClose}>
            <>
                <div className='coupon-main-container absolute-center'>
                    {loading ? (
                        <CouponLoader />
                    ) : (
                        <>
                            {!isDelayedImmediateCoupon && redeemCoupon && (
                                <Lottie className='container-animation' options={SuccessAnimation} />
                            )}
                            {isDelayedImmediateCoupon ? (
                                <DelayedImmediateCoupons handleClose={handleClose} />
                            ) : (
                                <>
                                    <CloseIcon className='btn-close' onClick={handleClose} />
                                    <div
                                        className={clsx({
                                            'coupon-container': true,
                                            'extra-padding': isDelayed,
                                        })}
                                    >
                                        {redeemCoupon && (
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <GreenTickIcon />
                                                {isDelayed ? (
                                                    <p className='coupon-title'>
                                                        Thank you for purchasing {couponName} Gift card
                                                    </p>
                                                ) : (
                                                    <p className='coupon-title'>
                                                        {couponCodes?.length} {couponName} Gift Card <br /> Purchased
                                                        Successfully!
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        {isDelayed ? (
                                            <DelayedCoupons delayedProductInfo={delayedProductInfo} />
                                        ) : (
                                            <div>
                                                {couponData && (
                                                    <div className={clsx({ 'coupon-history': !redeemCoupon })}>
                                                        <CouponAndPinSection
                                                            redeemCoupon={redeemCoupon}
                                                            couponData={couponData}
                                                            quantity={couponData?.isCustom ? 1 : couponCodes?.length}
                                                            currentPage={currentPage}
                                                            setCurrentPage={setCurrentPage}
                                                            SetSnackbar={SetSnackbar}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <button className='ew-btn ob-btn btn-ok' onClick={handleClose}>
                                            {backButtonText || 'Back'}
                                        </button>
                                        {tnc && (
                                            <button className='tnc-btn' onClick={() => setTermsOpen(true)}>
                                                Terms &amp; Conditions
                                            </button>
                                        )}
                                        <button className=' ri-btn' onClick={() => setInstructionOpen(true)}>
                                            Redemption Instructions
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                <RedeemInstructionModal
                    instructionOpen={instructionOpen}
                    setInstructionOpen={setInstructionOpen}
                    instructionData={redeemInstructions}
                />
                <TermsModal termsOpen={termsOpen} setTermsOpen={setTermsOpen} termsData={tnc} />
            </>
        </Modal>
    );
};

const CouponAndPinSection = ({ couponData, redeemCoupon, currentPage, setCurrentPage, quantity, SetSnackbar }) => {
    if (!quantity) {
        return <CustomLoader />;
    }

    const paginationProps = {
        currentPage,
        limit: 1,
        totalCount: quantity,
        setCurrentPage,
    };

    const onCopy = () =>
        SetSnackbar({
            open: true,
            variant: 'success',
            message: 'Copied to clipboard',
        });

    return (
        <div className='coupon-item-container'>
            <div className='coupon-card'>
                <p className='giftcard'>GIFT CARD {quantity > 1 && currentPage}</p>
                <div className='coupon-info'>
                    {couponData?.voucherCode && (
                        <div>
                            <p className='heading'>Coupon code</p>
                            <div className='coupon-pin-container'>
                                <p className='code coupon-code-text'>{couponData?.voucherCode}</p>
                                <CopyToClipboard
                                    text={couponData?.voucherCode}
                                    data-tip
                                    data-for='copy'
                                    onCopy={onCopy}
                                >
                                    <Tooltip title='Copy'>
                                        <CopyIcon className='pointer-cursor' />
                                    </Tooltip>
                                </CopyToClipboard>
                            </div>
                        </div>
                    )}

                    {couponData?.pin && (
                        <div>
                            <p className='heading'>Pin</p>
                            <div className='coupon-pin-container'>
                                <p className='pin'>{couponData?.pin}</p>
                                <CopyToClipboard text={couponData?.pin} data-tip data-for='copy' onCopy={onCopy}>
                                    <Tooltip title='Copy'>
                                        <CopyIcon className='pointer-cursor' />
                                    </Tooltip>
                                </CopyToClipboard>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {quantity > 1 && <Pagination {...paginationProps} />}
            {redeemCoupon && (
                <p>
                    <InfoIcon /> You can always go to <strong>Redeem history</strong> to avail these codes and pin
                </p>
            )}
        </div>
    );
};

// This enables the modal to be mounted only when open is true.
const Index = ({ open, ...props }) => {
    if (open) {
        return <ModalComponent {...props} />;
    }
    return null;
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    tnc: PropTypes.string,
    voucherCodes: PropTypes.object,
    isDelayed: PropTypes.bool,
    delayedProductInfo: PropTypes.object,
    redeemInstructions: PropTypes.string,
    redeemCoupon: PropTypes.bool,
    backButtonText: PropTypes.string,
    couponName: PropTypes.string,
};

ModalComponent.propTypes = {
    setOpen: PropTypes.func,
    tnc: PropTypes.string,
    voucherCodes: PropTypes.object,
    isDelayed: PropTypes.bool,
    delayedProductInfo: PropTypes.object,
    redeemInstructions: PropTypes.string,
    redeemCoupon: PropTypes.bool,
    backButtonText: PropTypes.string,
    couponName: PropTypes.string,
};

DelayedImmediateCoupons.propTypes = {
    handleClose: PropTypes.func,
};

DelayedCoupons.propTypes = {
    delayedProductInfo: PropTypes.object,
};

CouponAndPinSection.propTypes = {
    couponData: PropTypes.object,
    redeemCoupon: PropTypes.bool,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    quantity: PropTypes.number,
    SetSnackbar: PropTypes.func,
};

export default Index;
