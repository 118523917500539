import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { Tooltip, CustomSwitch } from 'components';
import GiftValue from 'components/ReusableComponents/GiftValue';
import CountryRewardsModal from 'components/Modals/CountryRewardsModal';
import { find } from 'lodash';
import { STATUS_TOOLTIP, ANNIVERSARY_TOOLTIP } from '../constants';
import { getCountryFlagURL } from 'utils/HelperFunctions';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { ReactComponent as Ellipse } from 'Assets/images/ellipse.svg';
import './style.scss';

const Column = ({ name }) => <span className='column-heading'>{name}</span>;

const Index = ({
    open,
    onClose,
    apiData,
    giftData,
    handleSave,
    loading,
    isBirthdayEnabled,
    isWorkAnniversaryEnabled,
    history,
}) => {
    const [tableGiftData, setTableGiftData] = useState([]);
    const disableSave = !find(giftData, (data) => data.modified);

    const header = [
        <Column key='countries' name='COUNTRY' />,
        <Column key='birthday' name='BIRTHDAY GIFT' />,
        <span key='anniversary'>
            <Column name='ANNIVERSARY GIFT' />
            <Tooltip arrow title={ANNIVERSARY_TOOLTIP}>
                <InfoIcon className='info-icon' />
            </Tooltip>
            <br></br>
            <p className='column-description'>{'1st (Y), 2nd (Y), 3rd (Y)...  = 100, 200, 300....'}</p>
        </span>,
        <span key='status'>
            <Column name='STATUS' />
            <Tooltip arrow title={STATUS_TOOLTIP}>
                <InfoIcon className='info-icon' />
            </Tooltip>
        </span>,
    ];

    let noSortColumns = [0, 1, 2, 3, 4];
    let columnWidth = ['25%', '25%', '35%', '15%'];

    const { SetSnackbar } = useToaster();

    const showErrorMessage = (errorMessage) => {
        SetSnackbar({
            open: true,
            variant: 'warning',
            message: errorMessage || 'Some error occurred',
        });
    };
    const isModified = (obj1, obj2) =>
        obj1.anniversaryValue !== obj2.anniversaryValue ||
        obj1.birthDateValue !== obj2.birthDateValue ||
        obj1.status !== obj2.status;

    const handleStatus = (index, value) => {
        const tempData = [...giftData];
        tempData[index].status = value;
        tempData[index].modified = isModified(tempData[index], apiData[index]);
        setTableData(tempData);
    };

    const handleEditGiftValue = (index, value, keyName) => {
        const tempData = [...giftData];
        if (keyName === 'anniversaryValue') {
            tempData[index][keyName] = value;
        } else {
            const pointValue = !value ? '' : Math.abs(value);
            tempData[index][`${keyName}`] = pointValue;
        }
        tempData[index].modified = isModified(tempData[index], apiData[index]);
        setTableData(tempData);
    };

    const setTableData = (tableData) => {
        const tempData = tableData.map((rowData, index) => {
            return {
                id: rowData?.country,
                index: index,
                row: [
                    <div className='country-item-container' key={rowData?.country}>
                        <img
                            src={getCountryFlagURL(rowData?.country)}
                            alt='countryFlag'
                            width={'24px'}
                            height={'16px'}
                        />
                        <EllipsisText text={rowData?.country} maxWidth={'80px'} customClassName='country-name' />
                        <Ellipse />
                        <p>{rowData?.count} users</p>
                    </div>,
                    <GiftValue
                        key={rowData?.country}
                        index={index}
                        currency={rowData?.currency}
                        value={rowData?.birthDateValue}
                        selected={rowData?.status}
                        keyName='birthDateValue'
                        handleEditValue={handleEditGiftValue}
                        disable={!isBirthdayEnabled}
                    />,
                    <GiftValue
                        key={rowData?.country}
                        index={index}
                        currency={rowData?.currency}
                        value={rowData?.anniversaryValue}
                        selected={rowData?.status}
                        keyName='anniversaryValue'
                        type='text'
                        handleEditValue={handleEditGiftValue}
                        disable={!isWorkAnniversaryEnabled}
                    />,
                    <CustomSwitch
                        data-testid='custom-switch'
                        key={rowData?.country}
                        checked={rowData?.status}
                        onClick={() => handleStatus(index, !rowData?.status)}
                        name='status'
                    />,
                ],
            };
        });
        setTableGiftData(tempData);
    };

    const hasValidData = () => {
        const hasEmpty = find(giftData, (item) => item?.birthDateValue === '' || item?.anniversaryValue === '');
        if (hasEmpty) {
            showErrorMessage(`Value cannot be empty`);
            return false;
        }
        return true;
    };

    const handleSaveGiftValues = () => {
        if (hasValidData()) {
            handleSave(giftData);
        }
    };

    useEffect(() => {
        setTableData(giftData);
    }, [giftData]);

    return (
        <div className='gift-values-modal-container'>
            <CountryRewardsModal
                open={open}
                onClose={onClose}
                header={header}
                data={tableGiftData}
                noSortColumns={noSortColumns}
                loading={loading}
                columnWidth={columnWidth}
                heading='Configure gift values for celebrations'
                onSave={handleSaveGiftValues}
                disableSave={disableSave}
                width='872px'
                history={history}
            />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    apiData: PropTypes.object,
    giftData: PropTypes.array,
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
    isBirthdayEnabled: PropTypes.bool,
    isWorkAnniversaryEnabled: PropTypes.bool,
    history: PropTypes.object,
};

export default Index;
