import React, { useState } from 'react';
import TitleBar from 'components/ReusableComponents/TitleBar';
import Rewards from './Rewards';
import { CATALOG } from './constant';

const CatalogHome = ({ history }) => {
    const [reward, setReward] = useState(undefined);
    const [showAddCustomRewardButton, setShowAddCustomRewardButton] = useState(true);

    return (
        <div>
            <TitleBar title='Catalog'>
                {showAddCustomRewardButton && (
                    <button className='ew-btn ob-btn' onClick={() => history.push(CATALOG.CREATE_CUSTOM_REWARD)}>
                        +Add Custom Reward
                    </button>
                )}
            </TitleBar>
            <Rewards
                history={history}
                reward={reward}
                setReward={setReward}
                setShowAddCustomRewardButton={setShowAddCustomRewardButton}
            />
        </div>
    );
};

export default CatalogHome;
