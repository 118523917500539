import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({
    inputID,
    label,
    labelClass,
    value,
    placeholder,
    width,
    height,
    handleChange,
    inputClass,
    inputType = 'text',
    textArea = false,
    inputRef,
    isRequired = false,
    optional,
}) => {
    return (
        <div className='input-field'>
            {label && (
                <div className='input-label-container'>
                    <p className={clsx({ label: true, [labelClass]: labelClass })}>
                        {label}
                        {isRequired && <span className='is-required'>*</span>}
                    </p>
                    {optional && <span className=' is-optional'>(Optional)</span>}
                </div>
            )}

            {!textArea && (
                <input
                    style={{ width, ...(height && { height }) }}
                    type={inputType}
                    placeholder={placeholder}
                    className={clsx({ [inputClass]: inputClass })}
                    {...(inputRef
                        ? { ref: inputRef }
                        : { value, onChange: (e) => handleChange(inputID, e.target.value) })}
                />
            )}
            {textArea && (
                <textarea
                    style={{ width, ...(height && { height }) }}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => handleChange(inputID, e.target.value)}
                    className={clsx({ [inputClass]: inputClass })}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    inputID: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    handleChange: PropTypes.func,
    inputClass: PropTypes.string,
    inputType: PropTypes.string,
    textArea: PropTypes.bool,
    inputRef: PropTypes.func,
    isRequired: PropTypes.bool,
    optional: PropTypes.bool,
};

export default Index;
