import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

const AnswerBox = ({ enps, val, translateRating, translateEmoji }) => {
    const { companyName } = useSelector(mapStateToProps, shallowEqual);

    const eNPSQuestionsList = [
        `On a scale from 1-10, how likely are you to recommend ${companyName} as a workplace?`,
        'What is the main reason for your score ?',
    ];

    if (enps) {
        if (val.type === 'MULTIPLE_CHOICE') {
            return (
                <div className='question-answer'>
                    <ul className='multiple-answer'>
                        {val.answer.map((ans, i) => (
                            <li key={val.question}>{ans}</li>
                        ))}
                    </ul>
                </div>
            );
        } else if (val.type === 'RATING' && eNPSQuestionsList.includes(val.question)) {
            return (
                <div className='question-answer'>
                    {val.answer && (
                        <p className='single-answer d-flex align-items-center'>
                            <span className={`rating-chip ${translateRating(val.answer[0])?.toLowerCase()}`}>
                                <span className='rating-value fw-600'>{val.answer[0]}/10</span>
                            </span>{' '}
                            <span className='rating-text'>{translateRating(val.answer[0])}</span>{' '}
                            <span
                                className='emoji'
                                data-toggle='tooltip'
                                data-placement='top'
                                title={translateRating(val.answer[0])}
                            >
                                {translateEmoji(val.answer[0])}
                            </span>
                        </p>
                    )}
                </div>
            );
        } else if (val.type === 'LONG_TEXT' && eNPSQuestionsList.includes(val.question)) {
            return (
                <div className='question-answer'>
                    {val.answer && (
                        <div className='single-answer row'>
                            <div className='col-10'>
                                <ul className='ml-4'>
                                    <li>{val.answer[0]}</li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className='question-answer'>{val.answer && <p className='single-answer'>{val.answer[0]}</p>}</div>
            );
        }
    } else {
        return (
            <div className='question-answer'>
                {val.type === 'MULTIPLE_CHOICE' ? (
                    <ul className='multiple-answer'>
                        {val.answer.map((ans) => (
                            <li key={val.question}>{ans}</li>
                        ))}
                    </ul>
                ) : (
                    val.answer && <p className='single-answer'>{val.answer[0]}</p>
                )}
            </div>
        );
    }
};

const mapStateToProps = (state) => ({ companyName: state.Workspace.companyName });

AnswerBox.propTypes = {
    enps: PropTypes.object,
    val: PropTypes.object,
    translateRating: PropTypes.func,
    translateEmoji: PropTypes.func,
};

export default AnswerBox;
