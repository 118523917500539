import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import Table from 'components/ReusableComponents/Table';
import { ANALYTICS } from '../../constants';
import AvatarGroupPopup from 'components/ReusableComponents/AvatarGroupPopup';
import { ReactComponent as EmptyMember } from 'Assets/images/empty-member.svg';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import Tooltip from 'components/Styles/Tooltip';
import EmptyScreen from 'components/ReusableComponents/EmptyScreen';
import { HISTORY_FROM, COHORT_TYPE } from 'constants.js';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { GET_MANGER_ANALYTICS_TABLE_DATA } from 'Services/apiKeys';
import { getTableHeadingTitle, getENPSColumnData, showSnackBarMessage } from 'utils/HelperFunctions';
import { getAnalyticsPageTableData } from 'Services/apiFunctions';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ToolTipInfo from '../../ToolTipInfo';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import EWButton from 'components/ReusableComponents/EWButton';

let isEnpsSet = false;
let managerTableHeader = ['NAME', 'TEAM RECOGNITIONS', 'ENGAGEMENT RATE', 'MOST RECOGNIZED FOR'];
let managersNoSortColumns = [0, 3, 4];
let managerColumnWidth = ['28%', '19%', '19%', '34%'];
let sortKeys = ['name', 'totalRecognitions', 'engagementRate', 'mostRecognizedFor'];

const Index = ({ history, selectedDateRange, selectedCohort, isFullView = false }) => {
    const { SetSnackbar } = useToaster();
    const { config } = useSelector(mapStateToProps, shallowEqual);
    const [showEnpsColumn, setShowEnpsColumn] = useState(false);
    const [page, setPage] = useState(1);
    const [managerTableData, setManagerTableData] = useState({});
    const [managerFilter, setManagerFilter] = useState({
        page,
        cohortId: selectedCohort?._id,
        cohortType: COHORT_TYPE[selectedCohort?.type?.toUpperCase()],
        queryType: 'manager',
        startDate: selectedDateRange?.startDate,
        endDate: selectedDateRange?.endDate,
        limit: isFullView ? 10 : 5,
        view: isFullView ? 'full' : 'card',
    });

    useEffect(() => {
        setManagerFilter({
            ...managerFilter,
            cohortId: selectedCohort?._id,
            cohortType: COHORT_TYPE[selectedCohort?.type?.toUpperCase()],
            startDate: selectedDateRange?.startDate,
            endDate: selectedDateRange?.endDate,
        });
    }, [selectedCohort, selectedDateRange]);

    if (showEnpsColumn) {
        isEnpsSet = true;
        managerTableHeader = ['NAME', 'TEAM RECOGNITIONS', 'ENGAGEMENT RATE', 'MANAGER ENPS', 'MOST RECOGNIZED FOR'];
        sortKeys = ['name', 'totalRecognitions', 'engagementRate', 'eNPS', 'mostRecognizedFor'];
        managerColumnWidth = ['22%', '19%', '17%', '19%', '23%'];
        managersNoSortColumns = [0, 4];
    }

    const getLoadingOrEmptyStateManagerComponent = (isFetched, isRefetching) => {
        return isFetched && !isRefetching ? (
            <EmptyScreen
                btnName='Assign Managers'
                action={() => history.push('/dashboard/users')}
                image={<EmptyMember />}
            />
        ) : (
            <CustomLoader />
        );
    };

    const formatManagerTableData = (apiData = []) => {
        return apiData.map((manager) => ({
            id: manager?._id,
            row: [
                <>
                    <img
                        src={manager?.imageURL || require('Assets/images/defaultUser.png')}
                        className='avatar-32 mr-3'
                        alt=''
                    />
                    <EWButton
                        buttonStyleClass='ew-btn tb-btn pointer-cursor'
                        plainTextButton={true}
                        primary={false}
                        onClick={handlePageRedirectionWithinSubMenu({
                            history,
                            redirectionURL: `/dashboard/profile/${manager?._id}`,
                            navMenuCode: HISTORY_FROM.ANALYTICS,
                        })}
                    >
                        <EllipsisText maxWidth='124px' text={manager?.name} />
                    </EWButton>
                    {manager?.team?.length && (
                        <AvatarGroupPopup
                            assignedMembers={manager.team}
                            history={history}
                            navigateTo={HISTORY_FROM.ANALYTICS}
                            className='avatar-group'
                        />
                    )}
                </>,
                <span className={`${isFullView ? 'ac-medium-font' : 'ac-dark-font'} pointer-cursor`} key={'info'}>
                    {manager.totalRecognitions}
                    <Tooltip
                        white
                        arrow
                        title={
                            <ToolTipInfo
                                givenRecognitions={manager.givenRecognitions}
                                receivedRecognitions={manager.receivedRecognitions}
                            />
                        }
                    >
                        <InfoIcon className='ac-info-icon' />
                    </Tooltip>
                </span>,
                <span key={manager._id} className={`${isFullView ? 'ac-medium-font' : 'ac-dark-font'} pointer-cursor`}>
                    {manager?.engagementRate ? `${manager.engagementRate?.toFixed(2)}%` : '-'}
                </span>,
                ...getENPSColumnData(showEnpsColumn || isEnpsSet, manager?.eNPS),
                <span className={isFullView ? 'ac-medium-font' : 'ac-light-font'} key={'managerRecog' + manager?._id}>
                    <EllipsisText white maxWidth='200px'>
                        {manager?.mostRecognizedFor?.trim() || '-'}
                    </EllipsisText>
                </span>,
            ],
        }));
    };

    const { isLoading, isFetching, isFetched, isRefetching } = useQuery(
        [GET_MANGER_ANALYTICS_TABLE_DATA, managerFilter],
        () => getAnalyticsPageTableData(managerFilter),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setManagerTableData(data);
                setShowEnpsColumn(config?.isENPSFlowCompleted && data?.eNPSExists);
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        }
    );

    if (isLoading) {
        return (
            <div className='ac-table-container'>
                <CustomLoader />
            </div>
        );
    }

    let managerAnalyticsData = {
        data: formatManagerTableData(managerTableData?.data),
        total: managerTableData?.total,
    };

    const showEmptyState = managerAnalyticsData?.data?.length === 0;

    const handleManagerTableSearch = debounce((value) => {
        setPage(1);
        setManagerFilter({ ...managerFilter, page: 1, searchText: value });
    }, 300);

    const handleManagerTableSort = (index) => {
        const sortValue = -(managerFilter.sortValue || -1);
        setManagerFilter({ ...managerFilter, page, sortKey: sortKeys[index], sortValue });
    };

    let paginationProps = null;

    if (isFullView) {
        paginationProps = {
            currentPage: page,
            totalCount: managerAnalyticsData?.total,
            setCurrentPage: setPage,
            onChange: (page) => setManagerFilter({ ...managerFilter, page }),
        };
    }

    return (
        <div className='ac-table-container'>
            <div className='ac-total-count'>
                <p>{getTableHeadingTitle(managerAnalyticsData?.total, 'Manager')}</p>
                {isFullView && (
                    <CustomSearchBox
                        width='35%'
                        handleSearch={(e) => handleManagerTableSearch(e.target.value)}
                        placeholder='Search by manager name'
                    />
                )}
            </div>
            {!showEmptyState ? (
                <>
                    <Table
                        header={managerTableHeader}
                        data={managerAnalyticsData.data}
                        noShadow={true}
                        loading={isFetching}
                        noSortColumns={managersNoSortColumns}
                        handleSort={handleManagerTableSort}
                        className='margin-top-8'
                        columnWidth={managerColumnWidth}
                        {...(isFullView && { paginationProps })}
                    />
                    {managerAnalyticsData?.total > 5 && !isFullView && (
                        <EWButton
                            buttonStyleClass='ac-view-all'
                            plainTextButton={true}
                            primary={false}
                            onClick={() => history.push(ANALYTICS.ANALYTICS_MANAGERS)}
                        >
                            {`View all ${managerAnalyticsData?.total} managers`} <ArrowForwardIcon />
                        </EWButton>
                    )}
                </>
            ) : (
                getLoadingOrEmptyStateManagerComponent(isFetched, isRefetching)
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    config: state.Workspace.todo,
});

Index.propTypes = {
    history: PropTypes.object,
    selectedDateRange: PropTypes.object,
    selectedCohort: PropTypes.object,
    isFullView: PropTypes.bool,
};

export default Index;
