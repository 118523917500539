import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { ReactComponent as EWLogo } from 'Assets/images/EW-logo-2.svg';
import { ReactComponent as ModuleBanner } from 'Assets/images/EW-module-banner.svg';
import './style.scss';
import InputField from 'components/ReusableComponents/InputField';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import clsx from 'clsx';
import { ReactComponent as RefreshImg } from 'Assets/images/refresh.svg';
import { getTimeZoneList } from 'utils/timezoneUtil';
import { isEmptyObject, showSnackBarMessage } from 'utils/HelperFunctions';
import ErrorField from 'components/ReusableComponents/ErrorField';
import { GET_GLOBAL_SETTINGS } from 'Services/apiKeys';
import { getGlobalSettings, configureGlobalSettings } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import EWButton from 'components/ReusableComponents/EWButton';
import { countryOptions, UPDATE_TYPE } from 'constants.js';
import { getCountries } from 'utils/countryUtils';
import { find } from 'lodash';

const Index = ({ history }) => {
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();

    const [teamName, setTeamName] = useState('');
    const [phone, setPhone] = useState('');

    // channel related states
    const [channelSelected, setChannelSelected] = useState({});
    const [channelList, setChannelList] = useState([]);
    const [refresh, setRefresh] = useState(false);

    // country + timezone states
    const [country, setCountry] = useState('');
    const [timezoneList, setTimezoneList] = useState([]);
    const [timezone, setTimezone] = useState('');

    // error state
    const [errorFields, setErrorFields] = useState({});
    const [loading, setLoading] = useState(false);

    const setAccountData = (data) => {
        setTeamName(data?.teamName);
        if (data?.country) {
            setCountry({ label: data.country, value: data.country });
            data?.timezone && setTimezone({ label: data.timezone, value: data.timezone });
        }
        const channels = data?.channels?.map((channel) => ({ ...channel, label: channel.name, value: channel.name }));
        setChannelList(channels || []);
        setRefresh(false);
        setLoading(false);
    };

    useQuery([GET_GLOBAL_SETTINGS], getGlobalSettings, {
        onSuccess: (data) => setAccountData(data),
        onError: (err) => {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
            setLoading(false);
        },
    });

    const { mutate: configureSettings } = useMutation(configureGlobalSettings, {
        onSuccess: () => {
            updateWorkspaceDetails(dispatch, {
                workspaceCountry: find(getCountries(), (item) => item.country === country?.label),
            });
            history.replace('/onboarding/module');
        },
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setLoading(false);
        },
    });

    const handleSubmit = () => {
        /*
         * CHECKS:
         * worspaceName trim + Required
         * phoneNumber
         *   - trim
         *   - Required
         *   - text size should be 7 to 15
         * channel Required
         *
         * */
        const errorObj = {};
        const bodyData = {
            teamName: teamName.trim(),
            phoneNumber: phone.trim(),
            channel: {
                channelName: channelSelected?.label,
                channelId: channelSelected?.id,
                isPrivate: channelSelected?.is_private,
            },
            country: country?.label,
            timezone: timezone?.label,
        };

        if (bodyData.teamName.length === 0) {
            errorObj.teamName = 'Workspace name is required';
        } else if (bodyData.teamName.length < 3) {
            errorObj.teamName = 'Workspace name is too short';
        }

        if (bodyData.phoneNumber.length < 7 || bodyData.phoneNumber.length > 15) {
            errorObj.phoneNumber = 'Invalid phone number';
        }

        if (!bodyData.channel.channelName) {
            errorObj.channelSelected = 'Channel selection is required';
        }

        if (!bodyData.country) {
            errorObj.country = 'Please select country';
        }

        if (!bodyData.timezone) {
            errorObj.timezone = 'Please select timezone';
        }

        if (!isEmptyObject(errorObj)) {
            setErrorFields(errorObj);
            return false;
        }

        setLoading(true);
        configureSettings(bodyData);
    };

    const handleChange = (type, value) => {
        switch (type) {
            case UPDATE_TYPE.WORKSPACE:
                setTeamName(value);
                setErrorFields({ ...errorFields, teamName: '' });
                break;
            case UPDATE_TYPE.PHONE:
                setPhone(!value || isNaN(value) ? '' : value);
                setErrorFields({ ...errorFields, phoneNumber: '' });
                break;
            case UPDATE_TYPE.COUNTRY:
                setCountry(value);
                setTimezone({ label: '', value: '' });
                setErrorFields({ ...errorFields, country: '' });
                break;
            case UPDATE_TYPE.TIMEZONE:
                setTimezone(value);
                setErrorFields({ ...errorFields, timezone: '' });
                break;
            case UPDATE_TYPE.CHANNEL:
                setChannelSelected(value);
                setErrorFields({ ...errorFields, channelSelected: '' });
                break;
            case UPDATE_TYPE.REFRESH:
                setRefresh(!refresh);
                queryClient.invalidateQueries(GET_GLOBAL_SETTINGS);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        // we are deleting the timestamps to show map managers modal on fresh install in organisation page.
        localStorage.removeItem('timestamp');
        if (country?.value) {
            setTimezoneList(getTimeZoneList(country?.value));
        }
    }, [country]);

    return (
        <div className='d-flex height-100-vh bg-white'>
            <div className='module-left'>
                <div className='module-logo'>
                    <EWLogo />
                </div>
                <div className='module-description'>
                    <div className='banner-heading'>Welcome to EngageWith</div>
                    <div className='banner-sub-heading'>
                        According to a Great Place To Work survey, employees who are appreciated by their peers are{' '}
                        <strong>2.6x</strong> more productive when compared to under-appreciated employees.
                    </div>
                </div>
                <div className='banner'>
                    <ModuleBanner />
                </div>
            </div>
            <div className='module-right-container'>
                <p className='title'>Setup your account</p>
                <p className='subtitle'>Help us with some general information to start with </p>

                <InputField
                    inputID='workspaceName'
                    label='Workspace Name  '
                    labelClass='input-label'
                    placeholder='Enter workspace name'
                    inputType='text'
                    inputClass={errorFields.teamName && 'border-red'}
                    width='400px'
                    isRequired
                    value={teamName}
                    handleChange={(_id, value) => handleChange(UPDATE_TYPE.WORKSPACE, value)}
                />
                {errorFields.teamName && <ErrorField errorText={errorFields.teamName} className='error-class' />}

                <InputField
                    inputID='phoneNumber'
                    label='Phone Number '
                    placeholder='Enter Phone Number'
                    labelClass='input-label'
                    width='250px'
                    inputType='text'
                    inputClass={errorFields.phoneNumber && 'border-red'}
                    isRequired
                    value={phone}
                    handleChange={(_id, value) => handleChange(UPDATE_TYPE.PHONE, value)}
                />
                {errorFields.phoneNumber && <ErrorField errorText={errorFields.phoneNumber} className='error-class' />}

                <div className='channel-section'>
                    <CustomFilterDropdown
                        title='INTEGRATE CHANNEL '
                        dropDownID='select-channel'
                        filterOptions={channelList}
                        selectedName={channelSelected?.label ? `#${channelSelected?.label}` : 'Select Channel'}
                        handleSelection={(index) => handleChange(UPDATE_TYPE.CHANNEL, channelList?.[index])}
                        buttonStyleClass='channel-section-button'
                        singleSelect
                        optionsSelected={channelSelected?.label}
                        search
                        isRequired
                        customSearchText='Search Channel'
                    />
                    <button className='ew-btn tb-btn mt-3' onClick={() => handleChange(UPDATE_TYPE.REFRESH, {})}>
                        Refresh{' '}
                        <span className='margin-left-5'>
                            <RefreshImg className={clsx({ rotate: refresh })} />
                        </span>
                    </button>
                </div>
                {errorFields.channelSelected && <ErrorField errorText={errorFields.channelSelected} />}

                <p className='note-section'>
                    <b>Note:</b> Choose a team with most employee to add as active users in EngageWith.
                </p>

                <CustomFilterDropdown
                    search
                    title='Country'
                    filterOptions={countryOptions}
                    selectedName={country?.label || 'Select Country'}
                    optionsSelected
                    handleSelection={(index) => handleChange(UPDATE_TYPE.COUNTRY, countryOptions[index])}
                    buttonStyleClass='width-300 margin-bottom-24'
                    dropDownID='Country'
                    info='Employees added to EngageWith will be assigned this country by default.'
                    singleSelect
                />
                {errorFields.country && <ErrorField errorText={errorFields.country} className='error-class' />}
                <CustomFilterDropdown
                    title='TIME ZONE'
                    filterOptions={timezoneList}
                    selectedName={timezone?.label || 'Select Timezone'}
                    optionsSelected
                    handleSelection={(index) =>
                        handleChange(UPDATE_TYPE.TIMEZONE, getTimeZoneList(country?.value)[index])
                    }
                    buttonStyleClass='width-300 margin-bottom-24'
                    dropDownID='Timezone'
                    singleSelect
                    info='Communications from EngageWith will be scheduled based on this time zone'
                    disabled={!country?.value}
                />
                {errorFields.timezone && <ErrorField errorText={errorFields.timezone} className='error-class' />}

                <EWButton
                    buttonStyleClass='continue-button'
                    buttonText='Continue'
                    width='200px'
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
