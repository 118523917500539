import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import Stepper from './Stepper';
import './style.scss';
import { BeatLoader } from 'react-spinners';
import PulseStatus from 'components/Dashboard/Content/PulseSurvey/PulseHome/components/PulseStatus';

const Index = ({
    eNPS,
    step,
    setStep,
    handleContinue,
    pulse,
    pulseTitle,
    setPulseTitle,
    onClose,
    loading,
    fetchLoading,
    questionWarn,
    isEditingOnetime,
    isEditingRolling,
    isEditingRecurring,
    editType,
}) => {
    const actionText = isEditingOnetime || isEditingRecurring ? 'Update' : 'Launch';
    const buttonText = step === 2 ? actionText : 'Next';

    const handleNext = () => {
        handleContinue();
    };

    return (
        <div className='create-pulse-header-container'>
            <div className='pulse-header-top'>
                <div>
                    <div className='close-pulse-icon-container'>
                        <ClearIcon onClick={() => onClose()} />
                    </div>
                    {isEditingOnetime || isEditingRolling || isEditingRecurring ? (
                        <div className='pulse-non-editable-title'>
                            {pulseTitle} {!eNPS && <PulseStatus pulse={pulse} isEditingOneTime />}{' '}
                        </div>
                    ) : (
                        <input
                            type='text'
                            placeholder='Pulse title'
                            value={pulseTitle}
                            onClick={(event) => event.target.select()}
                            onChange={(event) => {
                                setPulseTitle(event.target.value);
                            }}
                            disabled={eNPS || fetchLoading}
                        />
                    )}
                </div>
                <button className='ew-btn pb-btn' onClick={handleNext} disabled={loading || fetchLoading}>
                    {loading ? <BeatLoader color='#ffffff' size={10} /> : buttonText}
                </button>
            </div>
            <Stepper
                step={step}
                setStep={setStep}
                warn1={questionWarn?.isInvalid}
                isEditingRolling={isEditingRolling}
                isEditingRecurring={isEditingRecurring}
                fetchLoading={fetchLoading}
                editType={editType}
            />
        </div>
    );
};

Index.propTypes = {
    eNPS: PropTypes.bool,
    step: PropTypes.number,
    setStep: PropTypes.func,
    handleContinue: PropTypes.func,
    pulse: PropTypes.object,
    pulseTitle: PropTypes.string,
    setPulseTitle: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    fetchLoading: PropTypes.bool,
    questionWarn: PropTypes.object,
    isEditingOnetime: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    editType: PropTypes.string,
};

export default Index;
