import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/ReusableComponents/Table';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as EmptyImage } from 'Assets/images/empty-flag.svg';
import './style.scss';
import { EW_LOGO_URL } from 'constants.js';
import { MY_ACTIVITIES_RECOGNITION_TABLE, STATUS } from './../../constants';
import { getRecievedRows, getRowData } from './RecognitionRow';

const Index = ({
    history,
    given,
    tableData,
    currentPage,
    setCurrentPage,
    totalCount,
    loading,
    filter,
    setFilter,
    from,
    handleViewMore,
}) => {
    const sortTags =
        from === STATUS.RECIEVED
            ? MY_ACTIVITIES_RECOGNITION_TABLE.RECIEVED.SORTCOLUMNS
            : MY_ACTIVITIES_RECOGNITION_TABLE.GIVEN.SORTCOLUMNS;
    const header =
        from === STATUS.RECIEVED
            ? MY_ACTIVITIES_RECOGNITION_TABLE.RECIEVED.HEADERS
            : MY_ACTIVITIES_RECOGNITION_TABLE.GIVEN.HEADERS;
    const noSortColumns = [5];

    const getSenderImageURL = (item) => {
        if (given) {
            return item?.receiver?.pictureURL;
        } else if (item?.recognitionType?.toLowerCase() === 'special rewards') {
            return EW_LOGO_URL;
        } else {
            return item?.sender?.pictureURL || require('Assets/images/defaultUser.png');
        }
    };

    const data =
        from === STATUS.RECIEVED
            ? getRecievedRows(tableData, getSenderImageURL, history)
            : getRowData(tableData, getSenderImageURL, handleViewMore, history);

    const handleSort = (index) => {
        const type = sortTags[index];
        const tempSort = { ...filter, sortBy: type };
        if (type === filter.sortBy) {
            tempSort.order = tempSort.order === 'ascending' ? 'descending' : 'ascending';
        } else {
            tempSort.order = 'ascending';
        }
        setCurrentPage(1);
        setFilter({ ...filter, sortBy: tempSort.sortBy, order: tempSort.order });
    };

    const paginationProps = {
        fixedBottom: true,
        currentPage,
        totalCount,
        setCurrentPage,
    };
    const columnWidth =
        from === STATUS.RECIEVED ? ['15%', '15%', '20%', '15%', '35%'] : ['15%', '20%', '20%', '25%', '15%', '5%'];

    const emptyScreenContent = {
        image: <EmptyImage />,
        subheadingText: 'No results found',
    };

    if (data.length === 0) {
        return <EmptyContent {...emptyScreenContent} />;
    }

    return (
        <Table
            header={header}
            data={data}
            noSortColumns={noSortColumns}
            paginationProps={paginationProps}
            columnWidth={columnWidth}
            noShadow
            loading={loading}
            handleSort={handleSort}
        />
    );
};

Index.propTypes = {
    history: PropTypes.object,
    given: PropTypes.bool,
    tableData: PropTypes.array,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    totalCount: PropTypes.number,
    loading: PropTypes.bool,
    filter: PropTypes.object,
    setFilter: PropTypes.func,
    from: PropTypes.string,
    handleViewMore: PropTypes.func,
};

export default Index;
