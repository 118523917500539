import { useEndPulse } from 'components/Dashboard/Content/PulseSurvey/PulseHome/ApiHooks';
import DoughnutChart from 'components/ReusableComponents/DoughnutChart';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCommonContext } from 'Context/CommonContext';
import { toggleFullscreen } from 'redux/layout/actions';
import { getOrgDataForPulse, getCategorizedENPSData } from 'Services/apiFunctions';
import { GET_ORG_DATA_FOR_PULSE } from 'Services/apiKeys';
import { isEmptyObject, showSnackBarMessage, formatDate } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { filter, isArray } from 'lodash';
import {
    noSortColumns,
    columnWidth,
    orgFilter,
    dateFilters,
    benchMarks,
    eNPS_TYPE,
    eNPS_STATUS,
    INSTANCES,
    CONFIRM_MODALS_DATA,
    EDIT_INSTANCES,
    EDIT_TYPE,
    eNPS_ROUTE,
    INSTANCES_VALUES,
} from '../constant';
import { actions } from '../../../../../redux/workspace/actionEvents';
import OrganisationStrip from '../components/OrganisationStrip';
import OrgAnalysis from '../components/OrganizationAnalysis';
import CompanyReport from '../components/CompanyReport';
import ENPSScore from '../components/ENPSScore';
import ScoreBar from '../components/ScoreBar';
import EnpsHead from '../components/EnpsHead';
import Scale from '../components/Scale';
import ConfirmationModal from '../../PulseSurvey/PulseHome/components/ConfirmationModal';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { setEnpsPulseCreated } = useCommonContext();
    const { todo, companyName } = useSelector(mapStateToProps, shallowEqual);

    const [dateFilter, setDateFilter] = useState(dateFilters || []);
    const [score, setScore] = useState({
        workSpaceEnps: {
            label: companyName,
        },
    });
    const [confirmModal, setConfirmModal] = useState({ open: false, type: '', id: '' });
    const [instanceType, setInstanceType] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [otherIndustry, setOtherIndustry] = useState(benchMarks[0]);
    const [dateIndex, setDateIndex] = useState(0);
    const [pulseID, setPulseID] = useState(null);
    const [orgPulseID, setOrgPulseID] = useState(null);
    const [loading, setLoading] = useState(false);

    const onFetch = (fetchData) => {
        if (Object.keys(fetchData)?.length === 1 && fetchData?.hasOwnProperty('canLaunchNewEnps')) {
            history.push(eNPS_ROUTE.SETUP_PAGE);
        } else {
            setOrgPulseID(fetchData?.allIssues?.[dateIndex]?._id);
            const dateArray =
                fetchData?.allIssues?.map(
                    (enpsPulse, index) =>
                        (index < 1 ? 'Latest: ' : '') + formatDate(enpsPulse?.sentAt, enpsPulse?.endAt)
                ) || [];
            const respCount = fetchData?.enpsData?.totalRespondents || 0;
            setDateFilter(dateArray);
            setScore({
                ...score,
                pulseId: fetchData?.pulseId,
                date: dateArray[dateIndex],
                launch: fetchData?.canLaunchNewEnps,
                status: fetchData?.status?.toLowerCase(),
                scheduledAt: fetchData?.nextLaunchDate,
                enps: respCount ? fetchData?.enpsData : undefined,
                previousScore: fetchData?.previousScore ?? 0,
                totalRespondents: fetchData?.enpsData?.totalRespondents,
                totalRequestedUser: fetchData?.enpsData?.totalRequestedUser,
                type: fetchData?.type,
                draftId: fetchData?.draftedEnpsPulseId,
                isRecurringStopped: fetchData?.isRecurringStopped,
                currentIssueStatus: fetchData?.allIssues?.[0]?.status?.toLowerCase(),
                partialRespondents: fetchData?.enpsData?.partialRespondents,
                workSpaceEnps: {
                    label: companyName,
                    value: respCount ? Math.round(fetchData?.enpsData?.eNPSScore) : undefined,
                },
            });
        }
        if (fetchData.hasOwnProperty('canLaunchNewEnps')) {
            dispatch({
                type: actions.UPDATE_WORKSPACE_DETAILS,
                payload: { canLaunchNewEnps: fetchData.canLaunchNewEnps },
            });
        }
    };

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);

    const onEndPulse = () => {
        showSnackBarMessage(SetSnackbar, 'success', 'eNPS Ended Successfully');
        setEnpsPulseCreated(false);
        setScore({ ...score, endPulse: false });
        queryClient.invalidateQueries(GET_ORG_DATA_FOR_PULSE);
    };

    // API Calls -------------
    const { data, isFetching: fetchLoading } = useQuery([GET_ORG_DATA_FOR_PULSE, pulseID], getOrgDataForPulse, {
        keepPreviousData: true,
        onSuccess: onFetch,
        onError,
    });
    const { mutate: endPulse } = useEndPulse({
        key: 'EndEnpsPulse',
        onSuccess: onEndPulse,
        onError,
    });

    const [selectedOrgFilter, setSelectedOrgFilter] = useState(orgFilter[0]);
    const [orgData, setOrgData] = useState(undefined);
    const [orgDisplayData, setOrgDisplayData] = useState();

    const [orgAnalysis, setOrgAnalysis] = useState({
        org: orgFilter[0].label,
        orgSearch: '',
        orgSetUp: false,
        orgManagerSetUp: false,
    });

    const getOrgData = async () => {
        setLoading(true);
        try {
            const categoryData = await getCategorizedENPSData({
                type: selectedOrgFilter.value,
                pulseIssueId: orgPulseID,
            });
            setOrgAnalysis({
                ...orgAnalysis,
                orgSetUp: todo?.isManagerFlowCompleted,
                orgManagerSetUp: todo?.isManagerFlowCompleted && categoryData?.length > 0,
            });
            setOrgData(isArray(categoryData) ? categoryData : []);
            setOrgDisplayData(isArray(categoryData) ? categoryData : []);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    useEffect(() => {
        if (!isEmptyObject(todo)) {
            setOrgAnalysis({
                ...orgAnalysis,
                orgSetUp: todo?.isManagerFlowCompleted,
            });

            if (data && !todo?.isENPSFlowCompleted) {
                history.push({ pathname: eNPS_ROUTE.SETUP_PAGE, state: { draftId: data?.draftedEnpsPulseId } });
            } else {
                toggleFullscreen(dispatch, false);
                getOrgData();
            }
        }
    }, [orgPulseID, selectedOrgFilter, todo, data]);

    useEffect(() => {
        if (selectedOption === INSTANCES_VALUES.ONGOING) {
            setInstanceType('ongoing');
        } else if (selectedOption === INSTANCES_VALUES.FUTURE) {
            setInstanceType('future');
        } else {
            setInstanceType('all');
        }
    }, [selectedOption]);

    const handleConfirmationModal = () => {
        if (confirmModal.type === CONFIRM_MODALS_DATA.EDIT_ALL_RECURRING_SURVEY.name) {
            history.push({
                pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                state: { editId: confirmModal.editId, editType: selectedOption || EDIT_TYPE.ONGOING, isOngoing: true },
            });
        } else {
            endPulse({ pulseId: data?.pulseId, instanceType });
            updateConfirmModalState({
                open: false,
            });
        }
    };
    const handleOnClose = () => {
        updateConfirmModalState({ open: false });
        setSelectedOption('');
    };

    const stopSurvey = (item) => {
        if (item.type === eNPS_TYPE.ONE_TIME && item.status === eNPS_STATUS.ACTIVE) {
            updateConfirmModalState({
                open: true,
                type: CONFIRM_MODALS_DATA.STOP_ONGOING_SURVEY.name,
                id: item._id,
                enpsType: item.type,
            });
        } else if (item.type === eNPS_TYPE.RECURRING && item.isRecurringStopped) {
            updateConfirmModalState({
                open: true,
                type: CONFIRM_MODALS_DATA.STOP_ONGOING_SURVEY.name,
                id: item._id,
            });
        } else if (
            item.type === eNPS_TYPE.RECURRING &&
            item.currentIssueStatus === eNPS_STATUS.COMPLETED &&
            !item.isRecurringStopped
        ) {
            updateConfirmModalState({
                open: true,
                type: CONFIRM_MODALS_DATA.STOP_RECURRING_SURVEY.name,
                id: item._id,
                enpsType: item.type,
            });
        } else if (item.status === eNPS_STATUS.SCHEDULED) {
            updateConfirmModalState({
                open: true,
                type: CONFIRM_MODALS_DATA.DELETE_SURVEY.name,
                id: item._id,
            });
        } else if (item.type === eNPS_TYPE.RECURRING && item.status === eNPS_STATUS.ACTIVE) {
            updateConfirmModalState({
                open: true,
                type: CONFIRM_MODALS_DATA.STOP_RECURRING.name,
                id: item._id,
                height: '380px',
                showRadioButtons: true,
                instances: INSTANCES,
                enpsType: item.type,
            });
        }
    };
    const editSurvey = (item) => {
        if (item.type === eNPS_TYPE.RECURRING && item.isRecurringStopped) {
            //if only future instances of pulse is stopped
            history.push({
                pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                state: {
                    editId: item?.pulseId,
                    isRecurringStopped: item.isRecurringStopped,
                    isOngoing: true,
                },
            });
        } else if (
            item.type === eNPS_TYPE.RECURRING &&
            item.currentIssueStatus === eNPS_STATUS.COMPLETED &&
            !item.isRecurringStopped
        ) {
            history.push({
                pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                state: {
                    editId: item?.pulseId,
                    isOngoing: true,
                },
            });
        } else if (item.type === eNPS_TYPE.RECURRING && item.status === eNPS_STATUS.ACTIVE) {
            // for active recurring pulse
            updateConfirmModalState({
                open: true,
                type: CONFIRM_MODALS_DATA.EDIT_ALL_RECURRING_SURVEY.name,
                id: item._id,
                showRadioButtons: true,
                instances: EDIT_INSTANCES,
                height: '230px',
                isEditing: true,
                editId: item?.pulseId,
            });
        } else if (item.status === eNPS_STATUS.SCHEDULED) {
            // for scheduled enps
            history.push({
                pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                state: { editId: item?.pulseId, isOngoing: false, draftOrScheduled: true },
            });
        } else if (item.type === eNPS_TYPE.ONE_TIME && item.status === eNPS_STATUS.ACTIVE) {
            history.push({
                pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                state: { editId: item?.pulseId, editType: EDIT_TYPE.ONGOING, isOngoing: true },
            });
        }
    };

    const handleChange = (label, value) => {
        // eslint-disable-next-line default-case
        switch (label) {
            case 'dateFilter':
                setPulseID(data?.allIssues[value]?._id);
                setDateIndex(value);
                break;
            case 'endPulse':
                stopSurvey(score);
                break;
            case 'viewInsights':
                history.push(`/dashboard/eNPS/insights/${data?.pulseId}`, { pulseIssueId: orgPulseID });
                break;
            case 'orgFilter':
                setSelectedOrgFilter(orgFilter[value]);
                break;
            case 'orgSearch':
                setOrgAnalysis({ ...orgAnalysis, orgSearch: value });
                handleSearch(value);
                break;
            case 'orgSetUp':
                history.push(`/dashboard/users?type=Departments`);
                break;
            case 'orgManagerSetUp':
                history.push(`/dashboard/users?mapManager=true`);
                break;
            case 'otherIndustry':
                setOtherIndustry(benchMarks[value]);
                break;
            case 'edit':
                editSurvey(score);
                break;
        }
    };

    const handleSearch = (value) => {
        if (value) {
            const searchData = filter(orgData, (orgDataItem) =>
                orgDataItem?.name?.toLowerCase().includes(value.toLowerCase())
            );
            setOrgDisplayData(searchData);
        } else {
            setOrgDisplayData(orgData);
        }
    };

    const tableData = {
        data: orgDisplayData?.map((dataItem, index) => ({
            id: index + 1,
            row: [
                <OrganisationStrip
                    key={dataItem.id}
                    name={dataItem?.name}
                    assignedMembers={dataItem?.team}
                    selectedOrgFilter={selectedOrgFilter}
                    history={history}
                />,
                <ScoreBar
                    key={dataItem.id}
                    enpsInfo={dataItem?.type}
                    responses={dataItem?.totalRespondents}
                    totalRespondents={dataItem?.team?.length}
                />,
            ],
        })),
        noSortColumns,
        columnWidth,
    };
    const scoreProps = { score, dateFilter, handleChange };

    const updateConfirmModalState = (options = {}) => {
        setConfirmModal(() => ({
            ...options,
            ...(options.type ? { type: options.type } : {}),
            ...(options.id ? { id: options.id } : {}),
            ...(options.height ? { height: options.height } : {}),
            ...(options.showRadioButtons !== undefined ? { showRadioButtons: options.showRadioButtons } : {}),
            ...(options.instances ? { instances: options.instances } : {}),
            ...(options.isEditing ? { isEditing: options.isEditing } : {}),
            ...options.enpsType,
        }));
    };

    return (
        <div className='score'>
            {/* --- Heading --- */}

            <EnpsHead {...scoreProps} loading={fetchLoading} history={history} />
            {/* --- Top-Section --- */}
            <ENPSScore {...scoreProps} loading={fetchLoading} history={history}>
                <DoughnutChart
                    score={score?.enps?.eNPSScore?.toFixed(1)}
                    increasedBy={score?.enps?.eNPSScore - score?.previousScore}
                    promoters={score?.enps?.Promoter}
                    detractors={score?.enps?.Detractor}
                    passives={score?.enps?.Passive}
                    respondents={score?.totalRespondents}
                    respondentTotal={score?.totalRequestedUser}
                    previousScore={score?.previousScore}
                />

                <CompanyReport
                    history={history}
                    reportInfo={score?.workSpaceEnps}
                    pulseID={orgPulseID}
                    handleChange={handleChange}
                />
                <Scale
                    category
                    label={otherIndustry?.label}
                    value={otherIndustry?.value}
                    color='#5d5cf5'
                    list={benchMarks}
                    handleChange={handleChange}
                />
            </ENPSScore>

            {/* --- Bottom-Section  --- */}
            <OrgAnalysis
                orgAnalysis={orgAnalysis}
                data={tableData}
                handleChange={handleChange}
                selectedOrgFilter={selectedOrgFilter}
                orgFilter={orgFilter}
                loading={fetchLoading || loading}
            />
            {/* --- Modal --- */}

            <ConfirmationModal
                open={confirmModal.open}
                modalData={CONFIRM_MODALS_DATA[confirmModal.type]}
                onConfirm={handleConfirmationModal}
                onClose={handleOnClose}
                loading={false}
                height={confirmModal.height}
                showRadioButtons={confirmModal.showRadioButtons}
                instances={confirmModal.instances}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isRollingPulse={confirmModal.isRollingPulse}
                questionsToBeRolled={confirmModal.questionsToBeRolled}
                isEditing={confirmModal.isEditing}
                disableButton={selectedOption === '' && confirmModal.showRadioButtons}
            />
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({ todo: Workspace.todo, companyName: Workspace.companyName });

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
