import React, { useState } from 'react';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import RemoveValue from 'components/Modals/RemoveValues';
import ValueItem from './ValueItem';
import AddValueModal from './AddValueModal';
import Table from 'components/ReusableComponents/Table';
import { useToaster } from 'Context/SnackbarContext';
import { handleAddValue, handleValueDelete, handleValueEdit } from '../componentsUtil';
import { useGetCoreValuesQuery } from '../ApiHooks';
import { whatAreValues, valuesNote } from '../constants';
import './style.scss';

const Index = () => {
    const { SetSnackbar } = useToaster();
    const [addModal, setAddModal] = useState({ open: false, value: '', index: -1 });
    const [deleteModal, setDeleteModal] = useState({ open: false, index: -1 });

    const { data, isLoading } = useGetCoreValuesQuery({ SetSnackbar });

    const { values = [] } = data || {};

    if (isLoading) {
        return <CustomLoader />;
    }

    if (values.length < 1) {
        return (
            <div className='values-config-container'>
                <div className='response-empty align-center'>
                    <div className='response-empty-image'></div>
                    <p className='no-response-text'>
                        Currently there are no <br /> values to showcase
                    </p>
                    <button
                        className='ew-btn ob-btn margin-top-20'
                        onClick={() => setAddModal({ open: true, value: '', index: -1 })}
                    >
                        Add values
                    </button>
                </div>
                {addModal.open && <AddValueModal addModal={addModal} setAddModal={setAddModal} values={values} />}
            </div>
        );
    }

    const valuesTableData = values.map((value, index) => ({
        id: value.id,
        row: [
            <ValueItem
                key={value.id}
                index={index}
                value={value}
                handleEdit={(open, index, value) => handleValueEdit({ open, index, value, setAddModal })}
                handleDelete={(open, index) => handleValueDelete({ open, index, setDeleteModal })}
            />,
        ],
    }));

    return (
        <div className='values-config-container'>
            <div className='add-values-container'>
                <div className='header'>
                    <h3 className='header-4 header-reward'>Values ({values.length})</h3>
                    <p className='body-3 sub-header-reward'>{whatAreValues}</p>
                    <div className='body-3 value-notes-container'>
                        <p>
                            <strong>Note</strong>: {valuesNote}
                        </p>
                    </div>
                </div>
                <button className='ew-btn pb-btn' onClick={() => handleAddValue({ values, setAddModal, SetSnackbar })}>
                    Add Values
                </button>
            </div>
            <div className='values-list-container'>
                <Table
                    data={valuesTableData}
                    noShadow={true}
                    loading={isLoading}
                    noSortColumns={[0]}
                    columnWidth={['100%']}
                />
            </div>
            {addModal.open && <AddValueModal addModal={addModal} setAddModal={setAddModal} values={values} />}
            {deleteModal.open && (
                <RemoveValue values={values} deleteIndex={deleteModal.index} setDeleteModal={setDeleteModal} />
            )}
        </div>
    );
};

export default Index;
