import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { ReactComponent as Warning } from 'Assets/images/warning-yellow.svg';

const DelayedProductBuyConfirmation = ({ turnAroundTime, onConfirm, onClose }) => {
    const text = {
        heading: (
            <div className='d-flex align-items-baseline'>
                <Warning style={{ width: '18px', height: '14px' }} />
                <h4
                    className='header-4 ml-3'
                    style={{ letterSpacing: '0.1px', color: '#15222F', fontSize: '1.125rem' }}
                >
                    The gift card you are trying to purchase is a delayed product.
                </h4>
            </div>
        ),
        subHeading: (
            <span>
                You will receive the gift card code and pin only after{' '}
                <span style={{ color: '#000' }}>{turnAroundTime}</span> days
            </span>
        ),
        confirm: 'Buy',
    };
    return <ConfirmModal open onClose={onClose} onConfirm={onConfirm} data={text} height='230px' />;
};

DelayedProductBuyConfirmation.propTypes = {
    turnAroundTime: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

export default DelayedProductBuyConfirmation;
