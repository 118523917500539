import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getWorkspaceConfig, getIntegratedChannelsList } from 'Services/apiFunctions';
import { GET_CONFIG_WORKSPACE, GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { OrgSettings, RecognitionChannel, Toggle } from 'components/ReusableComponents/Settings';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { useToaster } from 'Context/SnackbarContext';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleWorkspaceToggleChanges, saveWorkspaceDetails } from '../componentsUtil';
import {
    getEndText,
    toggleSubText,
    confirmationMessage,
    broadcastMessages,
    defaultRecognitionChannel,
} from '../constants';
import { showSnackBarMessage, getChannelOptionsByPlatform, getIntegratedChannelName } from 'utils/HelperFunctions';
import './style.scss';

const Index = () => {
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();

    const [channelData, setChannelData] = useState([]);
    const [hereModalOpen, setHereModalOpen] = useState(false);
    const [recognitionModalOpen, setRecognitionModalOpen] = useState(false);
    const [hereNotifications, setHereNotifications] = useState(false);
    const [recognitionsParticularChannel, setRecognitionsParticularChannel] = useState({
        enabled: false,
    });

    const { config, platform } = useSelector(mapStateToProps, shallowEqual);
    const isMsteams = platform === 'MSTeams';

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);
    const onSuccess = (data) => saveWorkspaceDetails({ dispatch, data, config });

    useQuery([GET_CONFIG_WORKSPACE], getWorkspaceConfig, { onSuccess, onError });

    const { isLoading: channelDataLoading } = useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelData(getChannelOptionsByPlatform(data, platform));
            }
        },
        onError,
    });

    const handleToggleChange = (value, type) =>
        handleWorkspaceToggleChanges({
            value,
            type,
            config,
            dispatch,
            setHereNotifications,
            setRecognitionsParticularChannel,
            SetSnackbar,
        });

    const endText = (enableCondition) => (
        <div>
            <p className='ob-subtext ob-endtext'>{getEndText(enableCondition, platform)}</p>
            <p className='org-note'>
                <strong>Note:</strong>&nbsp;Recognitions sent to this channel will also be shown on the recognition
                feed.
            </p>
            <div className='org-divider' />
        </div>
    );

    const getAtHereSubText = (defaultChannel) => (
        <>
            This will notify all active members on{' '}
            {defaultChannel ? (
                <strong className='here-bold'>
                    {!isMsteams ? '#' : ''}
                    {defaultChannel}
                </strong>
            ) : (
                'selected channel'
            )}{' '}
            about Recognitions.
        </>
    );

    const orgSection = [
        {
            heading: `Do you want to send recognitions on a particular ${isMsteams ? 'team' : 'channel'}?`,
            subtext: toggleSubText,
            toggle: (
                <Toggle
                    checked={recognitionsParticularChannel.enabled}
                    handleChange={handleToggleChange}
                    modalOpen={recognitionModalOpen}
                    setModalOpen={setRecognitionModalOpen}
                    data={{
                        heading: confirmationMessage,
                        subHeading: `Once it’s disabled, you’ll need to enable and select the ${
                            isMsteams ? 'team' : 'channel'
                        } again`,
                    }}
                    type={defaultRecognitionChannel}
                />
            ),
            component: !channelDataLoading ? (
                <RecognitionChannel
                    channelData={channelData}
                    type={defaultRecognitionChannel}
                    selectedChannel={getIntegratedChannelName(recognitionsParticularChannel, platform)}
                    disabled={!recognitionsParticularChannel.enabled}
                    handleSelection={setRecognitionsParticularChannel}
                />
            ) : (
                <CustomLoader />
            ),
            endtext: endText(recognitionsParticularChannel.enabled),
            disableView: false,
            disableContent: true,
            disabled: !recognitionsParticularChannel.enabled,
        },
        ...(!isMsteams
            ? [
                  {
                      heading: '@here notification',
                      subtext: getAtHereSubText(recognitionsParticularChannel.channelName),
                      toggle: (
                          <Toggle
                              checked={hereNotifications}
                              handleChange={handleToggleChange}
                              modalOpen={hereModalOpen}
                              setModalOpen={setHereModalOpen}
                              data={{
                                  heading: confirmationMessage || '',
                              }}
                              type={broadcastMessages}
                          />
                      ),
                      component: null,
                      disableView: true,
                      disabled: !recognitionsParticularChannel.enabled,
                  },
              ]
            : []),
    ];

    useEffect(() => {
        if (config?.defaultRecognitionChannel?.enabled) {
            setRecognitionsParticularChannel({
                ...config?.defaultRecognitionChannel,
            });
        } else {
            setRecognitionsParticularChannel({
                enabled: false,
            });
        }
        setHereNotifications(config?.broadcastMessages);
    }, [config]);

    return (
        <div className='config-org-settings'>
            {orgSection.map((blockData, index) => (
                <OrgSettings blockData={blockData} key={blockData.heading} index={index} />
            ))}
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    config: Workspace.config,
    platform: Workspace.platform,
    approver: Workspace.approver,
});

export default Index;
