import React, { useState } from 'react';
import { useQuery } from 'react-query';
import TitleBar from 'components/ReusableComponents/TitleBar';
import WorkspaceSettings from './WorkspaceSettings';
import ContactDetails from './ContactDetails';
import DefaultCountrySettings from './DefaultCountrySettings';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_GLOBAL_SETTINGS } from 'Services/apiKeys';
import { getGlobalSettings } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import './style.scss';

const Index = () => {
    const { SetSnackbar } = useToaster();
    const [data, setData] = useState();

    useQuery([GET_GLOBAL_SETTINGS], getGlobalSettings, {
        onSuccess: (settingsData) => setData(settingsData),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    return (
        <div className='settings-container'>
            <TitleBar title='Admin Settings' />
            <WorkspaceSettings />
            <DefaultCountrySettings data={data} />
            <ContactDetails data={data} />
        </div>
    );
};

export default Index;
