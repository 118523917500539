export const eNPS_ROUTE = {
    HOME: '/dashboard/eNPS/',
    SETUP_PAGE: '/dashboard/eNPS/launch',
    eNPS_CREATE: '/dashboard/eNPS/create',
    CREATE_TEMPLATE: '/dashboard/eNPS/create/template',
    INSIGHTS: '/dashboard/eNPS/insights/:id',
};
export const FEATURED_PULSE = process.env.REACT_APP_FEATURED_PULSE_ID
    ? JSON.parse(process.env.REACT_APP_FEATURED_PULSE_ID)
    : {};

export const reportText = [
    {
        title: ' Oh no! 😧',
        description:
            'Your organization is at a high risk of suffering from attrition, due to unappreciated employees. Studies say 8/10 employees would quit if they are not recognized at work, Immediate action recommended!',
        buttonText: 'Contact Us',
        action: 'contact',
    },

    {
        title: ' Close call!  😰',
        description: `Your organization's eNPS score is average, indicating a lot of unaddressed issues. Promote the culture of appreciation before it's too late.`,
        buttonText: 'Promote Recognitions',
        action: 'promoteRecognitions',
    },
    {
        title: ' Close call!  😰',
        description: `Your organization's eNPS score is average, indicating a lot of unaddressed issues. Promote the culture of appreciation before it's too late.`,
        buttonText: 'Setup Rewards & Recognitions',
        action: 'setUpRewards',
    },
    {
        title: ' Phew!  😌',
        description: `Your organization's eNPS score is good and above average. Run an ‘Organizational Perception’ pulse to understand more about your employees' loyalty.`,
        buttonText: 'Launch Organizational Perception pulse',
        action: 'orgPerception',
    },
    {
        title: ' Woohoo! 😃',
        description: `Your organization's eNPS score is excellent! Your employees are happy and generating positive reviews`,
    },
];

export const message = `
Ola @user, 
You know how it goes, teamwork makes the dream work! 🚀
Acknowledging your teammates empowers them and helps create a positive company culture, so let's spread some joy!

`;

export const dateFilters = [`Latest: Jun 20th`, `Mar 20th`, `Jan 20th`];
export const noSortColumns = [0, 1];
export const columnWidth = ['50%', '50%'];

export const benchMarks = [
    {
        label: `Technology`,
        value: 26,
    },
    {
        label: `Aerospace`,
        value: 14,
    },
    {
        label: `Transportation`,
        value: 13,
    },
    {
        label: `Consulting`,
        value: 30,
    },
    {
        label: `Consumer Goods`,
        value: 20,
    },
    {
        label: `Retail`,
        value: 9,
    },
    {
        label: `Banking`,
        value: 15,
    },
];

export const orgFilter = [
    {
        label: 'Managers',
        value: 'manager',
    },
    {
        label: 'Departments',
        value: 'department',
    },
];
export const eNPS_TYPE = { ONE_TIME: 'one-time', RECURRING: 'recurring' };
export const eNPS_STATUS = { DRAFT: 'draft', ACTIVE: 'active', SCHEDULED: 'scheduled', COMPLETED: 'completed' };
export const CONFIRM_MODALS_DATA = {
    DELETE_SURVEY: {
        name: 'DELETE_SURVEY',
        heading: 'Are you sure want to delete eNPS?',
        subHeading: 'Once eNPS is deleted, you can’t recover it.',
        confirm: 'Delete',
    },
    STOP_ONGOING_SURVEY: {
        name: 'STOP_ONGOING_SURVEY',
        heading: 'Are you sure you want to stop this ongoing eNPS?',
        subHeading: 'Once stopped, this eNPS will not collect responses anymore.',
        confirm: 'Stop',
    },
    STOP_RECURRING_SURVEY: {
        name: 'STOP_RECURRING_SURVEY',
        heading: 'Are you sure you want to stop this recurring eNPS?',
        subHeading: 'Once stopped, any future instances of this eNPS will not be sent',
        confirm: 'Stop',
    },

    STOP_RECURRING: {
        name: 'STOP_RECURRING',
        heading: 'Are you sure you want to stop this recurring eNPS?',
        confirm: 'Stop',
    },
    EDIT_ALL_RECURRING_SURVEY: {
        name: 'EDIT_ALL_RECURRING_SURVEY',
        heading: 'Select an option before you edit this eNPS.',
        confirm: 'Continue',
    },
};

export const EDIT_TYPE = { ONGOING: 'ongoing', FUTURE: 'future' };

export const INSTANCES = [
    { HEADING: 'This Instance', SUBHEADING: ' e-NPS will not accept responses anymore', value: 'ongoingInstances' },
    {
        HEADING: 'Future Instances',
        SUBHEADING: ' Any future instance of this e-NPS will not be sent',
        value: 'futureInstances',
    },
    {
        HEADING: 'All instances',
        SUBHEADING:
            ' e-NPS will not accept responses anymore for the current instance & any future instances of this e-NPS will not be sent.',
        value: 'allInstances',
    },
];
export const INSTANCES_VALUES = {
    ONGOING: 'ongoingInstances',
    FUTURE: 'futureInstances',
    ALL_INSTANCES: 'allInstances',
};
export const EDIT_INSTANCES = [
    {
        HEADING: 'Edit this instance',
        value: 'ongoing',
    },
    {
        HEADING: 'Edit only future instance',
        value: 'future',
    },
];
