import React, { useEffect } from 'react';
import EmptyScreen from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as ENPSImage } from 'Assets/images/eNPS.svg';
import { useDispatch } from 'react-redux';
import { toggleFullscreen } from 'redux/layout/actions';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { eNPS_ROUTE } from '../constant';

const Index = ({ history }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { draftId } = location?.state || '';

    const emptyScreenContent = {
        image: <ENPSImage />,
        description:
            'Employee NPS is a scoring system designed to help employers measure employee engagement within the organizations.',
        btnName: 'Setup eNPS',
        action: () =>
            history.push(
                draftId
                    ? {
                          pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                          state: { draftId },
                      }
                    : eNPS_ROUTE.eNPS_CREATE
            ),
    };
    useEffect(() => {
        toggleFullscreen(dispatch, false);
    }, []);
    return (
        <div className='enps-empty'>
            <EmptyScreen {...emptyScreenContent} />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
