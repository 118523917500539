export const TRANSACTION_STATES = [
    { label: 'Completed', value: 'approved' },
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' },
];

export const MYACTIVITIES_TRANSACT_STATES = [
    { label: 'Recieved', value: 'recieved' },
    { label: 'Given', value: 'given' },
    { label: 'Requested', value: 'requested' },
];
